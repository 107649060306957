import { Box, Paper, Typography } from "@mui/material";
import useStyles from "../../components/style/BgStyle";
import { rulesData } from "../../data";

export default function Rules() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box
        sx={{
          width: "80%",
          height: "100%",
          "@media (max-width: 600px)": {
            width: "100%",
          },
        }}
        mt={10}
      >
        <Typography variant="h3" sx={{textAlign:"center"}}>
          Christmas Bible Challenge 2023: Rules{" "}
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            height: "28rem",
            padding: "20px",
            overflow: "auto",
            width:"45rem",
            margin:"2rem auto",
            "@media (max-width: 600px)": {
                width: "100%",
              },
          }}
        >
          {rulesData.map((rules) => (
            <Paper
              sx={{ marginBottom: "1rem", padding: "0.8rem", width: "100%" }}
              elevation={2}
            >
              <Box key={rules.id} sx={{ display: "flex", alignItems: "right" }}>
                <Typography mr={2} variant="body1" sx={{ fontWeight: "400" }}>
                  {rules.id}.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  {rules.heading}:{" "}
                </Typography>
              </Box>
              <Typography
                mr={8}
                variant="body2"
                sx={{ width: "100%", color: rules.id == 11 ? "red" : "inherit" }}
                dangerouslySetInnerHTML={{ __html: rules.rule }}
              ></Typography>
            </Paper>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
