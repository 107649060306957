
import CountdownTimer from "../../components/countDownTimmer/CountDownTimmer";
import Layout from "../../components/layout/Layout";
import { Box, Typography, Button, } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from "react-toastify";

export default function Awearness() {
  const info = JSON.parse(sessionStorage.getItem("userInfo"));
  const referralLink = `https://qwizfun.com/events/bibleChallenge/christmas/register?referralID=${info?.id}`
  const shortLink ="https://qwizfun.com/bibleChallenge/invite"
  const CountDownDate = "2023-12-18T13:00:00";

  const handleInvite = async (url) => {
    await navigator.clipboard.writeText(url);
    toast.success("Referral link successfully copied", {
      position: toast.POSITION.TOP_LEFT_CENTER,
    });
  };

  return (
    <Layout>
      <Box
        mt={{
          xs: 4,
          md: 6,
        }}
        sx={{
          minHeight: "calc(100vh - 200px)",
          display:"flex", 
          // justifyContent:"center",
          alignItems:"center",
          flexDirection:"column",
          "@media (max-width: 600px)": {
            width: "100%",
          },
        }}
      >
        <Typography
          sx={{
            width: "50rem",
            fontSize: "3rem",
            textAlign:"center",
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
        Coming Soon !
        </Typography>
        <Typography
          sx={{
            width: "50rem",
            fontSize: "3rem",
            textAlign:"center",
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          Christmas Bible Challenge 2023
        </Typography>
        {/* Note: the follow values should be specified else, the 
        component will take default value.value, label, height, 
        width, color, labelSize, valueSize */}
            <CountdownTimer targetDate={CountDownDate}/>
            <Box sx={{
                display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"2rem"
            }}>
              <Typography variant="" sx={{fontSize:"1rem", color:"Black",marginBottom:"1rem", textAlign:"center", fontWeight:"900",
             "@media (max-width: 600px)": {
              color:"#D0433E",
            fontSize:"0.8rem"
            },
            }}>{shortLink} </Typography>
              <Button
                sx={{width:"15rem", backgroundColor:"#469852", color: "white", textTransform:"capitalize",borderRadius:"8px" }}
                onClick={() => {
                  handleInvite(referralLink);
                }}
              >
                 <ContentCopyIcon style={{marginRight:"5px"}}/> {""}
                Refer a Friend
              </Button>
              </Box>
      </Box>
      
    </Layout>
  );
}
