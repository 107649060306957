import React from "react";
import Button from "../../../../../components/shared/Button";
import Guy3 from "../../../../../assets/images/guy3.png";
import CorporateLeaderboard from "./CorporateLeaderboard";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const Hero = ({
  header,
  summary,
  imageUrl,
  buttonLabel,
  buttonBG,
  buttonColor,
  headerColor,
  summaryColor,
}) => {
  return (
    <div className="2xl:container 2xl:mx-auto">
      <div className="md:flex justify-between  mb-10 px-8 md:px-0 mt-10 lg:mt-0 ">
        <Fade left>
          <div className="md:w-3/4 flex justify-center items-center">
            <div className="px-4 lg:px-8 mb-10 lg:mb-0 ">
              <h1 className="heading   lg:pb-10 max-w-lg ">{header}</h1>
              <p className="pb-6 heading-para max-w-[640px]">{summary}</p>
              <Link to="register">
                <Button
                  type="button"
                  hoverBgColor="primaryRed-dark"
                  disabled={false}
                  borderRadius="rounded-none"
                  fontSize="text-base"
                  textColor="text-white"
                  bgColor={buttonBG || "bg-accessOrange"}
                  fontWeight="font-medium"
                >
                  {buttonLabel}
                </Button>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade right delay={1000}>
          <div className="  flex  lg:justify-end">
            <div className="relative  access-1 w-fit h-fit p-4 lg:p-0   flex justify-center">
              <img
                src={imageUrl || Guy3}
                alt="laugh-man"
                width="100%"
                height={20}
                className="lg:mr-28 xl:mr-36 pt-10"
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Hero;
