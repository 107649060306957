import React from "react";
import { Grid } from "@mui/material";
function ScoreRow({ rank, ordinal, name, score, date, time, isUser }) {
  return (
    <Grid
      container
      alignItems={"center"}
      p={2}
      sx={{
        border: "1px solid #F3CB52",
        bgcolor: isUser ? "#E0EBEF" : "#ffffff",
        borderRadius: "8px",
        gap: "8px",
        fontSize: 12,
      }}
    >
      <Grid item xs={1} fontWeight={600}>
        {rank} <sup>{ordinal}</sup>
      </Grid>
      <Grid item xs={4} sx={{ wordBreak: "break-all" }}>
        {name}
      </Grid>
      <Grid item xs={1}>
        {score}
      </Grid>
      <Grid item xs={4} textAlign={"right"}>
        {" "}
        {date} {time}
      </Grid>
    </Grid>
  );
}

export default ScoreRow;
