import React from "react";

// Read note to understand how this component works
// bgColor: Background color for the button.
// hoverBgColor: Background color on hover.
// textColor: Text color for the button.
// fontSize: Font size of the text.
// onClick: Function to handle button click.
// type: Button type (submit, reset, or button).
// restProps: Any additional attributes you want to apply directly to the button.

const Button = ({
  onClick,
  type,
  children,
  bgColor,
  hoverBgColor,
  textColor,
  fontSize,
  fontWeight,
  borderRadius,
  ...restProps
}) => {
  const buttonClasses = `py-2 px-4 transition-colors duration-300 ${
    borderRadius || "rounded"
  } ${textColor || "text-white"} ${fontSize || "text-base"} ${
    fontWeight || "normal"
  }`;

  const hoverClasses = hoverBgColor ? `hover:${hoverBgColor}` : "";

  return (
    <button
      onClick={onClick}
      type={type}
      className={`${bgColor || "bg-blue-500"} ${buttonClasses} ${hoverClasses}`}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
