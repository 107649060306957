import React from "react";

//image & icon
import crown from "../../assets/svgs/logos/crown.svg";
import style from "./leaderboard.module.css";
import { Avatar } from "@mui/material";

export default function LeaderboardCard({
  players,
  active,
  activePlayerIndex,
  loadingState,
  sortedArray,
  backgroundColor = "accessOrange",
  winners = "accessOrange",
  currentPlayer,
  playersColor = "accessOrange",
}) {
  // console.log({ activePlayerIndex });
  function formatDate(date) {
    const dateTime = new Date(date);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year}   ${hours}:${minutes}`;
  }

  return (
    <div className="">
      {loadingState ? (
        <span style={{ margin: "0 auto" }} className="loadingstate">
          Loading ...
        </span>
      ) : (
        <div className="leaderboardScreen">
          <div className={style.winnersSection}>
            <div className={style.winnersSection}>
              {sortedArray?.length > 1 && (
                <div className={style.winners}>
                  <p className={style.position} key={sortedArray[1]?.id}>
                    {<div className="mt-[2rem]">2nd</div>}
                  </p>
                  {/* <div className={style.imgCtn}> */}
                  <div
                    className={`${
                      style.imgCtn
                    } border-2 border-solid ${`border-${winners}`}`}
                  >
                    {sortedArray?.avatar === undefined ? (
                      <div>
                        {active === "individual" || active === "overall" ? (
                          <img
                            src={sortedArray[2]?.child?.avatar}
                            alt="winners avatar"
                            className={style.winnersAvatar}
                          />
                        ) : (
                          <div className="flex  justify-center items-center text-center mt-7 font-[900]">
                            {sortedArray[1]?.group_name
                              ?.slice?.(0, 3)
                              .split?.("")
                              .map((n, i) => (
                                <div className="" key={i}>
                                  {n.charAt(0).toUpperCase()}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <img
                        src={sortedArray[1]?.child?.avatar}
                        alt="winners avatar"
                        className="h-[90%] w-[90%]"
                      />
                    )}
                  </div>

                  <p className="font-[900] text-[0.8rem] w-[8rem]">
                    {active === "individual" || active === "overall"
                      ? sortedArray[1]?.child?.display_name
                        ? sortedArray[1]?.child.display_name.substring(0, 10)
                        : "-"
                      : sortedArray[1]?.group_name
                      ? sortedArray[1].group_name
                      : "-"}
                  </p>
                  <p className="font-[900] text-[0.8rem] ">
                    {active === "individual" || active === "overall"
                      ? sortedArray[1]?.point
                      : sortedArray[1]?.average}
                  </p>
                </div>
              )}
              <div className="winners">
                <p className={style.position} key={sortedArray[0]?.id}>
                  {<div className="mt-[-2rem]">1st</div>}
                </p>
                <img src={crown} alt="crown" className="ml-[3.8rem] mb-3" />
                {/* <div className={style.winner}> */}
                <div
                  className={`${
                    style.winner
                  } border-2 border-solid ${`border-${winners}`}`}
                >
                  {sortedArray?.avatar === undefined ? (
                    <div>
                      {active === "individual" || active === "overall" ? (
                        <img
                          src={sortedArray[0]?.child?.avatar}
                          alt="winners avatar"
                          className={style.winnersAvatar}
                        />
                      ) : (
                        <div className="flex  justify-center items-center text-center mt-7 font-[900]">
                          {sortedArray[0]?.group_name
                            ?.slice?.(0, 3)
                            .split?.("")
                            .map((n, i) => (
                              <div className="" key={i}>
                                {n.charAt(0).toUpperCase()}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <img
                      src={sortedArray[2]?.child?.avatar}
                      alt="winners avatar"
                      className="h-[90%] w-[90%]"
                    />
                  )}
                </div>
                <p className="font-[900] text-[0.8rem] w-[8rem] ml-4">
                  {active === "individual" || active === "overall"
                    ? sortedArray[0]?.child?.display_name
                      ? sortedArray[0]?.child.display_name.substring(0, 10)
                      : "-"
                    : sortedArray[0]?.group_name
                    ? sortedArray[0].group_name
                    : "-"}
                </p>

                <p className="font-[900] text-[0.8rem] ">
                  {active === "individual" || active === "overall"
                    ? sortedArray[0]?.point
                    : sortedArray[0]?.average}
                </p>
              </div>
              {sortedArray?.length == 3 && sortedArray?.length > 2 && (
                <div className="winners">
                  <p className={style.position} key={sortedArray[2]?.id}>
                    {<div className="mt-[2rem]">3rd</div>}
                  </p>
                  {/* <div className={style.imgCtn}> */}
                  {/* <div className={`${style.imgCtn} border-2 border-solid ${`border-${winnersBorder}`}`}> */}
                  <div
                    className={`${
                      style.imgCtn
                    } border-2 border-solid ${`border-${winners}`}`}
                  >
                    {sortedArray?.avatar === undefined ? (
                      <div>
                        {active === "individual" || active === "overall" ? (
                          <img
                            src={sortedArray[2]?.child?.avatar}
                            alt="winners avatar"
                            className={style.winnersAvatar}
                          />
                        ) : (
                          <div className="flex  justify-center items-center text-center mt-7 font-[900]">
                            {sortedArray[2]?.group_name
                              ?.slice?.(0, 3)
                              .split?.("")
                              .map((n, i) => (
                                <div className="" key={i}>
                                  {n.charAt(0).toUpperCase()}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <img
                        src={sortedArray[2]?.child?.avatar}
                        alt="winners avatar"
                        className="h-[90%] w-[90%]"
                      />
                    )}
                  </div>
                  <p className="font-[900] text-[0.8rem]  w-[8rem] ">
                    {active === "individual" || active === "overall"
                      ? sortedArray[2]?.child?.display_name
                        ? sortedArray[2]?.child.display_name.substring(0, 10)
                        : "-"
                      : sortedArray[2]?.group_name
                      ? sortedArray[2].group_name
                      : "-"}
                  </p>
                  <p className="font-[900] text-[0.8rem] ">
                    {active === "individual"
                      ? sortedArray[2]?.point
                      : sortedArray[2]?.average}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={style.leaderboardCtn}>
            {players?.map((player, index) => (
              <div
                className={`${style.leaderboardBox} ${`bg-${backgroundColor}`}`}
                key={player?.id}
                style={{ background: index === activePlayerIndex && "#ea4630" }}
              >
                <div className=" w-[30px] font-[700] ml-2">{index + 4}</div>
                <div className=" w-[8rem] font-[700]">
                  {active === "individual" || active === "overall"
                    ? player?.child?.display_name === undefined
                      ? "-"
                      : player?.child?.display_name
                    : player?.group_name === undefined
                    ? "-"
                    : player?.group_name}
                </div>

                <div className="w-[2rem] font-[700] mr-3">
                  {" "}
                  {active === "individual" || active === "overall"
                    ? player?.point
                    : player?.average}
                </div>
                <div className="mr-4 font-[700] w-[10rem]">
                  {player?.updatedAt === undefined
                    ? "-"
                    : // : formatDate(player?.updatedAt.split(" ")[0])}
                      formatDate(player?.updatedAt)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
