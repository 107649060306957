import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styles from "./SideBar.module.css"

export default function Sidebar({ sidelinks, textcolor }) {
    return (
      <Box className={styles.sidelinks}>
        {" "}
        <Box>{/* <Typography variant="body1">Hello</Typography> */}</Box>
        <Box mt={4}>
          {sidelinks.map((nav) => (
            <NavLink to={nav.url} style={{ textDecoration: "none" }}>
              {({ isActive, isPending, isTransitioning }) => (
                <Box
                  mb={1}
                  p={1}
                  pl={3}
                  // sx={{color:textcolor}}
                  className={`${styles.navFlex} ${
                    isActive ? styles.activeNav : styles.idleNavLink
                  }`}
                >
                  <Box
                    className={` ${
                      isActive ? styles.activeNavLink : styles.idleNavLink
                    }`}
                  >
                    {nav.icon}
                  </Box>
                  <Typography
                    variant="body1"
                    ml={3}
                    fontSize={18}
                    fontWeight={600}
                    className={` ${
                      isActive ? styles.activeNavLink : styles.idleNavLink
                    }`}
                  >
                    {nav?.label}
                  </Typography>
                </Box>
              )}
            </NavLink>
          ))}
        </Box>
      </Box>
    );
  }