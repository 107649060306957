import { Form } from "lorem-library";
import * as Yup from "yup";
import { toast } from "react-toastify";
import style from "./Register.module.css";
import { Box, Paper, Typography } from "@mui/material";
import useStyles from "../../components/style/BgStyle";
import { useRegistrationBC } from "../../../../hooks/api/post";
import { Link, useNavigate,useParams, useSearchParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";

export default function Registration() {
  const registerAPI = useRegistrationBC();
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const referralID =searchParams.get("referralID")
  

  
  

  const initialValues = {
    reqAccountType: "parent",
    name: "",
    display_name:"",
    email: "",
    phone_number: "",
    age: "",
    country: "",
    rules: false,
    terms: false,
    referred_by:referralID
  };

  const ageRange = [
    {
      label: "10 - 12",
      value: "10",
    },
    {
      label: "13 - 18",
      value: "13",
    },
    {
      label: "18+",
      value: "18",
    },
  ];
  const country = [
    {
      label: "Nigeria",
      value: "nig",
    },
    {
      label: "Ghana",
      value: "ghana",
    },
    {
      label: "Kenya",
      value: "kenya",
    },
    {
      label: "Zambia",
      value: "zambia",
    },
  ];

  const childAge = ageRange?.map((age) => {
    return {
      label: age.label,
      value: age.value,
    };
  });
  const getCountry = country?.map((country) => {
    return {
      label: country.label,
      value: country.value,
    };
  });

  const handleFormSubmit = (values) => {
    // console.log(values);
    registerAPI.mutate(values, {
      onSuccess: (data) => {
        toast.success("Account Created Successfully");
        navigate("/events/bibleChallenge/christmas/dashboard")
      },
      onError: (err) => {
        toast.dismiss();
        if(err.response.data?.message?.includes("Please login with your phone number")){
          toast.error(`An error occured: ${err.response.data?.message} `);
          setTimeout(() =>navigate("/events/bibleChallenge/christmas/login"), 2000);
        } else{
          toast.error(`An error occured: ${err.response.data?.message} `);

        }
          
        


      },
    });
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name is too Short!")
      .max(70, "Name is too Long!")
      .required("Name is Required"),
      display_name: Yup.string()
      .required("Name is Required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is Required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid phone number")
      .min(2, "Phone Number is too Short!")
      .max(70, "Phone Number is too Long!")
      .required("Phone Number is Required"),
    age: Yup.string().required("Age is Required"),
    country: Yup.string().required("Country is Required"),
    terms: Yup.boolean().oneOf([true], "T&C is Required"),
    rules: Yup.boolean().oneOf([true],"Rules is Required"),

  });

  const fields = [
    {
      name: "name",
      label: "Name",
      placeholder: "Select Name",
      type: "text",
      width: "half",
    },
    {
      name: "display_name",
      label: "Display Name",
      placeholder: "Select Name",
      type: "text",
      width: "half",
      
    },
    {
      name: "email",
      label: "Email",
      placeholder: "email",
      type: "text",
      width: "half",
    },
    {
      name: "phone_number",
      label: "Mobile",
      placeholder: "phone Number",
      type: "text",
      width: "half",
    },
    {
      name: "age",
      label: "Age",
      placeholder: "age",
      type: "select",
      width: "half",
      options: childAge,
    },
    {
      name: "country",
      label: "Country",
      placeholder: "country",
      type: "select",
      width: "half",
      options: getCountry,
    },
    {
      name: "divider",
      label: "name",
      placeholder: "name",
      type: "divider",
      width: "full",
      options: [],
    },
    {
      name: "rules",
      label: (
        <div style={{fontSize:"0.8rem", textAlign:"left" , marginTop:'-2rem', marginLeft:"2.5rem"}}>
          I accept the competition rules and regulations.{" "}
          <Link
            to="/events/bibleChallenge/christmas/rules"
            style={{ color: "red" }}
          >
            Rules
          </Link>
          .
        </div>
      ),
      placeholder: "Rules",
      type: "checkbox",
      width: "full",
      spacing:0.1,
      options: [
        {
          label: "Male",
          value: "Male",
        },
      ],
    },
    {
      name: "terms",
      label: (
        <div style={{fontSize:"0.8rem", textAlign:"left" , marginTop:'-2rem', marginLeft:"2.5rem"}}>
          I accept and consent the use of my pictures and other media for platform promotion purposes, post event functions and other future uses.{" "}
          <Link
            to="/events/bibleChallenge/christmas/rules"
            style={{ color: "red" }}
          >
            T&C
          </Link>
          .
        </div>
      ),
      placeholder: "terms",
      type: "checkbox",
      width: "full",
      options: [],
    },
  ];

  return (
    <Layout>
      <Box
        mt={{
          xs: 4,
          md: 4,
        }}
        p={2}
        sx={{
          marginLeft: "15rem",
          width: "500px",
          minHeight: "calc(100vh - 200px)",
          "@media (max-width: 600px)": {
            marginLeft: "0rem",
            width: "100%",
          },
        }}
      >
        <Typography variant="h5" mb={2}>
          Create An Account
        </Typography>
        <Box sx={{ backgroundColor: "white", padding: "0.8rem" }}>
          <Form
            title="Sign up with your correct details"
            fields={fields}
            buttonLabel="Submit"
            initialValues={initialValues}
            handleFormSubmit={handleFormSubmit}
            validationSchema={validationSchema}
            buttonColor="red"
            disableElevation={true}
            spacing={1}
            gap="1px"
          />
          <div className={style.navigate}>
            Already have an account? {""}
            <Link
              to="/events/bibleChallenge/christmas/login" 
              className={style.loginLink}
            >
              Login
            </Link>
          </div>
        </Box>
      </Box>
    </Layout>
  );
}
