import React, { useState } from "react";
import { ModalWithCloseButton } from "lorem-library";
// import { Sidebar } from "lorem-library";
import { useGetInvites } from "../../../../hooks/api/fetch";
import { NavLink } from "react-router-dom";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { Paper, makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { sidelinks } from "../../data";
import backgroundImage from "../../assets/svg/christmasBackground.svg";
import Profile from "../../components/profile/Profile";
import MobileMenu from "../../components/mobileNav/MobileNav";
import { useTheme } from "@mui/material/styles";
import Sidebar from "../../components/sideBar/SideBar";
import EditProfile from "../../components/editProfile/EditProfile";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },
}));

export default function UsersProfile() {
  const [showModal, setShowModal] = useState(false);
  const [showInviteList, setShowInviteList]=useState(false)
  const info = JSON.parse(sessionStorage.getItem("userInfo"));
  const classes = useStyles();
  const theme = useTheme();
  const {data:inviteList, isLoading:loading}=useGetInvites(info?.id)
  

  const handleInvite = async (url) => {
    await navigator.clipboard.writeText(url);
    toast.success("Referral link successfully copied", {
      position: toast.POSITION.TOP_LEFT_CENTER,
    });
  };


  const referralLink = `https://qwizfun.com/events/bibleChallenge/christmas/register?referralID=${info?.id}`

  const handleInviteList = () => {
    setShowModal(true);
  };
  const handleInviteFriends =()=>{
    setShowInviteList(true)
  }


  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "300px auto",
        height: "100vh",
        "@media (max-width: 767px)": {
          gridTemplateColumns: "auto",
        },
      }}
    >
      <Box sx={{ backgroundColor: "#F8F8F8", padding: "15px 15px 0" }}>
        <Box
          sx={{
            borderBottom: "1.5px solid #707070 ",
            padding: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Profile
              bgColor="#469852"
              name={info?.display_name}
              textColor="#707070"
              avatar={info?.avatar}
            />
            <MobileMenu sidelinks={sidelinks} />
          </Box>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.down("768")]: {
              display: "none",
            },
          }}
        >
          <Sidebar
            sidelinks={sidelinks}
            NavLink={NavLink}
            textcolor="#469852"
          />
        </Box>
      </Box>
      <Box className={classes.root}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              marginTop: "2rem",
              width: "50rem",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <EditProfile
              btnTitle1="Invitations"
              btnTitle2="Copy Link"
              btnbg1="#EC1C24"
              btnbg2="#469852"
              handleInvite={handleInvite}
              handleInviteList={handleInviteList}
              inviteBtnBg="#EC1C24"
              handleInviteFriends={handleInviteFriends}
              referralLink={referralLink}
              shortLink="https://bibleChallenge/invite"
              socialUrl={referralLink}
              title="Bible Challenge christmas fun game"
              hashtags="#BibleChallenge2023"

            />
          </Box>
        </Box>
      </Box>
      {showModal ? (
        <ModalWithCloseButton
          open={true}
          handleClose={() => setShowModal(false)}
        >
          <Box>
            <Typography  sx={{fontSize:"1.2rem", fontWeight:"800", textAlign:"center"}} >Invites</Typography>
            {loading ?
            <Box> Loding... </Box>
            :
            inviteList.data?.invitations?.length < 1 ?

            <Box sx={{textAlign:"center", fontWeight:"600"}}>No friends invited yet</Box>
            :
            inviteList.data?.invitations?.map((invites)=>(
              <Box sx={{textAlign:"center", fontWeight:"600", padding:"1rem" }}>
                <Paper elevation={8} mb={4}>
                  <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"10px 15px", marginBottom:"10px"}}>
                    <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                       <Avatar >{invites?.name?.substring(0, 2)}</Avatar>
                      <Typography variant="body2" ml={4} sx={{fontWeight:"900"}}>{invites?.display_name === null ? invites?.name : invites?.display_name }</Typography>
                    </Box>
                  
                  <CheckCircleOutlineIcon sx={{color:"#469852"}}/>
                  </Box>
              </Paper>

              </Box>
              
            ))
          }
            
          </Box>
        </ModalWithCloseButton>
      ) : (
        ""
      )}
      
    </Box>
  );
}
