import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import MobileIcon from "../../../assets/Images2/mobile.png";
function RotateScreen({ onClick }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        bgcolor: "#333357",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 10,
        p: 2,
        // opacity: 0.5,
      }}
    >
      <Typography color={"white"} align="center" mb={2}>
        {" "}
        Please, rotate your phone to landscape for a better game experience.
      </Typography>

      <Box width={127} my={3}>
        <Image src={MobileIcon} color="transparent" />
      </Box>
      <Box>
        <Button
          onClick={onClick}
          variant="contained"
          size="large"
          sx={{ bgcolor: "white", color: "#277BA0" }}
        >
          {" "}
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default RotateScreen;
