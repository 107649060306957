import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
export default function Logout() {
  const navigate = useNavigate();

  const { id } = useParams();
  function performLogout() {
    let confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      sessionStorage.clear();
      localStorage.setItem("hideHelp", "false");
      navigate(
        `/${
          window.location.href.includes("corporate") ? "corporate" : "event"
        }/${id}/login`
      );
    } else {
      window.history.go(-1);
    }
  }
  useEffect(() => {
    performLogout();
    return () => performLogout;
  }, []);
  return <>Logging Out...</>;
}
