import React from "react";
import Modal from "../../components/modal/Modal";
import { Box, Button, Container, Checkbox, Typography } from "@mui/material";

function HowToPlay({ open, handleClose, options = [] }) {
  return (
    <Modal open={open} handleClose={handleClose}
    data-test-id="how-to-use"
    >
      <Container
        disableGutters
        sx={{
          padding: "40px",
          borderRadius: "16px",
          "@media (max-width: 600px)": {
            padding: "10px 20px",
            minWidth: "100%",
          },
        }}
      >
        <Typography
          align="center"
          variant="h2"
          fontWeight={900}
          fontSize={20}
          mb={{
            xs: 2,
            md: 3,
          }}
        >
          How to Use Platform
        </Typography>
        <Box mb={5}>
          {options.map((option, index) => (
            <Typography
              key={index}
              mb={1}
              variant="body1"
              fontWeight={500}
              fontSize={12}
            >
              <span style={{ fontWeight: "700" }}>
                {index + 1}. {option?.title}{" "}
              </span>
              {option?.text}
            </Typography>
          ))}
        </Box>
        <Box
          mb={{
            xs: 1,
            md: 2,
          }}
        >
          <Button
            fullWidth
            color="error"
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "rgb(113 51 186 )  " }}
            data-test-id="close-how-to-use-modal"
          >
            Close
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <Checkbox
            onChange={(e) => localStorage.setItem("hideHelp", e.target.checked)}
          />
          <Typography variant="body2">Don't show again</Typography>
        </Box>
      </Container>
    </Modal>
  );
}

export default HowToPlay;
