import React from "react";
import Card from "../../../components/cardLayout/Card";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
function Progress({ currentStep, totalStep }) {
  return (
    <Box>
      <Card small>
        <Typography variant="h6" fontWeight={600} fontSize={12}>
          Progress: {currentStep} of {totalStep}
        </Typography>
        <Box mt={2} sx={{ width: "100%" }}>
          {/* <LinearProgress
            variant="determinate"
            value={(currentStep / totalStep) * 100}
          /> */}
          <Grid
            item
            md={8}
            xs={9}
            className="flex justify-start gap-[1px] items-center "
            // sx={{ minWidth: "224px" }}
          >
            {[1, 2, 3, 4, 5].map((index) => (
              <Box
                key={index}
                style={{
                  height: "8px",
                  width: "24px",
                  borderRadius: "10px",
                  // backgroundColor: "orange",
                  backgroundColor:
                    index - 1 < (currentStep / totalStep) * 5
                      ? "#DB9D3D"
                      : "#F0E0C7",
                }}
              ></Box>
            ))}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
}

export default Progress;
