import {
  Box,
  CircularProgress,
  NativeSelect,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

export default function AchievementtBar({
  px,
  bgColor,
  achievementHeading,
  achievementsDataInfo,
  headingColor,
  iconColor,
  fetchAllBCQuiz,
  handleChangeQuizID,
  achievementData,
  isLoading,
  loadingColor,
}) {
  const handleChange = (e) => {
    const selectedQuizID = e.target.value;
    handleChangeQuizID(selectedQuizID);
    console.log("QID",selectedQuizID)
  };
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        textAlign: "center",
        padding: px,
        borderRadius: "15px",
        "@media (max-width: 600px)": {
          padding: "0px 0px 0px 0px",
        },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "900",
          color: headingColor,
          marginBottom: "0.6rem",
          fontSize: "1.6rem",
        }}
      >
        {achievementHeading}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          placeItems: "center",
          gap: 2,
          "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(3, 2fr)",
          },
        }}
      >
        {isLoading ? (
          <CircularProgress sx={{ color: loadingColor }} />
        ) : (
          achievementsDataInfo.map((item, index) => (
            <Tooltip title={item.info} arrow enterTouchDelay={0}>
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                {item.icon && (
                  <Box sx={{ color: iconColor, marginRight: "6px" }}>
                    {item.icon}
                  </Box>
                )}
                <Box>
                  {item.title === "Curent Quest" ? (
                   
                      <NativeSelect onChange={handleChange} sx={{width:"14rem", "@media (max-width: 600px)": {
                        width:"8rem",
                      }, }}>
                        {/* <option value={"1"}>select quiz</option> */}
                       {fetchAllBCQuiz?.data?.map((quiz) => (
                        <option value={quiz?.id}>{quiz?.quiz_title}</option>
                    ))}
                    </NativeSelect>

                  ) : (
                    <Box>
                      {/* <Typography
                variant="h6"
                sx={{
                  fontWeight: '900',
                  fontSize: '14px',
                  color: '#353935',
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                    width: 'max-content',
                    gap: 8,
                    color: '#353935',
                  },
                }}
              >
                {item.title}
              </Typography> */}
                      <Typography
                        variant="body2"
                        sx={{ color: "#707070", fontWeight: "700" }}
                      >
                        {item.value}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Tooltip>
          ))
        )}
      </Box>
    </Box>
  );
}
