import React from "react";
import Button from "../../../components/shared/Button";
import Guy2 from "../../../assets/images/guy2.png";
import Guy4 from "../../../assets/images/guy4.png";
import Shape1 from "../../../assets/svgs/background/corporateshape1.svg";

import Fade from "react-reveal/Fade";

import { Link } from "react-router-dom";
import GetInTouch from "./GetInTouch";

const CorporateWorks = ({
  paraData,
  videoUrl,
  btnLabel1,
  btnLabel2,
  btnFontSize,
  btnBorderRadius,
  btn1LabelBgColor,
  btn2LabelBgColor,
  btn1TextColor,
  btn2TextColor,
  btnFontWeight,
  btn1To,
  btn2To,
}) => {
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 px-8">
        <Fade bottom>
          <div className="flex justify-center items-center mb-20 md:mb-0">
            <div className="">
              <h1 className="heading text-black">How it works</h1>
              <div>
                {paraData?.map((paragraph) => (
                  <p key={paragraph.id} className="heading-para mb-4">
                    {`${paragraph.id}. ${paragraph.content}`}
                  </p>
                ))}
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start  gap-x-10 mt-14">
                <Link to={btn1To}>
                  <Button
                    type="button"
                    disabled={false}
                    borderRadius={btnBorderRadius}
                    fontSize={btnFontSize}
                    textColor={btn1TextColor}
                    bgColor={btn1LabelBgColor}
                    fontWeight={btnFontWeight}
                  >
                    {btnLabel1}
                  </Button>
                </Link>
                <Link to={btn2To} className="mt-6 md:mt-0 flex justify-center">
                  <Button
                    type="button"
                    disabled={false}
                    borderRadius={btnBorderRadius}
                    fontSize={btnFontSize}
                    textColor={btn2TextColor}
                    bgColor={btn2LabelBgColor}
                    fontWeight={btnFontWeight}
                  >
                    {btnLabel2}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="flex justify-center items-center">
            <div className="flex items-start justify-center h-fit md:h-56 lg:h-80 w-fit lg:w-full rounded-lg">
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                title="Embedded Video"
                frameBorder="0"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
        </Fade>
      </div>
      <div>
        <div className="bg-primaryPurple flex justify-center items-center gap-x-20 mt-20 px-8 lg:px-0 mb-20 lg:mb-0  ">
          <div className="md:w-1/3 hidden md:block">
            <div className="">
              <div className=" relative    rounded-2xl h-full  flex justify-center ">
                <div className="relative  corporate-2 rounded-2xl w-screen h-[60vh]  flex justify-center">
                  <img
                    src={Guy4}
                    alt="man-img"
                    width={220}
                    height={100}
                    className="absolute bottom-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3 py-10 lg:py-0">
            <div className="md:flex justify-center items-center text-white">
              <div>
                <div className="mb-6 lg:mb-0">
                  <h1 className="heading text-white  text-center">
                    Boost Team Knowledge and Connection
                  </h1>
                </div>
                <div className="heading-para">
                  <p>
                    • Elevate your corporate environment in a fun learning
                    experience and continuous development through gamification.
                  </p>
                  <p>
                    • Turn learning into a shared experience and boost your
                    team’s knowledge and connection simultaneously.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Fade bottom>
          <div className="flex justify-end items-center  px-8 md:px-0">
            <div>
              <div className="container mx-auto flex justify-center w-full">
                <div className="pl-0  md:pl-8 ">
                  <div className="text-center  ">
                    <h1 className="heading">Our Customers Love to Use Qwiz:</h1>
                    {/* <h1 className="text-black font-bold text-[50px] leading-11">
                    So why not you ?
                  </h1> */}
                  </div>
                  <p className="pb-4 text-center heading-para">
                    Leading organizations like Committee Of Wives of Lagos State
                    Officials (COWLSO) have harnessed the power of Qwiz to
                    organize engaging quizzes for their team members.
                  </p>
                  {/* <p className="pt-6 pb-4 text-center text-sm lg:text-base">
                    At the COWLSO event, the Qwiz platform captivated a
                    significant audience of over 500 distinguished women,
                    resulting in an impressive 80% participation rate. The event
                    garnered positive feedback, revealing a remarkable 90%
                    satisfaction rate among participants.
                  </p> */}
                  <div className="flex justify-center">
                    <Button
                      type="button"
                      hoverBgColor="primaryRed-dark"
                      disabled={false}
                      borderRadius="rounded-xl"
                      fontSize="text-base"
                      textColor="text-white"
                      bgColor="bg-primaryPurple"
                      fontWeight="font-medium"
                    >
                      Explore more case studies
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full pl-32 hidden md:block ">
              <img
                src={Shape1}
                alt="laugh-man"
                width={200}
                height={300}
                className=""
              />
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <div className="container mx-auto my-10 md:flex flex-wrap   justify-center items-center px-8 lg:px-0">
          <div className="md:w-1/2 mb-20 md:mb-0 px-4 lg:px-4">
            <h1 className="heading">Prizes to be Won</h1>
            <p className="heading-para">
              Engage, Learn and stand a chance to win exciting prizes!
            </p>
          </div>

          <div className="md:w-1/2">
            <div className="relative   bg-primaryPurple rounded-2xl h-72 flex justify-center">
              <div className="  w-full h-72 flex justify-center">
                <img
                  src={Guy4}
                  alt="man-img"
                  width={200}
                  height={50}
                  className="absolute bottom-0"
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <GetInTouch />
      </Fade>
    </div>
  );
};

export default CorporateWorks;
