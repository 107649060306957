import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Button from "./shared/Button";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/svgs/logos/logo-black.svg";

const navLinks = [
  { to: "/events/bibleChallenge/christmas/", text: "Events" },
  { to: "/competitions", text: "Competitions" },
  { to: "/corporate", text: "Corporate" },
  { to: "/contact", text: "Contact" },

  { to: "/faq", text: "FAQ" },
];

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuAnimation = useSpring({
    transform: isMenuOpen ? "translateX(0%)" : "translateX(-100%)",
  });

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav className="bg-white dark:bg-gray-800 shadow border-b border-[#C2C2C2]">
        <div className="container mx-auto">
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center gap-x-14">
              <Link className="flex-shrink-0" to="/">
                <img className="w-16 h-auto" src={Logo} alt="quiz-logo" />
              </Link>

              <div className="hidden lg:block">
                <div className="flex items-baseline ml-10 space-x-4">
                  {/* Navigation Links */}
                  {navLinks.map((link, index) => (
                    <NavLink
                      key={index}
                      to={link.to}
                      activeClassName="active-link"
                      className="text-black hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
                    >
                      {link.text}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>

            <div className="hidden lg:flex items-baseline ml-10 space-x-4">
              {/* Additional Navigation Links */}
              <Link
                className="text-black hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-base font-medium"
                to="/about"
              >
                About
              </Link>
              <Link
                className="text-black dark:text-white hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
                to="/login"
              >
                Login
              </Link>
              <Link to="/register">
                <Button
                  type="button"
                  hoverBgColor="primaryRed-dark"
                  disabled={false}
                  borderRadius="rounded-xl"
                  fontSize="text-base"
                  textColor="text-white"
                  bgColor="bg-primaryRed"
                  fontWeight="font-medium"
                >
                  Sign up
                </Button>
              </Link>
            </div>
            {/* Responsive Menu Icon */}
            <button
              className="lg:hidden text-gray-600 dark:text-white focus:outline-none"
              onClick={toggleMenu}
            >
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Responsive Menu */}
      <animated.div
        className="lg:hidden fixed top-0 left-0 w-full h-full z-50 bg-white dark:bg-gray-800"
        style={menuAnimation}
      >
        <div className="flex items-center justify-end p-4">
          <button
            className="text-gray-600 dark:text-white focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center space-y-4 pt-16">
          {/* Responsive Menu Links */}
          {navLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.to}
              activeClassName="active-link"
              className="text-black hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
            >
              {link.text}
            </NavLink>
          ))}

          <Link
            className="text-black hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
            to="/about"
          >
            About
          </Link>
          <Link
            className="text-black hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
            to="/login"
          >
            Login
          </Link>
          <Link to="/register">
            <Button
              type="button"
              hoverBgColor="primaryRed-dark"
              disabled={false}
              borderRadius="rounded-xl"
              fontSize="text-base"
              textColor="text-white"
              bgColor="bg-primaryRed"
              fontWeight="font-medium"
            >
              Sign up
            </Button>
          </Link>
        </div>
      </animated.div>
    </div>
  );
};

export default Navbar;
