import React from "react";
import QuizkWork from "./components/QuizkWork";
import Navbar from "../../components/NavBar";
import Footer from "../../components/shared/Footer";

import About from "./components/About";
import Hero from "./components/Hero";
import HomeTestimonial from "./components/HomeTestimonial";

const Home = () => {
  return (
    <main className="min-h-screen bg-white">
      <Navbar />
      <Hero />
      <About />
      <QuizkWork />
      <HomeTestimonial />

      <Footer
        bgColor="bg-primaryRed"
        textColor="text-white"
        logoTextColor="text-primaryRed"
        placeholderColor="placeholder-primaryRed"
        inputIconColor="text-primaryRed"
        hoverColor=""
      />
    </main>
  );
};

export default Home;
