import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Chip,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { toast, Flip } from "react-toastify";
import Image from "material-ui-image";
import "./Profile.css";
import AuthLayout from "../../components/layout/NewAuthLayout";

import friendImage from "../../assets/images/friends.png";
import Modal from "../../components/modal/Modal";
// import { useFetchProfile, useFetchStreak } from "../../hooks/api/fetch";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  LinkedinIcon,
} from "react-share";
import {
  useUpdateAvatar,
  useUpdateProfile2,
  useUploadToAWS,
} from "../../hooks/api/post";
import profileBG from "../../assets/svgs/event2/profile-bg.png";
import uploadIcon from "../../assets/svgs/event2/upload.png";
import qwizLogo from "../../assets/images/ngx.png";
import avatarIcon from "../../assets/images/avatar.png";
import WhatsappIcon from "../../assets/icons/whatsapp.png";
import FacebookIcon from "../../assets/icons/fb.png";
import InstagramIcon from "../../assets/icons/ig.png";
import XIcon from "../../assets/icons/x.png";
import { useFetchAvatars, useFetchProfile2 } from "../../hooks/api/fetch";
function Profile() {
  const { id } = useParams();
  const [showAvatars, setShowAvatars] = useState(false);
  const handleCloseAvatarModal = () => {
    setShowAvatars((show) => false);
  };
  const userProfile = JSON.parse(sessionStorage.getItem("ccAuth"));
  // const profileApi = useFetchProfile(userProfile?.userPassport);
  // const getStreak = useFetchStreak(userProfile?.userPassport);
  const updateProfileApi = useUpdateAvatar();
  const fetchAvatars = useFetchAvatars();
  const [avatarOptions, setAvatarOptions] = useState([]);

  useEffect(() => {
    const avatars = fetchAvatars.data?.avatars;

    setAvatarOptions(avatars);

    return () => setAvatarOptions([]);
  }, [fetchAvatars.data, setAvatarOptions]);

  const [newAvatar, setNewAvatar] = useState(userProfile?.avatar);

  const handleUpdateAvatar = () => {
    updateProfileApi.mutate(
      {
        userId: userProfile.id,
        data: {
          name: userProfile.name,
          display_name: userProfile.displayName,
          avatar_url: newAvatar,
          first_login: false,
        },
      },
      {
        onSuccess: () => {
          toast.dismiss();
          toast.success("Avatar updated successfully");
          sessionStorage.setItem(
            "ccAuth",
            JSON.stringify({ ...userProfile, avatar: newAvatar })
          );
          handleCloseAvatarModal();
        },
        onError: (err) => {
          toast.error("Avatar update failed");
        },
      }
    );
  };

  // if (profileApi.isLoading) {
  //   return <>Please Wait</>;
  // }
  const fetchProfile = useFetchProfile2({ user_id: userProfile?.id });
  const updateProfileAPI = useUpdateProfile2();

  // console.log({ fetchProfile, age: fetchProfile.data?.data?.user?.age_range });

  const [profileInfo, setProfileInfo] = useState({
    country: "",
    age: "",
    gender: "",
    username: "",
  });
  const updateProfile = (field) => {
    updateProfileAPI.mutate(
      {
        user_id: userProfile?.id,
        // ...field,
        ...profileInfo,
        age_range: profileInfo.age,
        display_name: profileInfo.username,
      },
      {
        onSuccess: () => {
          toast.dismiss();
          toast.success("Profile Updated Successfully");
        },
        onError: (err) => {
          toast.dismiss();
          toast.error("Profile Update Failed. " + err?.response?.data?.message);
        },
      }
    );
  };

  useEffect(() => {
    let user = fetchProfile.data?.data?.user;
    if (user) {
      setProfileInfo((prev) => ({
        age: user?.age_range,
        country: user?.country,
        gender: user?.gender,
        username: user?.display_name,
      }));
    }
  }, [fetchProfile.data]);
  return (
    <AuthLayout logo={qwizLogo}>
      <Box
        sx={{
          position: "relative",
          zIndex: 4,
        }}
      >
        <Box
          p={2}
          m={2}
          sx={{ background: `url(${profileBG}) no-repeat center` }}
        >
          <Box bgcolor="white" sx={{ borderRadius: "15px", p: 1, mx: 4 }}>
            <Typography
              variant="h5"
              align="center"
              fontWeight={600}
              mt={{ xs: 1, lg: 2 }}
            >
              My Profile
            </Typography>
          </Box>
        </Box>
        {/* AVatar */}
        <Box
          onClick={() => setShowAvatars(true)}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ height: 132, width: 120 }}>
            <Avatar
              src={userProfile?.avatar || avatarIcon}
              sx={{ height: 132, width: 132, bgcolor: "transparent" }}
            />
            {/* <Image
              aspectRatio={132 / 120}
              src={userProfile?.avatar || avatarIcon}
              color="transparent"
              style={{ objectFit: "contain" }}
            /> */}
          </Box>
          {/* <Avatar sx={{ width: 120, height: 120 }} /> */}
        </Box>

        {/* FOrm field */}
        <Typography
          variant="body2"
          fontSize={13}
          fontWeight={600}
          mb={2}
          px={3}
        >
          My details
        </Typography>
        <Box sx={{ px: 2 }}>
          <TextField
            sx={{
              my: 1,
              "&.MuiOutlinedInput-notchedOutline": {
                // borderColor: "#CAE8F8 !important",
                borderColor: "red !important",
              },
              bgcolor: "white",
            }}
            className="textfield"
            fullWidth
            // disabled
            value={profileInfo.username}
            onChange={(e) =>
              setProfileInfo((prev) => ({ ...prev, username: e.target.value }))
            }
            label="Username"
          />
          <TextField
            sx={{ my: 1 }}
            className="textfield"
            fullWidth
            disabled
            value={userProfile?.name}
            label="Full Name"
          />
          <TextField
            sx={{ my: 1 }}
            className="textfield"
            fullWidth
            disabled
            value={fetchProfile.data?.data?.user?.phone_number || ""}
            label="Phone Number"
          />
          <TextField
            sx={{ my: 1 }}
            className="textfield"
            fullWidth
            disabled
            value={fetchProfile.data?.data?.user?.email || ""}
            label="Email"
          />
          <Grid container sx={{ justifyContent: "space-between" }} mt={2}>
            <Grid item xs={5.8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Gender"
                  // onChange={handleChange}
                  sx={{ background: "white" }}
                  value={profileInfo.gender}
                  onChange={(e) => {
                    setProfileInfo((prev) => ({
                      ...prev,
                      gender: e.target.value,
                    }));
                    // updateProfile({ gender: e.target.value });
                  }}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5.8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Country"
                  sx={{ background: "white" }}
                  // onChange={handleChange}
                  value={profileInfo.country}
                  onChange={(e) => {
                    setProfileInfo((prev) => ({
                      ...prev,
                      country: e.target.value,
                    }));
                    // updateProfile({ country: e.target.value });
                  }}
                >
                  <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
                  <MenuItem value={"UK"}>UK</MenuItem>
                  <MenuItem value={"Ghana"}>Ghana</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item my={2} xs={5.8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={profileInfo.age}
                  label="Age"
                  onChange={(e) => {
                    setProfileInfo((prev) => ({
                      ...prev,
                      age: e.target.value,
                    }));
                    // updateProfile({ age_range: e.target.value });
                  }}
                >
                  <MenuItem value={"1-20"}>1-20</MenuItem>
                  <MenuItem value={"25-30"}>25-30</MenuItem>
                  <MenuItem value={"30+"}>30+</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", px: 4 }}>
            <Button onClick={updateProfile} variant="contained" size="large">
              Update
            </Button>
          </Box>
        </Box>

        {/* Invitations */}
        <InviteCard id={id} />
        {/* <Box>
          <CardLayout>
            <Box mt={{ md: 3, xs: 2 }} mb={{ xs: 2, md: 4 }}>
              <FriendCard />
            </Box>
            <Button
              fullWidth
              onClick={() => {
                return setShowAvatars(true);
              }}
              sx={{ cursor: "pointer" }}
            >
              <AvatarCard img={userProfile?.avatar} />
            </Button>
       
          </CardLayout>
        </Box> */}
      </Box>
      <AvatarForm
        open={showAvatars}
        handleClose={handleCloseAvatarModal}
        options={avatarOptions?.map((avatar) => {
          let data = {};
          data.value = avatar;
          data.src = avatar;
          return data;
        })}
        value={newAvatar}
        setValue={setNewAvatar}
        handleUpdate={handleUpdateAvatar}
      />
    </AuthLayout>
  );
}

export default Profile;

function AvatarForm({
  open,
  handleClose,
  options = [],
  setValue,
  value,
  handleUpdate,
  loading,
}) {
  const handleAvatarChange = (event, newAvatar) => {
    setValue(newAvatar);
  };
  const uploadToAWS = useUploadToAWS();
  const [file, setFile] = useState({
    name: "",
    url: "",
    size: "",
  });
  const handleUploadImage = (e) => {
    let file = e.target.files[0];
    console.log({
      name: file?.name,
    });
    setFile((prev) => ({ ...prev, name: file?.name }));
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const toast_id = toast.loading("Uploading File Please wait...");
    uploadToAWS.mutate(formData, {
      onSuccess: (response) => {
        setValue(response?.url);
        toast.success("File Uploaded Successfully");
      },
      onError: (err) => {
        toast.error("Unable to upload file, Kindly try a smaller file size");
      },
      onSettled: () => {
        toast.dismiss(toast_id);
      },
    });
  };
  return (
    <Modal open={open} handleClose={handleClose}>
      <Container disableGutters sx={{ px: 2 }}>
        <Typography
          variant="h6"
          fontWeight={600}
          // m={2}

          pt={3}
          mb={2}
          fontSize={12}
        >
          Display Picture
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button component="label">
            <Box
              sx={{
                // border: "1px dashed blue",
                backgroundImage: ` url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23277BA0' stroke-width='4' stroke-dasharray='15' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e")`,
                // border-radius: 8px,
                borderRadius: "8px",
                height: "66px",
                minWidth: "128px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {!file.name && (
                  <Box height={21} width={16} mr={1}>
                    <Image aspectRatio={31 / 26} src={uploadIcon} />
                  </Box>
                )}
                <Typography
                  variant="body1"
                  // width={52}
                  fontSize={12}
                  fontWeight={600}
                  color="#277BA0"
                >
                  {file.name || "  Upload Picture"}
                </Typography>
              </Box>
            </Box>
            <input type="file" hidden onChange={handleUploadImage} />
          </Button>
        </Box>

        <Box my={2}>
          <Divider
            component="li"
            color="primary.main"
            sx={{
              color: "#0A4B6D",

              "&.MuiDivider-root::before": {
                border: "1px solid #0A4B6D",
              },
              "&.MuiDivider-root::after": {
                border: "1px solid #0A4B6D",
              },
              // borderBottomWidth: 5
            }}
          >
            Or
          </Divider>
        </Box>

        <Typography
          // mt={2}
          variant="body2"
          fontSize={12}
          fontWeight={600}
          color="#333333"
        >
          Select an Avatar
        </Typography>
        {/* Avatar select */}
        <ToggleButtonGroup
          exclusive
          value={value}
          onChange={handleAvatarChange}
          // color="primary"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            gap: "10px",
            paddingInline: "10px",
          }}
        >
          {options.map((option, index) => (
            <ToggleButton
              key={index}
              value={option.value}
              aria-label="left aligned"
              sx={{
                border: "none",
              }}
            >
              <Avatar
                src={option.src}
                sizes="large"
                sx={{
                  width: 24,
                  height: 24,
                  border: "1px dashed #61A641",
                  padding: "0px",
                }}
              />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Box sx={{ display: "flex", justifyContent: "center" }} p={1}>
          <Button
            onClick={handleUpdate}
            type="submit"
            variant="contained"
            // color="primary "
            disabled={loading}
          >
            {loading ? "Updating..." : " Update Avatar"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
}
function InviteCard({ id }) {
  const inviteUrl = `https://qwizfun.com/event/${id}/register`;
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(inviteUrl);
    toast.dismiss();
    toast("🦄 Copied to Clipboard!", {
      // position: "bottom-right",
      autoClose: 130,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  };
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "15px",
        p: 2,
        m: 2,
        position: "relative",
        zIndex: 3,
      }}
    >
      <Typography
        variant="h6"
        fontSize={12}
        fontWeight={600}
        color="#333"
        mb={2}
      >
        Invite your friends and win amazing rewards
      </Typography>
      <Box sx={{ display: "flex", gap: "10px", fontSize: 10, mb: 2 }}>
        <Chip
          onClick={handleCopyUrl}
          label={
            <Typography variant="body2" fontSize={12} color="#333">
              {" "}
              Copy Link
            </Typography>
          }
        ></Chip>
        <Typography
          onClick={handleCopyUrl}
          variant="body2"
          fontSize={12}
          color="white"
          pr={2}
          pl={0.4}
          sx={{ borderRadius: "5px", wordBreak: "break-word" }}
          bgcolor="primary.main"
        >
          {inviteUrl}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
        <Typography variant="body2" fontWeight={800}>
          {" "}
          Share{" "}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {/* Icons */}

          <WhatsappShareButton url={inviteUrl}>
            <Box sx={{ height: 24, width: 24 }}>
              <Image src={WhatsappIcon} />
            </Box>
          </WhatsappShareButton>

          <LinkedinShareButton url={inviteUrl}>
            <Box sx={{ height: 24, width: 24 }}>
              {/* <Image src={InstagramIcon} /> */}
              <LinkedinIcon size={24} round />
            </Box>
          </LinkedinShareButton>

          <FacebookShareButton url={inviteUrl}>
            <Box sx={{ height: 24, width: 24 }}>
              <Image src={FacebookIcon} />
            </Box>
          </FacebookShareButton>

          <TwitterShareButton url={inviteUrl}>
            <Box sx={{ height: 24, width: 24 }}>
              <Image src={XIcon} />
            </Box>
          </TwitterShareButton>
          {/* {[
            { url: "", icon: WhatsappIcon, button: <WhatsappShareButton /> },
            // { url: "", icon: FacebookIcon },
            // { url: "", icon: InstagramIcon },
            // { url: "", icon: XIcon },
          ].map((item) => (
            <>
              {
                <item.button>
                  <Box sx={{ height: 24, width: 24 }}>
                    <Image src={item?.icon} />
                  </Box>
                </item.button>
              }
            </>
          ))} */}
        </Box>
      </Box>
    </Box>
  );
}
