import FAQ from "../../components/faq/FAQ";
import { faqData } from "../../data";
import backgroundImage from "../../assets/images/christmasBG3.jpg";
import { Box, Typography } from "@mui/material";
import useStyles from "../../components/style/BgStyle";
import { useState } from "react";

export default function BibleChallengeFAQ() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <Box className={classes.root}>
      <Box sx={{ height: "100%", overflow: 'auto', width:'800px' }}>
        ,<Typography variant="h3" sx={{fontWeight:"600", marginBottom:"1rem", fontSize:"2.8rem"}}>Frequently Asked Questions (FAQs)</Typography>
        <Box>
          {faqData.map((faq) => (
            <FAQ
              backgroundImage={backgroundImage}
              question={faq?.question}
              answer={faq?.answer}
              id={faq?.id}
              expanded={expanded === faq.id}
              onChange={handleChange(faq.id)}
              key={faq.id}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
