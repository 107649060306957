import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  Stack,
  // TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FacebookShareButton, WhatsappShareButton, FacebookIcon, WhatsappIcon, TelegramIcon, TelegramShareButton, TwitterShareButton, TwitterIcon } from "react-share";

import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import Image from "material-ui-image";
import "./EditProfile.css";
// import CardLayout from "../../components/cardLayout/CardLayout";
import CardLayout from "../../../../components/cardLayout/CardLayout";
// import giftIcon from "../../assets/images/gift-box.png";
import giftIcon from "../../../../assets/images/gift-box.png";
import fireIcon from "../../../../assets/images/fire.png";
import friendImage from "../../../../assets/images/friends.png";
import Modal from "../../../../components/modal/Modal";
import { useUpdateAvatar } from "../../../../hooks/api/post";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import TelegramIcon from "@mui/icons-material/Telegram";

export default function EditProfile({
  btnTitle1,
  btnTitle2,
  btnbg1,
  btnbg2,
  handleInvite,
  handleInviteList,
  referralLink,
  shortLink,
  socialUrl,
  title,
  hashtags
}) {
  const [showAvatars, setShowAvatars] = useState(false);
  const handleCloseAvatarModal = () => {
    setShowAvatars((show) => false);
  };
  const userProfile = JSON.parse(sessionStorage.getItem("ccAuth"));
  const info = JSON.parse(sessionStorage.getItem("userInfo"));
  // const profileApi = useFetchProfile(userProfile?.userPassport);
  // const getStreak = useFetchStreak(userProfile?.userPassport);
  const updateProfileApi = useUpdateAvatar();

  const baseAvatar = "https://qwizmobi.s3.amazonaws.com/Avatar/";
  
  const avatarOptions = [
    // { avatar_link: userProfile?.avatar },
    { avatar_link: `${baseAvatar}Thumbs-UpKim.png` },
    { avatar_link: `${baseAvatar}Thumbs-UpAl.png` },
    { avatar_link: `${baseAvatar}SadKim.png` },
    { avatar_link: `${baseAvatar}NormalKim.png` },
    { avatar_link: `${baseAvatar}NormalAl.png` },
    // { avatar_link: `${baseAvatar}.png` },
    // { avatar_link: `${baseAvatar}.png` },
  ];

  const [newAvatar, setNewAvatar] = useState(info?.avatar);

  const handleUpdateAvatar = () => {
    updateProfileApi.mutate(
      {
        userId: info?.id,
        data: {
          name: info.name,
          display_name: info.display_name,
          avatar_url: newAvatar,
          first_login: false,
        },
      },
      {
        onSuccess: () => {
          toast.dismiss();
          toast.success("Avatar updated successfully");
          sessionStorage.setItem(
            "userInfo",
            // JSON.stringify({ ...userProfile, avatar: newAvatar })
            JSON.stringify({ ...info, avatar: newAvatar })
          );
          handleCloseAvatarModal();
        },
        onError: (err) => {
          toast.error("Avatar update failed");
        },
      }
    );
  };

  // if (profileApi.isLoading) {
  //   return <>Please Wait</>;
  // }
  return (
    <div>
      <Box>
        <Typography
          variant="h5"
          align="center"
          fontWeight={600}
          mt={{ xs: 1, lg: 2 }}
        >
          Edit Profile
        </Typography>
        <Box>
          <CardLayout>
            <Box mt={{ md: 3, xs: 2 }} mb={{ xs: 2, md: 4 }}>
              <FriendCard />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                "@media (max-width: 600px)": {
                  display: "block",
                },
              }}
            >
              <Button
                sx={{
                  width: "8rem",
                  backgroundColor: btnbg1,
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                }}
                onClick={handleInviteList}
              >
                {btnTitle1}
              </Button>
              <Box
                sx={{
                  border: "2px solid gray",
                  borderRadius: "10px",
                  "@media (max-width: 600px)": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  },
                }}
              >
                {shortLink ? (
                  <Typography
                    variant=""
                    sx={{
                      fontSize: "0.8rem",
                      color: btnbg2,
                      paddingLeft: "8px",
                      "@media (max-width: 600px)": {},
                    }}
                  >
                    {shortLink}{" "}
                  </Typography>
                ) : (
                  ""
                )}

                <Button
                  sx={{
                    width: "8rem",
                    backgroundColor: btnbg2,
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    handleInvite(referralLink);
                  }}
                >
                  <ContentCopyIcon />
                  {btnTitle2}
                </Button>
              </Box>
            </Box>
            <Paper
              elevation={8}
              sx={{
                padding: "10px 20px",
                width: "100%",
                borderRadius: "15px",
                backgroundColor: "white",
                textAlign: "center",
                margin: "15px 0",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "800",
                  color: "gray",
                  fontSize: "1rem",
                }}
              >
                Share on social media
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <FacebookShareButton url={socialUrl} title={title} hashtags={hashtags}>
                 <FacebookIcon style={{fontSize:"3rem", color:"#25D366", cursor:"pointer", borderRadius:"100%"}}/>
                </FacebookShareButton>
                <WhatsappShareButton url={socialUrl} title={title} hashtags={hashtags}>
                  <WhatsappIcon style={{fontSize:"3rem", color:"#25D366", cursor:"pointer", borderRadius:"100%"}}/>
                </WhatsappShareButton>
                <TelegramShareButton url={socialUrl} title={title} hashtags={hashtags}><TelegramIcon style={{fontSize:"3rem", color:"#25D366", cursor:"pointer", borderRadius:"100%"}}/></TelegramShareButton>
                <TwitterShareButton url={socialUrl} title={title} hashtags={hashtags}><TwitterIcon  style={{fontSize:"3rem", color:"#25D366", cursor:"pointer", borderRadius:"100%"}}/></TwitterShareButton>
              </Box>
            </Paper>
            <Button
              fullWidth
              onClick={() => {
                return setShowAvatars(true);
              }}
              sx={{ cursor: "pointer" }}
            >
              <AvatarCard img={info?.avatar} />
            </Button>
            {/* <Box mt={{ md: 4, xs: 2 }}>
              <ProfileForm
                userProfile={userProfile}
                updateProfileApi={updateProfileApi}
              />
            </Box> */}
          </CardLayout>
        </Box>
      </Box>
      <AvatarForm
        open={showAvatars}
        handleClose={handleCloseAvatarModal}
        options={avatarOptions?.map((data) => {
          data.value = data.avatar_link;
          data.src = data.avatar_link;
          return data;
        })}
        value={newAvatar}
        setValue={setNewAvatar}
        handleUpdate={handleUpdateAvatar}
      />
    </div>
  );
}

// export default Profile;
function Card({ title, subtitle, img, alt, bgColor }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        width: "100%",
        height: "100%",
        borderRadius: "8px",
      }}
      bgcolor={bgColor}
    >
      <Box mx={1} sx={{ height: 24, width: 24 }}>
        <Image
          src={img}
          alt={alt}
          sx={{ height: 24, width: 24 }}
          color="transparent"
        />
      </Box>
      <Box>
        <Typography
          variant="body1"
          //   mb={0.1}
          fontWeight={900}
          fontSize={16}
          color="white"
        >
          {title}
        </Typography>
        <Typography variant="body1" color="white" fontSize={12}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
function FriendCard() {
  return (
    <Box
      sx={{ backgroundColor: "#8BD9D9", borderRadius: "16px" }}
      py={1}
      px={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box justifySelf="flex-start" sx={{ maxWidth: "300px" }}>
          <Typography variant="body1" fontSize={20} fontWeight={900}>
            Friends
          </Typography>
          <Typography variant="body1" fontSize={10} fontWeight={900}>
            Play games and challenges with friends to win amazing prizes
          </Typography>
        </Box>
        <Box sx={{ minWidth: "76px" }}>
          <Image
            src={friendImage}
            alt="group of friends"
            color="transparent"
            cover={true}
            aspectRatio={16 / 9}
          />
        </Box>
        {/* <Box></Box> */}
      </Stack>
    </Box>
  );
}
function AvatarCard({ img }) {
  return (
    <Container disableGutters>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={{ xs: 1, md: 3 }}
        px={{ md: 4, xs: 2 }}
        sx={{ border: "1px solid #61A641", borderRadius: "16px" }}
      >
        <Typography variant="body1" fontSize={16} fontWeight={900}>
          Pick an Avatar
        </Typography>
        <Box>
          <Avatar
            src={img}
            alt="Avatar"
            sx={{ border: "1px dashed #61A641", height: 72, width: 72 }}
          />
        </Box>
      </Stack>
    </Container>
  );
}

function ProfileForm({ userProfile, updateProfileApi }) {
  const profileSchema = Yup.object().shape({
    display_name: Yup.string()
      .min(6, "Display Name should be at least 6 characters ")
      .max(30, "Display Name is Too Long!")
      .required("Display Name is Required"),
  });
  return (
    <Box>
      <Formik
        initialValues={{
          display_name: userProfile?.displayName,
          name: userProfile?.name,
          avatar: userProfile?.avatar,
          first_login: false,
          mobile: userProfile?.userPassport,
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateProfileApi.mutate(
            { userId: userProfile.id, data: values },
            {
              onSuccess: () => {
                toast.dismiss();
                toast.success("Display Name updated successfully");
                sessionStorage.setItem(
                  "ccAuth",
                  JSON.stringify({
                    ...userProfile,
                    displayName: values.display_name,
                  })
                );
              },
              onSettled: () => {
                setSubmitting(false);
              },
            }
          );
        }}
        validationSchema={profileSchema}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Box sx={{ width: "50%" }}>
                <label sx={{ display: "block" }}>Display Name</label>

                <TextField
                  fullWidth
                  required
                  //   label="Display Name"
                  value={values.display_name}
                  defaultValue={values.display_name}
                  onChange={(e) =>
                    setFieldValue("display_name", e.target.value)
                  }
                />
                <Box sx={{ color: "error.main" }} mb={1} fontSize={12}>
                  <ErrorMessage name="display_name" />
                </Box>
              </Box>
              <Box sx={{ width: "50%" }}>
                <label>Full Name</label>
                <TextField
                  fullWidth
                  disabled
                  //   label="Full Name"
                  value={values.name}
                  defaultValue={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              </Box>
            </Stack>
            <Box mt={{ xs: 1, md: 3 }}>
              <label>Mobile Number</label>
              <TextField
                fullWidth
                disabled
                // label="Mobile Number"
                defaultValue={values.mobile}
                onChange={(e) => setFieldValue("mobile", e.target.value)}
              />
            </Box>
            <Box mt={{ xs: 2, md: 5 }}>
              <Button
                fullWidth
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="contained"
                color="success"
                size="large"
              >
                {isSubmitting ? "Updating" : " Update Profile"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
function AvatarForm({
  open,
  handleClose,
  options = [],
  setValue,
  value,
  handleUpdate,
  loading,
}) {
  const handleAvatarChange = (event, newAvatar) => {
    setValue(newAvatar);
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Container disableGutters>
        <Typography variant="h6" fontWeight={600} m={2} mb={0}>
          Choose Avatar
        </Typography>
        <ToggleButtonGroup
          exclusive
          value={value}
          onChange={handleAvatarChange}
          color="success"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            gap: "10px",
            padding: "10px",
          }}
        >
          {options.map((option, index) => (
            <ToggleButton
              key={index}
              value={option.value}
              aria-label="left aligned"
              sx={{
                border: "none",
              }}
            >
              <Avatar
                src={option.src}
                sizes="large"
                sx={{
                  width: 86,
                  height: 86,
                  border: "1px dashed #61A641",
                  padding: "0px",
                }}
              />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Box sx={{ display: "flex", justifyContent: "center" }} p={3}>
          <Button
            onClick={handleUpdate}
            type="submit"
            variant="contained"
            color="success"
            disabled={loading}
          >
            {loading ? "Updating..." : " Update Avatar"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
}
