import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Card from "../../../components/cardLayout/Card";
import Clock from "../../../assets/Icons2/timer.svg";
import Image from "material-ui-image";
function Timer({ time = "59" }) {
  return (
    <Card small sx={{ display: "flex" }}>
      <Stack direction="row" alignItems="center">
        <Box height={32} width={32} mr={1}>
          <Image src={Clock} />
        </Box>
        <Typography variant="h6" fontSize={12}>
          Timer: 00:{time}s
        </Typography>
      </Stack>
    </Card>
  );
}

export default Timer;
