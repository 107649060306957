import React, { useState } from "react";
import AuthLayout from "../../components/layout/NewAuthLayout";
import PageTitle from "../../components/PageTitle";
import RecentActivities from "../Onboarding/component/RecentActivities";
import OnGoingGamesAndQuizzes from "../Onboarding/component/OnGoingGamesAndQuizzes";
import { useGetExplore } from "../../hooks/api/fetch";
import { Box, Container, Input, InputAdornment, TextField, Typography } from "@mui/material";
import { Icons } from "../../assets/Icons";
import { SearchOutlined } from "@mui/icons-material";
import LoadingState from "../../components/LoadingState";

function Explore() {
  let auth = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data, isLoading } = useGetExplore(auth?.id);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredRecentlyAdded = data?.recentlyAdded.filter((item) => item?.quiz_title?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
  const filteredPopularGame = data?.popularGame.filter((item) => item?.quiz_title?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
  const filteredAllQuiz = data?.allQuiz.filter((item) => item?.quiz_title?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
  const filteredAllGame = data?.allGame.filter((item) => item?.quiz_title?.toLowerCase()?.includes(searchQuery?.toLowerCase()));

  return (
    <AuthLayout>
      <PageTitle title={"Explore"} />
      {isLoading ? (
        <LoadingState />
      ) : (
        <Box
          sx={{
            position: "relative",
            zIndex: 4,
          }}
        >
          <Box>
            <Container>
              <Typography variant="body1" textAlign={"center"}>
                Explore, engage, and keep pushing your limits! Ready to dive in?
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: "700 !important",
                  fontFamily: "Poppins !important",
                  width: "100%",
                  margin: "1rem 0rem",
                  borderRadius: "0.5rem",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={Icons?.Search}
                        alt="search-icon"
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          objectFit: "contain",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Container>
          </Box>
          <OnGoingGamesAndQuizzes title="Recently Added" data={filteredRecentlyAdded} />
          <OnGoingGamesAndQuizzes title="Popular Games  and Quizzes" data={filteredPopularGame} />
          <OnGoingGamesAndQuizzes title="All Quizzes" data={filteredAllQuiz} />
          <OnGoingGamesAndQuizzes title="All Games" data={filteredAllGame} />
        </Box>
      )}
    </AuthLayout>
  );
}

export default Explore;
