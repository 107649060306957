import React from "react";
import Button from "../../../../../components/shared/Button";
import Guy2 from "../../../../../assets/images/guy2.png";
import Shape1 from "../../../../../assets/svgs/background/corporateshape1.svg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const HowItWorks = ({
  options = [],
  videoUrl,
  loginButtonColor,
  registerButtonColor,
}) => {
  // const videoUrl =
  //   "https://mobigames.s3.amazonaws.com/access-compliance-game/Quiz%20Corporate%20-%20Profile%201%20-%20Microsoft%20Edge%202023-11-17%2012-43-23.mp4";
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 px-8">
        <Fade bottom>
          <div className="flex justify-center items-center mb-20 md:mb-0">
            <div className="">
              <h1 className="heading text-black">How it works</h1>
              <div className="md:max-w-lg heading-para">
                {options?.map((option, index) => (
                  <p key={index} className=" mb-4">
                    {index + 1}. {option?.text || option?.content}
                  </p>
                ))}
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start  gap-x-5 mt-14">
                <Link to="register">
                  <Button
                    type="button"
                    hoverBgColor="primaryRed-dark"
                    disabled={false}
                    borderRadius="rounded-none"
                    fontSize="text-base"
                    textColor="text-white"
                    bgColor={
                      registerButtonColor
                        ? `bg-[${registerButtonColor}]`
                        : "bg-accessOrange"
                    }
                    fontWeight="font-medium"
                  >
                    Register
                  </Button>
                </Link>
                <Link to="login" className="flex justify-center">
                  <Button
                    type="button"
                    hoverBgColor="primaryRed-dark"
                    disabled={false}
                    borderRadius="rounded-none"
                    fontSize="text-base"
                    textColor="text-white"
                    bgColor="bg-[#30333A]"
                    fontWeight="font-medium"
                  >
                    Log in
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="flex justify-center items-center">
            <div className="flex items-start justify-center h-fit md:h-56 lg:h-80 w-fit lg:w-full rounded-lg">
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                title="Embedded Video"
                frameBorder="0"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default HowItWorks;
