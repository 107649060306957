import { Grid, Box, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const TimerItem = ({
  value,
  label,
  height,
  width,
  color,
  labelSize,
  valueSize,
}) => (
  <Grid item xs={4}>
    <Paper
      elevation={6}
      sx={{
        height: height,
        width: width,
        fontWeight: "600",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: color,
        padding: "0rem 1rem 0rem 1rem",
        "@media (max-width: 600px)": {
          width: "100%",
        },
      }}
    >
      <Typography
        variant=""
        sx={{
          fontSize: valueSize ,
          "@media (max-width: 600px)": {
            fontSize: "2.5rem",
          },
        }}
      >
        {value}
      </Typography>
    </Paper>
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      {" "}
      <Typography
        variant=""
        sx={{
          fontWeight: "500",
          fontSize: labelSize,
          textTransform: "uppercase",
        }}
      >
        {label}
      </Typography>
    </Box>
  </Grid>
);

export default function CountdownTimer({
  targetDate,
  height = "5rem",
  width = "6rem",
  color="#D0433E",
  labelSize ="1rem",
  valueSize = "3rem",
}) {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <Box sx={{textAlign:"center"}}>
      <Grid container spacing={2} columns={16} p={4} sx={{textAlign:"center",
    }}>
        <TimerItem
          value={timeLeft.days}
          label="Days"
          color={color}
          height={height}
          labelSize={labelSize}
          valueSize={valueSize}
          width={width}
        />
        <TimerItem
          value={timeLeft.hours}
          label="Hours"
          color={color}
          height={height}
          labelSize={labelSize}
          valueSize={valueSize}
          width={width}
        />
        <TimerItem
          value={timeLeft.minutes}
          label="Minutes"
          color={color}
          height={height}
          labelSize={labelSize}
          valueSize={valueSize}
          width={width}
        />
        <TimerItem
          value={timeLeft.seconds}
          label="Seconds"
          color={color}
          height={height}
          labelSize={labelSize}
          valueSize={valueSize}
          width={width}
        />
      </Grid>
    </Box>
  );
}
