import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";

// const BASE_URL = "http://192.168.0.175:4001" // ? to be modify to the ip of the network
// const BASE_URL = "https://fizzle.gamequiz.live";
const BASE_URL = "https://pollapi.9ijakids.com";
// const BASE_URL = "https://gameapi.9ijakids.com";
// const BASE_URL = "https://conduit.9ijakids.com/";

export const request = axios.create({
  baseURL: BASE_URL,
});
request.interceptors.request.use((config) => {
  const auth = sessionStorage.getItem("ccAuth");
  if (auth) {
    config.headers.Authorization = `Bearer ${JSON.parse(auth).token}`;
  }
  return config;
});

export const useFetchDepartments = () => {
  const fetchAllQuiz = async () => {
    const { data } = await request.get(`/user/groups/091098`);
    return data;
  };
  const fetchAllQuizQuery = useQuery(
    ["fetch-all-department"],
    () => fetchAllQuiz(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllQuizQuery;
};

export const useFetchAllQuiz = (payload) => {
  const fetchAllQuiz = async (payload) => {
    const { data } = await request.post(`/quiz/by-event`, payload);
    return data;
  };
  const fetchAllQuizQuery = useQuery(
    ["fetch-all-games", payload.user_id],
    () => fetchAllQuiz(payload),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllQuizQuery;
};
export const usePlayGame = (payload) => {
  // const payload= {player_id, quiz_id}
  const fetchPlayQuiz = async (payload) => {
    const { data } = await request.post(`/quiz/start`, payload);
    return data;
  };
  const fetchPlayQuizQuery = useQuery(
    ["fetch-all-games", payload.player_id, payload.quiz_id],
    () => fetchPlayQuiz(payload),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchPlayQuizQuery;
};
export const useFetchCountries = () => {
  const fetchAllQuiz = async () => {
    const { data } = await request.get(`/user/country`);
    return data;
  };
  const fetchAllQuizQuery = useQuery(
    ["fetch-all-games"],
    () => fetchAllQuiz(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllQuizQuery;
};
//close
export const useFetchGames = (props) => {
  const { payload } = props;
  const fetchAllGames = async (payload) => {
    const { data } = await request.get(
      `/trivia/quiz-question/${payload?.quiz_id}`,
      payload
    );

    return data;
  };
  const fetchAllGamesQuery = useQuery(
    ["fetch-all-games", payload.quizId],
    () => fetchAllGames(payload),
    {
      enabled: !!payload?.quiz_id,
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllGamesQuery;
};

// GET AVAILABLE PLANS
export const useFetchPlans = () => {
  const fetchAllPlans = async () => {
    const { data } = await request.get(`/trivia/plans`);
    return data.plans;
  };
  const { data: fetchAllPlansQuery, isLoading } = useQuery(
    ["fetch-all-plans"],
    () => fetchAllPlans()
  );
  return { fetchAllPlansQuery, isLoading };
};
// FETCH LEADERBOARD
export const useFetchLeaderBoard = (quizID) => {
  const fetchLeaderboard = async () => {
    const { data } = await request.get(`/trivia/leaderboard/${quizID}`);
    return data;
  };
  const { data: fetchAllLeaderboardData, isLoading } = useQuery(
    ["fetch-all-leaderboard", { quizID }],
    () => fetchLeaderboard(quizID),
    {
      refetchOnWindowFocus: false,
    }
  );
  return { fetchAllLeaderboardData, isLoading };
};

export const useCheckSubscribeStatus = (number) => {
  const CheckSubscribeStatusAPI = async (number) => {
    const { data } = await request.post(`/trivia/check-sub`, {
      user_passport: number,
    });
    return data;
  };
  const CheckSubscribeStatusQuery = useQuery(
    ["check sub status", number],
    () => CheckSubscribeStatusAPI(number),

    {
      refetchOnWindowFocus: false,
    }
  );

  return CheckSubscribeStatusQuery;
};

// SUBSCRIPTION ACTIVITY.
export const useSubscriptionActivity = ({ userid }) => {
  const fetchSubscriptionActivity = async () => {
    const { data } = await request.get(
      `/trivia/subscription-history/${userid}`
    );
    return data?.subActivities;
  };
  const fetchAllSubscriptionActivity = useQuery(
    ["fetch-all-leaderboard", { userid }],
    () => fetchSubscriptionActivity(userid),
    {
      refetchOnWindowFocus: false,
    }
  );

  return fetchAllSubscriptionActivity;
};

//FETCH ALL BANKS
export const useFetchAllBanks = () => {
  const fetchAllBanks = async () => {
    const { data } = await request.get(`/trivia/bank/list`);
    return data.banks;
  };
  const fetchAllAvailableBank = useQuery(["fetch-all-Banks"], () =>
    fetchAllBanks()
  );
  return fetchAllAvailableBank;
};

//FETCH USER ACCOUNT DETAIL
export const useFetchUserAccount = ({ userid }) => {
  const fetchAllAddedAccount = async () => {
    const { data } = await request.get(`/trivia/account/${userid}`);
    return data;
  };
  const fetchAllUsersAddedAccount = useQuery(["fetch-all-users-account"], () =>
    fetchAllAddedAccount()
  );
  return fetchAllUsersAddedAccount;
};

export const useFetchProfile = (userPassport) => {
  const fetchProfile = async () => {
    try {
      const { data } = await request.get(`/telco/user/profile/${userPassport}`);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const queryProfile = useQuery(
    ["profile", userPassport],
    () => fetchProfile(),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log("00h", data);
  return queryProfile;
};
export const useFetchStreak = (userPassport) => {
  // console.log(userPassport);

  const fetchStreak = async () => {
    try {
      const { data } = await request.get(
        `/telco/streak?user_passport=${userPassport}`
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const queryStreak = useQuery(["streak", userPassport], () => fetchStreak(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  return queryStreak;
};

export const useFetchRewards = (userID) => {
  const fetchAllQuiz = async (userID) => {
    const { data } = await request.get(`/trivia/my-winning/${userID}`);
    return data;
  };
  const fetchAllQuizQuery = useQuery(
    ["fetch-reward", userID],
    () => fetchAllQuiz(userID),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllQuizQuery;
};
// get leaderboard
export const useGetQuizFunLeaderboard = (quizID) => {
  const quizLeaderboard = async () => {
    const { data } = await request.post(`/quiz/event-leaderboard`, { quiz_id: quizID });
    return data;
  };

  return useQuery(['quizLeaderboard', {quiz_id: quizID}], quizLeaderboard);
};

//get all quiz
export const useGetQuiz = ({eventCode, userID}, quizID) => {
  const quiz = async () => {
    const { data } = await request.post(`/quiz/by-event`, {
        event_code:eventCode,
        user_id:userID
      });
    return data;
    
  };

  return useQuery(['getAllQuiz', quizID], quiz);
};

//get all quiz for bible challenge 
export const useGetAllQuiz = ({eventID, userid}) => {
  const fetchAllBibleChallengeQuiz = async ({eventID, userid}) => {
    const { data } = await request.get(`/quiz/quiz-by-event/${eventID}?user_id=${userid}`);
    return data;
  };
  const fetchAllBCQuiz = useQuery(
    ["fetch-BCQuiz", {eventID, userid}],
    () => fetchAllBibleChallengeQuiz({eventID, userid}),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllBCQuiz ;
};

//get all achievement data 
export const useGetAchievementData = ({userID, eventID, quizID,userid}) => {
  const fetchAllAchievementData = async ({userID, eventID, quizID,userid}) => {
    const { data } = await request.get(`/children/summary/${userID}?event_id=${eventID}&quiz_id=${quizID}&user_id=${userid}`);
    return data;
  };
  const achievementData = useQuery(
    ["fetch-BCAchievementData", {userID, eventID, quizID, userid}],
    () => fetchAllAchievementData({userID, eventID, quizID, userid}),
    {
      refetchOnWindowFocus: false,
    }
  );
  return achievementData ;
};

//bible challenge leaderboard 
export const useGetBCLeaderboard = ({userID, age, quizID}) => {
  

  const bibleChallengeLeaderboard = async () => {
    const { data } = await request.post(`/quiz/leaderboard/`,
     {
        user_id: userID,
        age_group:age,
        quiz_id:quizID
    });
    return data;
  };
  return useQuery(['quizLeaderboard', {
    user_id: userID,
        age_group:age,
        quiz_id:quizID
}], bibleChallengeLeaderboard);
};

//get bible challenge invite list
export const useGetInvites = (userID) => {
  const fetchAllInvitedFriends = async ({userID,}) => {
    const { data } = await request.get(`/children/invitations/${userID}`);
    return data;
    
  };
  const inviteList = useQuery(
    ["fetch-BCInviteList", {userID}],
    () => fetchAllInvitedFriends({userID}),
    {
      refetchOnWindowFocus: false,
    }
  );
  return inviteList ;
};
// export const useBCPlayGame = (payload) => {
//   // const payload= {player_id, quiz_id}
//   const fetchBCPlayQuiz = async (payload) => {
//     const { data } = await request.post(`/quiz/start`, payload);
//     return data;
//   };
//   const fetchPlayQuizQuery = useQuery(
//     ["fetch-all-BC-games", payload.player_id, payload.quiz_id],
//     () => fetchBCPlayQuiz(payload),
//     {
//       refetchOnWindowFocus: false,
//     }
//   );
//   return fetchPlayQuizQuery;
// };

