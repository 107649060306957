import React, { useEffect, useState } from "react";
import {
  useGetAllQuiz,
  useGetAchievementData,
} from "../../../../hooks/api/fetch";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useUpdateDisplayName } from "../../../../hooks/api/post";
// import { Sidebar } from "lorem-library";
import WavingHandSharpIcon from "@mui/icons-material/WavingHandSharp";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { GameDetails, ModalWithCloseButton } from "lorem-library";
import {
  Box,
  Divider,
  CircularProgress,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@material-ui/core";
import { sidelinks } from "../../data";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { howToPlay } from "../../../../utils/data/data";
import HowToPlay from "../../../homepage/HowToPlay";
import backgroundImage from "../../assets/svg/christmasBackground.svg";
import AchievementtBar from "../../components/achievementBar/AchievementBar";
import Profile from "../../components/profile/Profile";
import MobileMenu from "../../components/mobileNav/MobileNav";
import Sidebar from "../../components/sideBar/SideBar";
import QuizIcon from "@mui/icons-material/Quiz";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Diversity3Icon from "@mui/icons-material/Diversity3";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },
}));

export default function UsersDashboard() {
  const event_id = "a2ff469b-c09e-4ab8-b828-dc8e0e67fe68";
  const info = JSON.parse(sessionStorage.getItem("userInfo"));
  const is_existing = sessionStorage.getItem("isExisting");
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const [quizID, setQuizID] = useState();
  const [displayNamePrompt, setDisplayNamePrompt] = useState(false);
  const { data: fetchAllBCQuiz, isLoading: Loading } = useGetAllQuiz({
    eventID: info?.event_id || event_id,
    userid: info?.id,
  });

  const { data: achievementData, isLoading: isLoading } = useGetAchievementData(
    {
      userID: info?.id,
      eventID: info?.event_id || event_id,
      quizID: quizID,
      userid: info?.id,
    }
  );
  const [openHowToPlayModal, setOpenHowToPlayModal] = useState(
    localStorage.getItem("hideHelp") === "true" ? false : true
  );

  const updateDisplayName = useUpdateDisplayName(info?.id);

  useEffect(() => {
    // if (info?.display_name === null || undefined) {
    if (
      is_existing === "true" ||
      info?.display_name === "" ||
      info?.display_name === null ||
      info?.display_name === undefined
    ) {
      setDisplayNamePrompt(true);
    }
  }, [info?.display_name, is_existing]);

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (quizID === undefined) {
      setQuizID(fetchAllBCQuiz?.data[0].id);
    }
  }, [quizID, fetchAllBCQuiz?.data[0].id]);

  const handleChangeQuizID = (quizid) => {
    setQuizID(quizid);
  };

  function handleCloseHowToPlayModal() {
    setOpenHowToPlayModal(false);
  }

  let displayValidation;
  let age_rangeValidation;
  if (info?.display_name === null || info?.display_name === undefined) {
    displayValidation = Yup.string().required("Display name is Required");
  } else {
    displayValidation = Yup.string();
  }
  if (info?.age_range === null || info?.age_range === undefined) {
    displayValidation = Yup.string().required("Age Range is Required");
  } else {
    displayValidation = Yup.string();
  }

  const validationSchema = Yup.object().shape({
    display_name: displayValidation,
    age_range: age_rangeValidation,
  });

  const formik = useFormik({
    initialValues: {
      display_name: info?.display_name || "",
      age_range: info?.age_range || "",
      event_id: event_id,
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      updateDisplayName.mutate(values, {
        onSuccess: (data) => {
          toast.success("Display name successfully updated");
          setDisplayNamePrompt(false);
          navigate("/events/biblechallenge/christmas/login");
        },
        onError: (err) => {
          toast.dismiss();
          toast.error(`An error occured: ${err?.response?.data?.message} `);
        },
      });
    },
  });

  const achievementsDataInfo = [
    {
      title: "Curent Quest",
      value: "Spelling Bee",
      icon: <QuizIcon />,
    },
    {
      title: "Highest Scores",
      value:
        achievementData?.data?.highScore === null
          ? "0 "
          : achievementData?.data?.highScore,
      icon: <ScoreboardIcon />,
      info: "Player's highest score",
    },
    {
      title: "Rank",
      value: achievementData?.data?.rank,
      icon: <EmojiEventsIcon />,
      info: "Player's rank",
    },
    {
      title: "Attempts",
      value: achievementData?.data?.attempt,
      icon: <ReplayCircleFilledIcon />,
      info: "Player's number of attempt ",
    },
    {
      title: "Invited Friends",
      value: achievementData?.data?.numberOfFriends,
      icon: <Diversity3Icon />,
      info: "Player's total number of invited friends",
    },

    {
      title: "Time Spent",
      value: achievementData?.data?.timeSpentt,
      // value:timeString,
      icon: <AccessTimeIcon />,
      info: "Time spent on game",
    },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "300px auto",
        height: "100vh",
        "@media (max-width: 767px)": {
          gridTemplateColumns: "auto",
        },
      }}
    >
      <Box sx={{ backgroundColor: "#F8F8F8", padding: "15px 15px 0" }}>
        <Box
          sx={{
            borderBottom: "1.5px solid #707070 ",
            padding: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Profile
              bgColor="#469852"
              name={info?.display_name || info?.name}
              textColor="#707070"
              avatar={info?.avatar}
            />
            <MobileMenu sidelinks={sidelinks} />
          </Box>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.down("768")]: {
              display: "none",
            },
          }}
        >
          <Sidebar
            sidelinks={sidelinks}
            NavLink={NavLink}
            textcolor="#469852"
          />
        </Box>
      </Box>
      <Box className={classes.root}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              marginTop: "2rem",
              width: "50rem",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <Box sx={{ width: "100%", bgcolor: "#F24444" }} mb={2}>
              <Link
                to="https://api.whatsapp.com/message/GBEFBYHAWUIRH1"
                target={"_blank"}
              >
                <Typography variant="h6" align="center" p={1} color="white">
                  Click here to chat us on whatsapp
                </Typography>
              </Link>
            </Box>
            <AchievementtBar
              px="0 1rem 1rem 1rem"
              achievementHeading=" Statistics"
              bgColor="#F8F8F8"
              achievementsDataInfo={achievementsDataInfo}
              fetchAllBCQuiz={fetchAllBCQuiz}
              achievementData={achievementData}
              handleChangeQuizID={handleChangeQuizID}
              headingColor="#469852"
              iconColor="#469852"
              isLoading={isLoading}
              loadingColor="#469852"
            />

            <Box
              sx={{
                borderRadius: "15px",
                padding: "15px",
                minHeight: "calc(100vh - 220px)",
                backgroundColor: "#F8F8F8",
                marginTop: "40px",
                borderTopLeftRadius: " 40px",
                borderTopRightRadius: " 40px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "16px",
                  color: "#707070",
                  fontWeight: "900",
                  fontSize: "16px",
                }}
              >
                Available Games
              </Typography>
              {Loading ? (
                <CircularProgress sx={{ color: "#469852" }} />
              ) : (
                fetchAllBCQuiz?.data.map((quizData, id) => (
                  <Box>
                    <GameDetails
                      title={quizData?.quiz_title}
                      summary={quizData?.quiz_title}
                      questionCount={quizData?.quiz_question_number}
                      time={quizData?.quiz_duration}
                      icon={quizData?.avatar}
                      status={quizData?.is_active === 1 ? "status" : ""}
                      onClick={() => {
                        if (quizData?.is_active === 1) {
                          navigate(
                            `/events/biblechallenge/christmas/game?id=${quizData?.id}&user_id=${info?.id}`
                          );
                        }
                      }}
                    />
                    {fetchAllBCQuiz?.data.length > id + 1 && (
                      <Box my={2} px={{ xs: 1, md: 2 }}>
                        <Divider sx={{ border: "1px solid #e0d4d49c" }} />
                      </Box>
                    )}
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {displayNamePrompt ? (
        <ModalWithCloseButton
          open={true}
          handleClose={() => setDisplayNamePrompt(false)}
        >
          <Box sx={{ padding: " 15px" }}>
            <Box sx={{ padding: " 15px" }}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "800",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                Hello {""}
                <WavingHandSharpIcon
                  style={{ color: "#469852", marginRight: "10px" }}
                />
                {info?.name}
              </Typography>
              <Box sx={{ textAlign: "center", fontWeight: "600" }}>
                We noticed your account is not updated. Take a moment to make
                the update now so you can play the game and see your name
                proudly displayed on the leaderboard
              </Box>
            </Box>
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {(info?.display_name === "" ||
                info?.display_name === null ||
                info?.display_name === undefined) && (
                <Box>
                  <TextField
                    sx={{
                      width: "20rem",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                    }}
                    id="outlined-basic"
                    label="Display Name"
                    variant="outlined"
                    name="display_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.display_name}
                  />
                  {formik.touched.display_name &&
                    formik.errors.display_name && (
                      <Box sx={{ color: "red" }}>
                        {formik.errors.display_name}
                      </Box>
                    )}
                </Box>
              )}

              {(info?.age_range === "" ||
                info?.age_range === null ||
                info?.age_range === undefined) && (
                <Box
                  sx={{
                    "@media (max-width: 600px)": {
                      width: "100%",
                    },
                  }}
                >
                  <FormControl
                    sx={{
                      width: "20rem",
                      marginTop: "15px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                    }}
                  >
                    <InputLabel id="">Age</InputLabel>
                    <Select
                      labelId="Age"
                      id=""
                      name="age_range"
                      label="Age"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.age_range}
                    >
                      <MenuItem value="" disabled>
                        Select Age
                      </MenuItem>
                      <MenuItem value="10">10 - 12</MenuItem>
                      <MenuItem value="13">13 - 18</MenuItem>
                      <MenuItem value="18">18+</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.age_range && formik.errors.age_range && (
                    <Box sx={{ color: "red" }}>{formik.errors.age_range}</Box>
                  )}
                </Box>
              )}

              <Button
                type="submit"
                sx={{
                  height: "2.5rem",
                  backgroundColor: "#469852",
                  width: "9rem",
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  marginTop: "10px",
                  "@media (max-width: 600px)": {},
                }}
                // onClick={handleUpdateDisplayName}
              >
                Submit
              </Button>
            </form>
          </Box>
        </ModalWithCloseButton>
      ) : (
        ""
      )}
      {!displayNamePrompt && (
        <HowToPlay
          options={howToPlay}
          open={openHowToPlayModal}
          handleClose={handleCloseHowToPlayModal}
        />
      )}
    </Box>
  );
}
