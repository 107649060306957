import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Image from "material-ui-image";
import starIcon from "../../../assets/Icons2/starAward.png";
function ScoreCard({
  bgcolor = "#CAE8F8",
  avatar,
  rank,
  ordinal,
  name,
  score,
  ...rest
}) {
  return (
    <Box
      {...rest}
      sx={{
        display: !name ? "none" : "inherit",
        borderRadius: "8px",
        bgcolor: "white",
        pb: 1,
      }}
    >
      {/* Header part */}
      <Box
        sx={{
          display: "flex",
          bgcolor,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          height: 32,
          px: 0.2,
          py: 1,
          pb: 2,
        }}
      >
        <Avatar
          alt="leaderboard"
          src={avatar}
          sx={{ width: 40, height: 40, mr: 1 }}
        />

        <Typography variant="h6" pb={1} fontSize={14}>
          {rank}
          <sup>{ordinal}</sup>
        </Typography>
      </Box>
      {/* Bottom section */}
      <Box mt={4} px={1}>
        <Typography variant="h6" fontSize={10}>
          {name}
        </Typography>
        <Box mt={1} sx={{ display: "flex", alignItems: "center" }}>
          {/* Image */}
          <Box height={17} width={17} mr={1}>
            <Image
              height={17}
              width={17}
              src={starIcon}
              alt="star award"
              color="transparent"
            />
          </Box>
          <Box>
            <Typography variant="body1" fontSize={12}>
              High Score
            </Typography>
            <Typography variant="body1" fontSize={12} fontWeight={600}>
              {score}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ScoreCard;
