import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

export default function MobileMenu({ sidelinks }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Box
        sx={{
          "@media (min-width:768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {!open ? <MenuIcon /> : <CloseIcon />}
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {sidelinks?.map((link) => (
            <Link key={link.url} to={link.url}>
              <MenuItem onClick={handleClose} sx={{display:"flex", alignItems:"center"}}>
                <Box mr={1}> {link.icon} </Box>
                <Box> {link.label}</Box>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
    );
  }