import React from "react";
import Button from "../../../components/shared/Button";
import Guy2 from "../../../assets/images/guy2.png";
import Guy4 from "../../../assets/images/guy4.png";
import Shape1 from "../../../assets/svgs/background/corporateshape1.svg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const GetInTouch = () => {
  return (
    <div>
      <div className="px-4 2xl:container 2xl:mx-auto mt-10 flex flex-wrap justify-center items-center">
        <div className="md:w-1/2 p-20">
          <div className="relative  corporate-3 rounded-2xl w-full min-h-full  flex justify-center">
            <img
              src={Guy2}
              alt="laugh-man"
              width={300}
              height={100}
              className=""
            />
          </div>
        </div>
        <div className="md:w-1/2 mb-20 lg:mb-0">
          <div className="mx-auto">
            <h1 className="heading text-center md:text-left">
              Ready to Transform Learning at Your Workplace?
            </h1>
            <p className=" pb-4 heading-para">
              Get in touch with us to discover how Qwizfun can revolutionize
              corporate training and elevate your team's knowledge and
              connection. Take the first step towards a more engaged workforce.
            </p>
            <div className="flex justify-center md:justify-start">
              <Link to="/contact">
                <Button
                  type="button"
                  hoverBgColor="primaryRed-dark"
                  disabled={false}
                  borderRadius="rounded-xl"
                  fontSize="text-base"
                  textColor="text-white"
                  bgColor="bg-primaryBlue"
                  fontWeight="font-medium"
                >
                  Get in Touch
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
