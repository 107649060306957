import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Button from "./Button";
import { Link } from "react-router-dom";

const CustomNavbar = ({
  data,
  bgColor,
  textColor,
  btnText,
  btnTextLink,
  outlineText,
  outlineTextLink,
  logoSrc,
  logoAlt,
  logoLink,
  bgColors,
  navColor="white",
  loginTextColor="black"
 
  
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuAnimation = useSpring({
    transform: isMenuOpen ? "translateX(0%)" : "translateX(-100%)",
  });

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav
        className={`${bgColors}  dark:bg-gray-800 shadow border-b border-[#C2C2C2]`}
      >
        <div className="container mx-auto">
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center gap-x-14">
              <Link className="flex-shrink-0" to={logoLink}>
                <img className="w-16 h-auto" src={logoSrc} alt={logoAlt} />
              </Link>

              <div className="hidden lg:block">
                <div>
                  <ul className="inline-flex items-baseline ml-10 space-x-8">
                    {data.map((link) => (
                      <li key={link.name}>
                        <Link
                          className={`${textColor} hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium`}
                          to={link.link}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex items-baseline ml-10 space-x-4">
              {/* Additional Navigation Links */}

              <Link
                className="text-white  hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
                to={outlineTextLink}
              >
                {outlineText}
              </Link>
              <Link to={btnTextLink}>
                <Button
                  type="button"
                  hoverBgColor="primaryRed-dark"
                  disabled={false}
                  borderRadius="rounded-xl"
                  fontSize="text-base"
                  // textColor="text-black"
                  textColor={loginTextColor}
                  // bgColor={`bg-${logoBg}`}
                  bgColor={bgColor}
                  // bgColor="bg-white"
                  fontWeight="font-medium"
                >
                  {btnText}
                </Button>
              </Link>
            </div>
            {/* Responsive Menu Icon */}
            <button
              className={`lg:hidden ${navColor} focus:outline-none mr-8 md:mr-0`}
              onClick={toggleMenu}
            >
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Responsive Menu */}
      <animated.div
        className={`lg:hidden fixed top-0 left-0 w-full h-full z-50 ${bgColor} dark:bg-gray-800`}
        style={menuAnimation}
      >
        <div className="flex items-center justify-end p-4">
          <button
            className="text-white dark:text-white focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center space-y-4 pt-16">
          {/* Responsive Menu Links */}
          {data.map((link) => (
            <li key={link.name}>
              <Link
                className={`${textColor} hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium`}
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}

          <Link
            className="text-white hover:text-gray-800 px-3 py-2 rounded-md text-base font-medium"
            to={outlineTextLink}
          >
            {outlineText}
          </Link>
          <Link to={btnTextLink}>
            <Button
              type="button"
              hoverBgColor="primaryRed-dark"
              disabled={false}
              borderRadius="rounded-xl"
              fontSize="text-base"
              textColor="text-white"
              bgColor="bg-primaryRed"
              fontWeight="font-medium"
            >
              {btnText}
            </Button>
          </Link>
        </div>
      </animated.div>
    </div>
  );
};

export default CustomNavbar;
