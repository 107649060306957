import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Register from './pages/register/Register';
import Homepage from './pages/homepage/Homepage';
import Competitions from './pages/competitions/page';
import Corporate from './pages/corporate/page';
import Events from './pages/events/page';
import Home from './pages/Home/Home';
import Gameplay from './pages/gameplay/Gameplay';
import Leaderboard from './pages/Leaderboard/Leaderboard';
// import Leaderboard from "./pages/Leaderboard/Leaderboard";

import Chatwoot from './components/chatwoot/Chatwoot';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import Accessnation from './pages/corporate/pages/accessnation/Accessnation';
import Profile from './pages/profile/Profile';

// import Event from "./Event/Event";
import Event from './pages/bibleChallenge/Event';
import Register2 from './pages/events2.0/pages/register/Register';
import Login2 from './pages/events2.0/pages/login/Login';
import Homepage2 from './pages/events2.0/pages/homepage/Homepage';
import Leaderboard2 from './pages/events2.0/pages/Leaderboard/Leaderboard';
import Logout2 from './pages/events2.0/pages/logout/Logout';
import Gameplay2 from './pages/events2.0/pages/gameplay/Gameplay';
import Profile2 from './pages/events2.0/pages/profile/Profile';

import Onboarding from './pages/events2.0/pages/Onboarding/Onboarding';

import Corporate2 from './pages/events2.0/pages/corporate2/Events';
import EventLandingPage from './pages/events2.0/pages/events/Events';

import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import NewLeaderboard from './pages/events2.0/pages/Leaderboard/NewLeaderboard';
// import HowToPlay from './pages/events2.0/pages/howToPlay/HowToPlay';
// import Activities from './pages/events2.0/pages/Activities/Activities';
// import Explore from './pages/events2.0/pages/explore/Explore';
// import Notifications from './pages/events2.0/pages/notification/Notifications';
// import Feedback from './pages/events2.0/pages/feedback/Feedback';
import Events2 from './pages/events2.0/Events';

function App() {
  console.log("femi new deployment 06/21/2024 17:24")
  const { pathname } = useLocation();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#277BA0',
        dark: '#277BA0',
        light: 'rgba(39, 123, 160, 0.4)',
      },
    },
    typography: {
      fontFamily: "'Poppins', san-serif",
    },
  });

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>

        <Routes>
          {/* 2.0 for customizable */}

          <Route path='/corporate/:id' element={<Corporate2 />} />
          <Route path='/corporate/:id/register' element={<Register2 />} />
          <Route path='/corporate/:id/login' element={<Login2 />} />
          <Route path='/corporate/:id/homepage' element={<Homepage2 />} />
          <Route path='/corporate/:id/leaderboard2' element={<Leaderboard2 />} />
        <Route path='/event/:id/leaderboard' element={<NewLeaderboard />} />
          <Route path='/corporate/:id/logout' element={<Logout2 />} />
          <Route path='/corporate/:id/profile' element={<Profile2 />} />
          <Route path='/corporate/:id/gameplay' element={<Gameplay2 homeUrl='/corporate/ngx/homepage' />} />

          {/* 2.0 for customizable ends */}

          {/* 2.0 for customizable */}

          {/* <Route path='/event/:id' element={<EventLandingPage />} />
          <Route path='/event/:id/register' element={<Register2 />} />
          <Route path='/event/:id/login' element={<Login2 />} />
          <Route path='/event/:id/homepage' element={<Homepage2 />} />
          <Route path='/event/:id/leaderboard2' element={<Leaderboard2 />} />
          <Route path='/event/:id/leaderboard' element={<NewLeaderboard />} />
          <Route path='/event/:id/logout' element={<Logout2 />} />
          <Route path='/event/:id/profile' element={<Profile2 />} />
          <Route path='/event/:id/gameplay' element={<Gameplay2 />} />
          <Route path='/event/:id/onboarding' element={<Onboarding />} />
          <Route path='/event/:id/help' element={<HowToPlay />} />
          <Route path='/event/:id/activities' element={<Activities />} />
          <Route path='/event/:id/explore' element={<Explore />} />
          <Route path='/event/:id/notifications' element={<Notifications />} />
          <Route path='/event/:id/feedback' element={<Feedback />} /> */}
          <Route path='/event/*' element={<Events2 />} />
          <Route path='/event/634564/*' element={<SteamRedirectFix />} />

          {/* 2.0 for customizable ends */}

          <Route path='/corporate/accessnation/login' element={<Login />} />
          <Route path='/login' element={<Navigate to='/corporate/accessnation/login' />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/corporate/accessnation/register' element={<Register />} />
          <Route path='/register' element={<Navigate to='/corporate/accessnation/register' />} />
          <Route path='/corporate/accessnation/homepage' element={<Homepage />} />
          <Route path='/homepage' element={<Navigate to='/corporate/accessnation/homepage' />} />
          <Route path='/competitions' element={<Competitions />} />
          <Route path='/corporate/:id' element={<Corporate />} />
          <Route path='/corporate' element={<Corporate />} />
          <Route exact path='/corporate/accessnation' element={<Accessnation />} />
          <Route path='/events' element={<Events />} />
          <Route path='/' element={<Home />} />
          <Route path='/gameplay' element={<Gameplay />} />

          <Route path='/corporate/accessnation/profile' element={<Profile />} />
          <Route path='/profile' element={<Navigate to='/corporate/accessnation/profile' />} />

          <Route path='/corporate/accessnation/login' element={<Login />} />
          <Route path='/login' element={<Navigate to='/corporate/accessnation/login' />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/corporate/accessnation/register' element={<Register />} />
          <Route path='/register' element={<Navigate to='/corporate/accessnation/register' />} />
          <Route path='/corporate/accessnation/homepage' element={<Homepage />} />
          <Route path='/homepage' element={<Navigate to='/corporate/accessnation/homepage' />} />
          <Route path='/competitions' element={<Competitions />} />
          <Route path='/corporate/:id' element={<Corporate />} />
          <Route path='/corporate' element={<Corporate />} />
          <Route exact path='/corporate/accessnation' element={<Accessnation />} />
          <Route path='/events' element={<Events />} />
          <Route path='/' element={<Home />} />
          <Route path='/gameplay' element={<Gameplay />} />

          <Route path='/corporate/accessnation/profile' element={<Profile />} />
          <Route path='/profile' element={<Navigate to='/corporate/accessnation/profile' />} />

          <Route path='/corporate/accessnation/leaderboard' element={<Leaderboard />} />
          <Route path='/leaderboard' element={<Navigate to='/corporate/accessnation/leaderboard' />} />
          <Route exact path='/events/biblechallenge/christmas/*' element={<Event />} />

        </Routes>
      </ThemeProvider>

      <ToastContainer />
      {!pathname.includes('/gameplay') ? (
        <Box>
          <Chatwoot pathname={pathname} />
        </Box>
      ) : null}
    </div>
  );
}

export default App;


function SteamRedirectFix() {
  window.location.href="http://game-lb.qwizfun.com/event/683097/register"
  return <>Redirecting...Please Wait</>

}