import React from "react";
import Button from "../../../../../components/shared/Button";
import Guy2 from "../../../../../assets/images/guy2.png";
import Guy4 from "../../../../../assets/images/guy4.png";
import Shape1 from "../../../../../assets/svgs/background/corporateshape1.svg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  const videoUrl =
    "https://mobigames.s3.amazonaws.com/access-compliance-game/Quiz%20Corporate%20-%20Profile%201%20-%20Microsoft%20Edge%202023-11-17%2012-43-23.mp4";
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 px-8">
        <Fade bottom>
          <div className="flex justify-center items-center mb-20 md:mb-0">
            <div className="">
              <h1 className="heading text-black">How it works</h1>
              <div className="md:max-w-lg heading-para">
                <p className=" mb-4">
                  1. Register with your name, nickname, work email, department
                  and country.
                </p>
                <p className="mb-4">
                  2. Login with your work email used during registration and
                  select a game to start playing.
                </p>
                <p className="">
                  3. Play as many times as possible to top the leaderboard to
                  win wonderful prizes!
                </p>
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start  gap-x-5 mt-14">
                <Link to="/register">
                  <Button
                    type="button"
                    hoverBgColor="primaryRed-dark"
                    disabled={false}
                    borderRadius="rounded-none"
                    fontSize="text-base"
                    textColor="text-white"
                    bgColor="bg-accessOrange"
                    fontWeight="font-medium"
                  >
                    Register
                  </Button>
                </Link>
                <Link to="/login" className="flex justify-center">
                  <Button
                    type="button"
                    hoverBgColor="primaryRed-dark"
                    disabled={false}
                    borderRadius="rounded-none"
                    fontSize="text-base"
                    textColor="text-white"
                    bgColor="bg-[#30333A]"
                    fontWeight="font-medium"
                  >
                    Log in
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="flex justify-center items-center">
            <div className="flex items-start justify-center h-fit md:h-56 lg:h-80 w-fit lg:w-full rounded-lg">
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                title="Embedded Video"
                frameBorder="0"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
        </Fade>
      </div>
      {/* <div>
        <div className="bg-primaryPurple flex justify-center items-center gap-x-20 mt-20 px-8 lg:px-0 mb-20 lg:mb-0  ">
          <div className="md:w-1/3 hidden md:block">
            <div className="">
              <div className=" relative    rounded-2xl h-full  flex justify-center ">
                <div className="relative  corporate-2 rounded-2xl w-screen h-[60vh]  flex justify-center">
                  <img
                    src={Guy4}
                    alt="man-img"
                    width={220}
                    height={100}
                    className="absolute bottom-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3 py-10 lg:py-0">
            <div className="md:flex justify-center items-center text-white">
              <div>
                <div className="mb-6 lg:mb-0">
                  <h1 className="heading text-white  text-center">
                    Boost Team Knowledge and Connection
                  </h1>
                </div>
                <div className="heading-para">
                  <p>
                    • Elevate your corporate environment in a fun learning
                    experience and continuous development through gamification.
                  </p>
                  <p>
                    • Turn learning into a shared experience and boost your
                    team’s knowledge and connection simultaneously.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div>
        <Fade bottom>
          <div className="flex justify-end items-center  px-8 md:px-0">
            <div>
              <div className="container mx-auto flex justify-center w-full">
                <div className="pl-0  md:pl-8 ">
                  <div className="text-center  ">
                    <h1 className="heading">Our Customers Love to Use Qwiz:</h1>
                  
                  </div>
                  <p className="pb-4 text-center heading-para">
                    Leading organizations like Committee Of Wives of Lagos State
                    Officials (COWLSO) have harnessed the power of Qwiz to
                    organize engaging quizzes for their team members.
                  </p>
                 
                  <div className="flex justify-center">
                    <Button
                      type="button"
                      hoverBgColor="primaryRed-dark"
                      disabled={false}
                      borderRadius="rounded-xl"
                      fontSize="text-base"
                      textColor="text-white"
                      bgColor="bg-primaryPurple"
                      fontWeight="font-medium"
                    >
                      Explore more case studies
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full pl-32 hidden md:block ">
              <img
                src={Shape1}
                alt="laugh-man"
                width={200}
                height={300}
                className=""
              />
            </div>
          </div>
        </Fade>
      </div> */}
      <Fade bottom>
        <div className="container mx-auto my-10 md:flex flex-wrap   justify-center items-center px-8 lg:px-0">
          <div className="md:w-1/2 mb-20 md:mb-0 px-4 lg:px-4">
            <h1 className="heading text-center">
              Exciting Prizes Awaits You!{" "}
            </h1>
            <p className="heading-para  text-center">
              Participate, learn, and win thrilling prizes with Access Bank!{" "}
            </p>
          </div>

          <div className="md:w-1/2">
            <div className="relative   bg-accessGreen rounded-2xl h-72 flex justify-center">
              <div className="  w-full h-72 flex justify-center">
                <img
                  src={Guy4}
                  alt="man-img"
                  width={200}
                  height={50}
                  className="absolute bottom-0"
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default HowItWorks;
