export default function Hero2({
  paragraph,
  text,
  backgroundImage,
  imageAlt,
  image,
}) {
  return (
    <div
      className="lg:bg-cover md:bg-center bg-no-repeat mb-6 flex items-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col-reverse  items-center md:flex md:justify-between md:items-center md:p-0 md:flex-row">
        <div className="sm:w-full lg:w-full">
          <img src={image} alt={imageAlt} className="w-fit " />
        </div>
        <div className="px-8 pt-8 lg:px-0 lg:pt-8 sm:px-0 xl:ml-[10rem]">
          <h1 className="heading pb-6  font-[700] xl:w-[40rem] lg:w-[34rem] lg:pb-0 sm:w-[20rem] sm:pb-0">
            {text}
          </h1>
          <p className="heading-para pb-6 lg:w-[30rem] xl:w-[35rem] lg:pb-0 sm:w-[20rem] sm:pb-0 ">
            {paragraph}
          </p>
        </div>
      </div>
    </div>
  );
}
