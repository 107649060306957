import { Box, Button, Card, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import bg from "../../../assets/Images2/recent_activities_bg.png";
import { Icons } from "../../../assets/Icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";

function RecentActivities({ data }) {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <Box className="my-10">
      <Container maxWidth="xl">
        <Box sx={{ backgroundImage: `url("${bg}")`, backgroundSize: "cover", backgroundPosition: "center", padding: "0.7rem", borderRadius: "1rem" }}>
          <Card sx={{ height: "100%", borderRadius: "1rem", padding: "0.5rem 0" }}>
            <Container sx={{ height: "100%", display: "flex", alignItems: "center" }}>
              <Grid container spacing={2} sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                <Grid item md={3} xs={2.5}>
                  <Typography sx={{ fontSize: "0.75rem", fontFamily: "poppins", fontWeight: 600 }}>Recent Activites</Typography>
                </Grid>
                <Grid item md={9} xs={9.5} className="flex justify-between gap-1">
                  <Divider orientation="vertical" sx={{ border: 1.5, height: "100px", borderColor: "#277BA0" }} />
                  <Box>
                    <Box>
                      <Typography sx={{ fontSize: "0.7rem", fontFamily: "poppins", fontWeight: 500 }}>No. of Attempts</Typography>
                      <Box className="flex gap-1 my-1">
                        <img src={Icons?.Target} alt="" />{" "}
                        <span style={{ fontSize: "1.5rem", fontFamily: "poppins", fontWeight: 600 }}>{data?.noOfAttempt >= 0 ? data?.noOfAttempt : data?.attempt >= 0 ? data?.attempt : 0}</span>
                      </Box>
                    </Box>
                    {/* <Button sx={{ textTransform: "none", color: "#277BA0", fontSize: "0.65rem", fontWeight: 600 }} onClick={() => navigate(`/event/${id}/activities`)}>
                      See more
                    </Button> */}
                  </Box>
                  <Box>
                    <Box>
                      <Typography sx={{ fontSize: "0.7rem", fontFamily: "poppins", fontWeight: 500 }}>High Score</Typography>
                      <Box className="flex gap-1 my-1">
                        <img src={Icons?.Dice} alt="" /> <span style={{ fontSize: "1.5rem", fontFamily: "poppins", fontWeight: 600 }}>{data?.highScore ? data?.highScore : 0}</span>
                      </Box>
                    </Box>
                    <Button sx={{ textTransform: "none", color: "#277BA0", fontSize: "0.65rem", fontWeight: 600 }} onClick={() => navigate(`/event/${id}/leaderboard`)}>
                      See leaderboard
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default RecentActivities;
