import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import AuthLayout from "../../components/layout/NewAuthLayout";

import { useParams } from "react-router-dom";
import { queryClient } from "../../../..";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
import FAQ from "./FAQ";
import data from "./dummy.json";
function HowToPlay() {
  const [activeTab, setActiveTab] = useState(0);
  const [title, setTitle] = useState("How to play");
  const [content, setContent] = useState([]);
  const { id } = useParams();
  const landingPageSettings = useFetchLandingPageSettings(id);
  // console.log({ landingPageSettings });

  useEffect(() => {
    const howToPlay =
      landingPageSettings.data?.landing_page_setting?.landing_page_other_sections?.find(
        (item) => item?.section_name === "how to play"
      )?.text;
    if (!!howToPlay) {
      const parsedHowToPlay = JSON.parse(howToPlay);
      setContent(parsedHowToPlay.map((item) => item.key));
      // console.log({
      //   parsedHowToPlay2: parsedHowToPlay.map((item) => item.key),
      //   parsedHowToPlay,
      // });
    }
  }, [landingPageSettings.data]);
  function handleTabChange(event, newValue) {
    setActiveTab(newValue);
    if (newValue === 1) {
      setTitle("T & C");
    } else if (newValue === 2) {
      setTitle("FAQ");
    } else {
      setTitle("How To Play");
    }
  }

  return (
    <AuthLayout title={title}>
      {/* Tab Panel */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          bgcolor: "white",
          borderRadius: "8px",
          m: 2,
          color: "blue",
          border: "1px solid #CAE8F8",
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          label="How to play"
          color="primary"
          sx={{ color: "#277BA0", fontWeight: 600, fontSize: 12 }}
        />
        <Tab
          label="Terms & Conditions"
          sx={{ color: "#277BA0", fontWeight: 600, fontSize: 12 }}
        />
        <Tab
          label="FAQ"
          sx={{ color: "#277BA0", fontWeight: 600, fontSize: 12 }}
        />
      </Tabs>

      {/* Tab Panel */}

      {activeTab === 0 ? (
        <Box p={2}>
          {content?.map((item, index) => (
            <Typography variant="body1" fontWeight={500} mb={1} color="#333">
              {index + 1}. {item}
            </Typography>
          ))}
        </Box>
      ) : activeTab === 2 ? (
        <FAQ />
      ) : (
        <Box p={2}>
          {data.TAC?.map((item, index) => (
            <Typography
              variant="body1"
              fontWeight={500}
              mb={1}
              color="#333"
              fontSize={12}
            >
              {index + 1}.{" "}
              <span className="font-semibold text-xs">{item.key}:</span>{" "}
              {item.value}
            </Typography>
          ))}
        </Box>
      )}
    </AuthLayout>
  );
}

export default HowToPlay;
