import React from "react";

const CardComponent = ({ image, name }) => (
  <div className="p-4">
    <img src={image} alt={name} className="" />
    <div className="text-center">{name}</div>
  </div>
);

const CardSection = ({ data }) => {
  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-4 items-center  px-8 mt-20 md:mt-32">
        {data.map((card, index) => (
          <CardComponent key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default CardSection;
