import React from "react";

const About = () => {
  return (
    <div className="mt-[35rem] sm:mt-[20rem] md:mt-[20rem] lg:mt-[25rem] xl:mt-[15rem] 2xl:mt-[10rem]">
      <div className="px-16 my-10 flex justify-center">
        <div className="">
          <h1 className="heading text-black flex justify-center text-center whitespace-normal">
            Embark on a learning <br className="flex justify-center" />{" "}
            adventure where knowledge meets fun
          </h1>

          {/* <h1 className="text-header text-black flex justify-center">
            around the world, one meeting at a time
          </h1> */}
          <div className="flex justify-center mt-6">
            <p className="lg:w-2/3 h-heading-para ">
              At Qwizfun, we understand the power of engagement and we believe
              that learning should be more than just a process. It should be an
              experience that captivates, educates, and leaves a lasting impact.
              Whether you're a forward-thinking organization, an event organizer
              seeking to elevate engagement, or a competition enthusiast eager
              for thrills, Qwizfun is your dynamic solution.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
