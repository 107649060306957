import React, { useCallback } from "react";
import Image from "material-ui-image";
import { Dialog, Box, Typography } from "@mui/material";
import Card from "../../../components/cardLayout/Card";
import Correct from "../../../assets/Icons2/correct.png";
import Wrong from "../../../assets/Icons2/wrong.png";
function CorrectModal({ open, handleClose, wrong }) {
  return (
    <Dialog onClose={handleClose} open={open}>
      <Card sx={{ minWidth: "260px" }}>
        <Typography
          variant="h4"
          color={wrong ? "#E3101D" : "#10A284"}
          fontWeight={700}
          fontSize={16}
          align="center"
          mb={1}
        >
          {" "}
          {wrong ? "Wrong Answer" : " Correct Answer"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box width={157} height={144}>
            <Image src={wrong ? Wrong : Correct} aspectRatio={157 / 144} />
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
}

export default React.memo(CorrectModal);
