import React from "react";
import PageTitle from "../../components/PageTitle";
import AuthLayout from "../../components/layout/NewAuthLayout";
import RecentActivities from "../Onboarding/component/RecentActivities";
import RecentlyPlayed from "./components/RecentlyPlayed";
import Achievements from "./components/Achievements";
import { useGetActivities } from "../../hooks/api/fetch";
import LoadingState from "../../components/LoadingState";

function Activities() {
  let auth = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data, isLoading } = useGetActivities(auth?.id);
  return (
    <div>
      <AuthLayout>
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <PageTitle title={"Activities"} />
            <RecentActivities data={data} />
            <RecentlyPlayed data={data?.recentPlay} />
          </>
        )}
        {/* <Achievements /> */}
      </AuthLayout>
    </div>
  );
}

export default Activities;
