import React from "react";
import Button from "../../../../../components/shared/Button";
import LaptopMan1 from "../../../../../assets/images/laptop1.png";
import LaptopMan2 from "../../../../../assets/images/laptop2.png";
import Lady1 from "../../../../../assets/images/lady1.png";
import Lady2 from "../../../../..//assets/images/lady4.png";
import Guy1 from "../../../../../assets/images/guy1.png";
import Guy3 from "../../../../../assets/images/guy3.png";
import CorporateLeaderboard from "./CorporateLeaderboard";
import Fade from "react-reveal/Fade";
import LeaderBoard from "../../../../../components/shared/LeaderBoard";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="2xl:container 2xl:mx-auto">
      <div className="md:flex justify-between  mb-10 px-8 md:px-0 mt-10 lg:mt-0 ">
        <Fade left>
          <div className="md:w-3/4 flex justify-center items-center">
            <div className="px-4 lg:px-8 mb-10 lg:mb-0 ">
              <h1 className="heading   lg:pb-10 max-w-lg ">
                Play, Learn, and Win with Access Bank Compliance Game.
              </h1>
              <p className="pb-6 heading-para max-w-[640px]">
                Dive into the world of compliance where learning meets
                excitement and stand a chance to win big rewards. Ready for an
                exhilarating adventure and big wins?
              </p>
              <Link to="/register">
                <Button
                  type="button"
                  hoverBgColor="primaryRed-dark"
                  disabled={false}
                  borderRadius="rounded-none"
                  fontSize="text-base"
                  textColor="text-white"
                  bgColor="bg-accessOrange"
                  fontWeight="font-medium"
                >
                  Sign Up Now
                </Button>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade right delay={1000}>
          <div className="  flex  lg:justify-end">
            <div className="relative  access-1 w-fit h-fit p-4 lg:p-0   flex justify-center">
              <img
                src={Guy3}
                alt="laugh-man"
                width="100%"
                height={20}
                className="lg:mr-28 xl:mr-36 pt-10"
              />
            </div>
          </div>
        </Fade>
      </div>

      <div className="">
        <CorporateLeaderboard
          avatarNameColor="text-[#707070]"
          bgColor="bg-accessOrange"
          textColor="text-white"
          textHead="Celebrate Achievements with Access Compliance Leaderboard"
          textPara="Monitor real-time progress for you and your team at Access Bank."
        />
      </div>
    </div>
  );
};

export default Hero;
