import React from "react";
import {
  Box,
  Button,
  Grid,
  ToggleButton,
  TextField,
  Typography,
} from "@mui/material";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Card from "../../../components/cardLayout/Card";
function Question({ sx, question, options = [], setSelectedOption }) {
  return (
    <Box {...sx}>
      {/* Questions */}
      <Box
        height={250}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Card>
            <Typography variant="h3" color="#277BA0" fontWeight={700} mb={1}>
              Question
            </Typography>
            <Typography sx={{ maxWidth: "300px" }}>{question}</Typography>
          </Card>
        </Box>
      </Box>

      {/* Answers */}
      {!false ? (
        <Multichoice
          options={options || []}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <TextArea setSelectedOption={setSelectedOption} />
      )}
    </Box>
  );
}

export default Question;

function Multichoice({ options, setSelectedOption }) {
  const [alignment, setAlignment] = React.useState("");

  const handleChange = (event, value) => {
    setAlignment(value);
    console.log(value);
    setSelectedOption(value);
  };

  const alpahbets = "ABCDEFG";
  return (
    <Grid container alignItems="center">
      {options?.map((option, index) => (
        <Grid item xs={6}>
          <Box>
            <Button
              fullWidth
              name={option?.value}
              onClick={(e) => handleChange(e, option?.value)}
            >
              <Card
                small
                bgcolor={alignment === option?.value ? "#CAE8F8" : "white"}
                sx={{
                  width: "100%",
                  minHeight: "68px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" color="#333" fontSize={12}>
                  <span style={{ fontWeight: 600 }}>{alpahbets[index]}.</span>{" "}
                  {option?.label}
                </Typography>
              </Card>
            </Button>
          </Box>
        </Grid>
      ))}
      {/* <Grid item xs={6}>
        <Box>
          <Button variant="outlined" fullWidth name="a" onClick={handleChange}>
            Tello
          </Button>
        </Box>
      </Grid> */}
    </Grid>
  );
}

function TextArea({ setSelectedOption }) {
  return (
    <Box className="w-full border-sky-900">
      <Card sx={{ width: "100%" }}>
        <Typography variant="h6" className="pb-3" color="primary.main">
          Answer
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          onChange={(e) => {
            setSelectedOption(e.target.value);
            console.log(e.target.value);
          }}
        />
      </Card>
    </Box>
  );
}
