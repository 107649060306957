import { Box, Button, Card, Container, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ColorfulBg from "../../../assets/Images2/colorful_bg.svg";
import dayjs from "dayjs";
import { Icons } from "../../../assets/Icons";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import NoDataInfo from "../../../components/NoData";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
}));

function RecentlyPlayed({ data }) {
  const navigate = useNavigate();
  const classes = useStyles();
  function getPositionSuffix(position) {
    if (position % 100 >= 11 && position % 100 <= 13) {
      return "th";
    }
    switch (position % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const goToQuizPage = (id) => {
    navigate(`../quizplay?quiz_id=${id}`, { relative: "path" });
  };
  const goToGamePage = (id) => {
    navigate(`../gameplay?id=${id}`, { relative: "path" });
  };
  return (
    <Box className="my-4">
      <Container>
        <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
          Recently Played
        </Typography>
      </Container>
      {/* <Grid container spacing={2} style={{ width: '100%' }}> */}
      {data.length > 0 ? (
        <Box className="my-5 pl-5">
          <Splide
            options={{
              type: "slide",
              rewind: true,
              autoplay: true,
              perMove: 1,
              perPage: 2,
              gap: 10,
              arrows: true,
              width: "100%",
              pagination: false,
            }}
          >
            {data?.map((each) => {
              return (
                <SplideSlide key={each?.id}>
                  <Card
                    elevation={0}
                    style={{ height: "18rem", width: "100%", backgroundColor: "#ffffff", border: "1px solid #CAE8F8", borderRadius: "1rem" }}
                    onClick={() => {
                      if (each.quiz.quizType === "quiz") {
                        goToQuizPage(each.quiz.id);
                      } else {
                        goToGamePage(each.quiz.id);
                      }
                    }}
                  >
                    <Box
                      style={{
                        backgroundImage: each?.quiz?.avatar ? `url("${each?.quiz?.avatar}")` : `url("${ColorfulBg}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "40%",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                        justifyContent: "center",
                      }}
                    >
                      <Box style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, width: "100%", backgroundColor: "rgba(000,000,000,0.5)" }}></Box>
                      <Box
                        sx={{
                          fontSize: "0.8rem",
                          padding: "0.2rem 0.7rem",
                          width: "fit-content",
                          fontFamily: "poppins",
                          position: "absolute",
                          bottom: 10,
                          color: "white",
                          fontWeight: 700,
                          display: "flex",
                          gap: "0.5rem",
                        }}
                      >
                        <img src={Icons?.Play} alt="" /> Play Again
                      </Box>
                    </Box>
                    <Box className="p-1">
                      <Typography variant="body1" className={classes.ellipsis} sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
                        {each?.quiz?.quiz_title}
                      </Typography>
                      <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 400, color: "black", fontSize: "12px" }} variant="body2">
                        {dayjs(each?.quiz?.createdAt).format("DD/MM/YYYY")}
                      </Typography>
                      {/* <Box className='flex justify-end'>
                      <Button sx={{ textTransform: 'none', color: '#277BA0', fontSize: '0.7rem', fontWeight: 600 }}>See More</Button>
                    </Box> */}
                      <Box>
                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 500, color: "black", fontSize: "12px" }} variant="body2">
                          Current Score
                        </Typography>
                        <Box className="flex  gap-1 my-1">
                          <img src={Icons?.Dice} alt="" /> <span style={{ fontSize: "1.5rem", fontFamily: "poppins", fontWeight: 600 }}>{each?.point}</span>
                        </Box>
                      </Box>
                      <Box>
                        <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 500, color: "black", fontSize: "12px" }} variant="body2">
                          Leaderboard Position
                        </Typography>
                        <Box className="flex items-center gap-2 my-1">
                          <img src={Icons?.Crown} style={{ height: "2rem", width: "2rem", objectFit: "contain" }} alt="" />
                          <span style={{ fontSize: "1rem", fontFamily: "poppins", fontWeight: 600 }}>
                            {each.position}
                            {getPositionSuffix(each.position)} Position{" "}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </SplideSlide>
              );
            })}
          </Splide>
        </Box>
      ) : (
        <div className="mt-2">
          <NoDataInfo />
        </div>
      )}
      {/* </Grid> */}
    </Box>
  );
}

export default RecentlyPlayed;
