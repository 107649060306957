import { Box, Paper, Typography } from "@mui/material";

export default function LeaderboardByGroup({ CountryWinners }) {
  console.log(CountryWinners);
  return (
    <div>
      {CountryWinners?.map((country) => {
        return (
          <Paper elevation={8} sx={{ padding: "10px", marginBottom: "10px"}}>
            <Typography
              variant="body2"
              sx={{ fontWeight: "900", fontSize: "1.5rem", textAlign: "left" }}
            >
              {country?.country}
            </Typography>

            <Box>
              {country?.players?.slice(0, 3)?.map((player, index) => (
                <Box
                  sx={{
                    color: "white",
                    backgroundColor: "#469852",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "15px",
                    fontSize:"13px"

                  }}
                  key={index}
                >
                  <Box sx={{ fontWeight: "700", width: "30px" }}>
                    {index + 1}
                  </Box>
                  <Box sx={{ fontWeight: "700", width: "8rem" }}>
                    {player?.child?.name}
                  </Box>
                  <Box sx={{ fontWeight: "700", width: "2rem" }}>
                    {player?.point}
                  </Box>
                  <Box sx={{ width: "15rem", fontWeight: "700" }}>
                    {player?.child?.updatedAt}
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        );
      })}
    </div>
  );
}
