import React from "react";

function NoDataInfo() {
  return (
    <div style={{ height: "3ovh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <h3>Nothing to play here for now, check back soon</h3>
    </div>
  );
}

export default NoDataInfo;
