import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import data from "./dummy.json";
function FAQ() {
  return (
    <div className="px-4">
      <Typography variant="body1" fontSize={10} mt={3}>
        {" "}
        For live support and personalized assistance, you can reach out to our
        customer service representatives directly by clicking on the "Live Chat"
        button located in the bottom right corner of your screen. Our team is
        available to assist you during our business hours.
      </Typography>

      {data.FAQ.map((item, index) => (
        <Box key={index} my={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon sx={{ color: "#277BA0" }} />}
            >
              <Typography fontWeight={500}>{item.Question}</Typography>
            </AccordionSummary>
            <AccordionDetails>{item.Answer}</AccordionDetails>
          </Accordion>{" "}
        </Box>
      ))}
    </div>
  );
}

export default FAQ;
