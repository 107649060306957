import { Route, Routes } from "react-router-dom";
import Registration from "../bibleChallenge/pages/registration/Register";
import BibleChallengeLogin from "./pages/login/Login";
import Home from "./pages/home/LandingPage";
import FAQ from "./pages/faq/Faq";
import Rules from "./pages/rules/Rules";
import Awearness from "./pages/awearness/Awearness";
import UsersDashboard from "./pages/usersDashboard/UsersDashboard";
import UserProfile from "./pages/profilePage/ProfilePage";
import Leaderboard from "./pages/leaderboard/Leaderboard";
import Gameplay from "../gameplay/Gameplay";
import AuthenticateRoutes from "./pages/authenticateRoutes/AuthenticateRoutes"

export default function Event(){
    return (
        <div>
        <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<BibleChallengeLogin />} />
            <Route exact path="/register" element={<Registration />} />
            <Route exact path="/faq" element={<FAQ />} />
            <Route exact path="/rules" element={<Rules />} />
            <Route exact path="/dashboard" element={<UsersDashboard />} />
          {/* <Route element={<AuthenticateRoutes />}> */}
            <Route exact path="/welcome" element={<Awearness />} />
           
            <Route exact path="/profile" element={<UserProfile />} />
            <Route exact path="/leaderboard" element={<Leaderboard/>} />
            <Route exact path="game" element={<Gameplay homeUrl="/events/biblechallenge/christmas/dashboard"/>} />

          {/* </Route> */}
          </Routes>
        </div>
    )

}