import React from "react";
import Button from "./Button";


const Hero = ({
  heroHeader,
  heroPara,
  btnLabel,
  btnFontSize,
  btnTextColor,
  btnBgColor,
  btnFontWeight,
  btnBorderRadius,
  moreWord,
  imgHeight,
  imgWidth,
  imgAlt,
  imgClassName,
  imgSrc,
  bgImage,
  onClick
}) => {
  return (
    <div className="2xl:container 2xl:mx-auto">
      <div className="md:flex justify-between  mb-0 px-8 md:px-0 mt-10 lg:mt-0 ">
          <div className="md:w-1/2 flex justify-center items-center">
            <div className="px-4 lg:px-8 mb-10 lg:mb-0 ">
              <h1 className={`heading lg:pb-10 ${moreWord ? "max-w-md" : ""}`}>
                {heroHeader}
              </h1>
              <p className="pb-6 heading-para">{heroPara}</p>
              <Button
                type="button"
                disabled={false}
                borderRadius={btnBorderRadius}
                fontSize={btnFontSize}
                textColor={btnTextColor}
                bgColor={btnBgColor}
                fontWeight={btnFontWeight}
                onClick={onClick}
              >
                {btnLabel}
              </Button>
            </div>
          </div>
          
          <div className="md:w-1/2  flex  lg:justify-end">
            <div
              className={`relative  ${bgImage} w-fit h-fit lg:p-0 lg:w-full lg:h-full  flex justify-center object-cover`}
            >
              <img
                src={imgSrc}
                alt={imgAlt}
                width={imgWidth}
                height={imgHeight}
                className={imgClassName}
              />
            </div>
          </div>
      </div>
    </div>
  );
};

export default Hero;
