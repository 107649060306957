import React from "react";
import { Link } from "react-router-dom";
// import Link from "next/link";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import Modal from "../../components/modal/Modal";
import boyImage from "../../assets/images/handBoy.png";
function NoSubscription({ open, handleClose, title }) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box py={2}>
        <Typography variant="h3" fontSize={20} fontWeight={900} align="center">
          {title}
        </Typography>
        <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Box width={211}>
            <Image
              src={boyImage}
              color="transparent"
              alt="boy with opened arm"
            />
          </Box>
        </Box>
        <Typography variant="h3" fontSize={20} fontWeight={900} align="center">
          You do not have an active subscription
        </Typography>
      </Box>
      <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Link to="../plans">
          <Button variant="contained" sx={{ backgroundColor: "#F29F06" }}>
            Subscribe
          </Button>
        </Link>
      </Box>
    </Modal>
  );
}

export default NoSubscription;
