import React from "react";

import Guy4 from "../../../../../assets/images/guy4.png";
import Fade from "react-reveal/Fade";
function ImageSection({ header, summary }) {
  return (
    <Fade bottom>
      <div className="container mx-auto my-10 md:flex flex-wrap   justify-center items-center px-8 lg:px-0">
        <div className="md:w-1/2 mb-20 md:mb-0 px-4 lg:px-4">
          <h1 className="heading text-center">
            {header || "Exciting Prizes Awaits You!"}{" "}
          </h1>
          <p className="heading-para  text-center">
            {summary || " Participate, learn, and win thrilling prizes "}{" "}
          </p>
        </div>

        <div className="md:w-1/2">
          <div className="relative   bg-accessGreen rounded-2xl h-72 flex justify-center">
            <div className="  w-full h-72 flex justify-center">
              <img
                src={Guy4}
                alt="man-img"
                width={200}
                height={50}
                className="absolute bottom-0"
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default ImageSection;
