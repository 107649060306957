import React from "react";
import { Icons } from "../../../assets/Icons";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import AuthLayout from "../../../components/layout/NewAuthLayout";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
}));

function Achievements() {
  const achievements = [
    {
      name: "Achievement",
      description: "Complete Tutorial Level",
      icon: Icons?.Game,
      isLocked: false,
    },
    {
      name: "Achievement",
      description: "Visit Every Location",
      icon: Icons?.Gold,
      isLocked: true,
    },
    {
      name: "Achievement",
      description: "Finish in Record Time",
      icon: Icons?.Silver,
      isLocked: false,
    },
    {
      name: "Achievement",
      description: "Collect 100 Gems",
      icon: Icons?.Bronze,
      isLocked: true,
    },
    {
      name: "Achievement",
      description: "Complete All Challenges",
      icon: Icons?.Game,
      isLocked: false,
    },
    {
      name: "Achievement",
      description: "Earn Over 1000 Points",
      icon: Icons?.Gold,
      isLocked: true,
    },
    {
      name: "Achievement",
      description: "Master Brand New Skill",
      icon: Icons?.Silver,
      isLocked: false,
    },
    {
      name: "Achievement",
      description: "Discover 10 Secrets",
      icon: Icons?.Bronze,
      isLocked: true,
    },
    {
      name: "Achievement",
      description: "Unlock All Achievements",
      icon: Icons?.Game,
      isLocked: false,
    },
  ];

  const classes = useStyles();
  return (
    <Box>
      <Container>
        <Box className="w-full" style={{ background: "white", padding: "1rem", borderRadius: "1rem", position: "relative", zIndex: 2 }}>
          <Grid container>
            {achievements.map((achievement) => {
              return (
                <Grid item md={4} xs={4} className="flex justify-center item-center p-2">
                  <Box className="flex flex-col items-center justify-center py-1">
                    <Box style={{ position: "relative" }}>
                      <img src={achievement?.icon} alt="" style={{ height: "4rem", width: "4rem", objectFit: "contain" }} />
                      {achievement?.isLocked && (
                        <Box
                          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, width: "100%", backgroundColor: "rgba(000,000,000,0.5)", borderRadius: "1rem" }}
                          className="flex items-center justify-center"
                        >
                          <img src={Icons?.Lock} alt="" style={{ height: "2rem", width: "2rem", objectFit: "contain" }} />
                        </Box>
                      )}
                    </Box>
                    <Typography variant="body2" style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "0.75rem", textAlign: "center" }} className="">
                      {achievement?.name}
                    </Typography>
                    <Typography variant="body2" style={{ fontFamily: "Poppins", fontWeight: 400, textAlign: "center", fontSize: "0.6rem" }} className={classes.ellipsis}>
                      {achievement?.description}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Achievements;
