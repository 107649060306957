import React from "react";
import Navbar from "../../components/shared/CustomNavbar";
import Footer from "../../components/shared/Footer";
import menuData from "../../utils/data/menu.json";
import Hero from "./components/Hero";
import Logo from "../../assets/svgs/logos/logo-white.svg";
import LogoBlack from "../../assets/svgs/logos/logo-black.svg";
import { BiLogoFacebook, BiLogoTwitter, BiLogoInstagram } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";
import footerData from "../../utils/data/footer.json";

const socialLinks = [
  {
    url: "https://www.instagram.com/9ijakids",
    icon: <BiLogoInstagram className="cursor-pointer" />,
  },
  {
    url: "https://twitter.com/9ijakids?t=4YP8vgUSVJzw7KhpS9e_0Q&s=08",
    icon: <BiLogoTwitter className="cursor-pointer" />,
  },
  {
    url: "https://www.facebook.com/9ijakids",
    icon: <BiLogoFacebook className="cursor-pointer" />,
  },
  { url: "https://t.me/+B3jIp1KP4qE0MDk8", icon: <AiFillLinkedin /> },
];
const page = () => {
  const eventsLinks = menuData?.menuData.find((data) => data.events)?.events;
  // Transform links to objects with 'name' and 'link' properties
  const data = footerData.footerData.map((section) => ({
    header: section.header,
    links: section.links.map((link) => ({
      name: link,
      link: link.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-"),
    })),
  }));

  return (
    <div>
      <Navbar
        data={eventsLinks}
        bgColors="bg-lightGreen"
        textColor="text-white"
        outlineText="Help & Support"
        btnText="Log in"
        outlineTextLink="/help"
        btnTextLink="/login"
        logoLink="/"
        logoSrc={Logo}
        logoAlt="logo-image"
        bgColor="bg-white"
      />

      <Hero />
      <Footer
        data={data}
        logoSrc={LogoBlack}
        logoAlt="quiz-logo"
        logoLink="/"
        bgColor="bg-lightGreen"
        textColor="text-white"
        logoTextColor="text-lightGreen"
        placeholderColor="placeholder-lightGreen"
        inputIconColor="text-lightGreen"
        hoverColor="hover:text-gray-600"
        socialLinks={socialLinks}
        subscribeText="Stay in touch with us for the freshest products!"
        disclaimerText="Qwizfun powered by 9ijakids"
      />
    </div>
  );
};

export default page;
