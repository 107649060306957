import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Profile({ name, avatar, bgColor, textColor}) {
    return (
      <Link to="/events/bibleChallenge/christmas/profile">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width:"250px",
            "@media (max-width: 600px)": {
              // width:"0px"
              width:"max-content",
            },
          }}
        >
          <Typography variant="body1" fontWeight={900} fontSize={20}
          sx={{
            color:textColor,
            "@media (max-width: 600px)": {
              marginRight:"6px",
              width:"max-content",
              
            },
          }}

          >
            {" "}
            {name}
          </Typography>
          <Avatar
            src={avatar}
            alt="Avatar Image"
            sx={{ bgcolor: bgColor, height: 48, width: 48, padding: 1 }}
          />
        </Box>
      </Link>
    );
  }