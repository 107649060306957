import React from "react";
import { Box, Container, Typography } from "@mui/material";
// import "@fontsource/poppins";
import Image from "material-ui-image";
import qwizLogo from "../../assets/images/accessbank-logo.png";
import styles from "./Layout.module.css";
import { useNavigate } from "react-router-dom";
function Layout({ children, logo, url }) {
  return (
    <div
      className={styles.section}
      style={{
        // backgroundColor: "#F6F8F9",
        minHeight: "100vh",
      }}
    >
      <Header logo={logo} url={url} />
      <Container disableGutters className={styles.container}>
        <Box className={styles.content}>{children}</Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Layout;
export function Header({ logo, url }) {
  const navigate = useNavigate();
  return (
    <Box pt={1}>
      {/* support header */}
      <Box
        sx={{ display: "flex", justifyContent: "flexStart" }}
        mb={1}
        pl={{ xs: 3, md: 5, lg: 9 }}
      >
        <Box
          width={132}
          onClick={() => navigate(url || "/corporate/accessnation")}
          sx={{ cursor: "pointer" }}
        >
          <Image
            aspectRatio={16 / 9}
            src={logo || qwizLogo}
            color="transparent"
          />
        </Box>
      </Box>
      <Box
        height={5}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          // backgroundColor: "white",
          // backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Typography
          variant="h6"
          color="#000"
          align="center"
          fontSize={12}
          sx={{
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          For support or enquiries, please contact:{" "}
          <a
            href="https://wa.me/message/GBEFBYHAWUIRH1"
            target="_blank"
            className={styles.link}
          >
            09157504476
          </a>
        </Typography> */}
      </Box>
    </Box>
  );
}
export function Footer() {
  return (
    <Box py={4}>
      <Typography
        variant="h6"
        align="center"
        fontSize={12}
        sx={{
          fontFamily: "'Poppins', sans-serif",
          color: "#333333",
        }}
      >
        © 2023. Qwizfun - powered by 9ijakids
      </Typography>
    </Box>
  );
}
