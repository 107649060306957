import React from "react";
import { Link } from "react-router-dom";

import { RiSendPlaneLine } from "react-icons/ri";
import Fade from "react-reveal/Fade";

const Footer = ({
  data,
  bgColor,
  textColor,
  placeholderColor,
  hoverColor,
  subscribeText,
  logoSrc,
  logoAlt,
  logoLink,
  logoTextColor,
  iconsColor,
  inputIconColor,
  socialLinks,
  disclaimerText,
}) => {
  return (
    <footer className={``}>
      <Fade bottom>
        {/* <div className={`${bgColor} ${textColor} pt-4 pb-8 xl:pt-8  `}>
          <div
            className={`${bgColor} ${textColor} container mx-auto w-full px-4   sm:px-6 md:px-8`}
          >
            <ul className=" flex flex-wrap justify-center pb-8 text-lg font-light">
              {data?.map((item) => (
                <li className="w-1/2 md:w-1/2 lg:w-1/4" key={item.header}>
                  <div className="text-center">
                    <h2 className="text-[#CBD0D3]  text-xl font-bold mb-4">
                      {item.header}
                    </h2>
                    <ul>
                      {item.links.map((link) => (
                        <li
                          key={link.name} // Assuming each link has a 'name' property
                          className="mb-4 transition-colors duration-200 hover:text-gray-800  text-sm"
                        >
                          <Link to={link.link}>{link.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

        <div className=" px-16 flex flex-wrap  border-t border-gray-200 container mx-auto items-center justify-between">
          <div className=" flex justify-center items-center mb-6 lg:mb-0">
            <Link to={logoLink}>
              <img className="w-16 h-auto" src={logoSrc} alt={logoAlt} />
            </Link>
          </div>

          <div
            className={`flex items-center text-4xl space-x-10 mb-6 lg:mb-0  ${logoTextColor}`}
          >
            {socialLinks?.map((link, index) => (
              <Link
                key={index}
                to={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon}
              </Link>
            ))}
          </div>
          <div className="flex flex-wrap  gap-x-8 items-center">
            <div className="relative mb-6 md:mb-0 ">
              <input
                className={`appearance-none border-2  border-gray-300 hover:border-gray-400 transition-colors rounded-md w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none ${placeholderColor} `}
                id="email"
                type="email"
                placeholder="Email"
              />
              <div className="absolute right-0 inset-y-0 flex items-center">
                <RiSendPlaneLine
                  className={`-ml-1 mr-3 h-5 w-5 ${inputIconColor} ${hoverColor} `}
                />
              </div>
            </div>
            <div>
              <p className="font-normal text-lg">{subscribeText}</p>
            </div>
          </div>
        </div>
        <div className="text-center text-gray-600 dark:text-gray-200 py-4 sm:py-6 font-light flex items-center justify-center">
          {disclaimerText}
        </div>
      </Fade>
    </footer>
  );
};

export default Footer;
