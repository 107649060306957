// import { Form } from "lorem-library";
import { Form } from "lorem-library";
import style from "../registration/Register.module.css";
import { ModalWithCloseButton } from "lorem-library";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Box, Paper, Typography } from "@mui/material";
import useStyles from "../../components/style/BgStyle";
import { useLoginBC, useUpdateAvatar } from "../../../../hooks/api/post";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";

export default function BibleChallengeLogin() {
  const navigate = useNavigate();
  const initialValues = {
    phone_number: "",
  };
  const baseAvatar = "https://qwizmobi.s3.amazonaws.com/Avatar/";

  const avatarOptions = [
    // { avatar_link: userProfile?.avatar },
    { avatar_link: `${baseAvatar}Thumbs-UpKim.png` },
    { avatar_link: `${baseAvatar}Thumbs-UpAl.png` },
    { avatar_link: `${baseAvatar}SadKim.png` },
    { avatar_link: `${baseAvatar}NormalKim.png` },
    { avatar_link: `${baseAvatar}NormalAl.png` },
    // { avatar_link: `${baseAvatar}.png` },
    // { avatar_link: `${baseAvatar}.png` },
  ];
  const updateProfileApi = useUpdateAvatar();
  const loginAPI = useLoginBC();
  const handleFormSubmit = (values) => {
    loginAPI.mutate(values, {
      onSuccess: (data) => {
        // console.log("data", data)
        sessionStorage.setItem("userInfo", JSON.stringify(data?.data?.user));
        sessionStorage.setItem("isExisting", data?.data?.is_existing);
        sessionStorage.setItem("token", data?.data?.token);
        toast.success("Login Successfully");
        navigate("/events/bibleChallenge/christmas/dashboard");

        // assign random avata for user without avatar
        if (!data.data.user.avatar) {
          let info = data.data.user;
          let rand = Math.floor(Math.random() * 4);
          updateProfileApi.mutate(
            {
              userId: info?.id,
              data: {
                name: info.name,
                display_name: info.display_name,
                avatar_url: avatarOptions[rand].avatar_link,
                first_login: false,
              },
            },
            {
              onSuccess: () => {
                // toast.dismiss();
                // toast.success("Avatar updated successfully");
                sessionStorage.setItem(
                  "userInfo",
                  // JSON.stringify({ ...userProfile, avatar: newAvatar })
                  JSON.stringify({
                    ...info,
                    avatar: avatarOptions[rand].avatar_link,
                  })
                );
              },
              onError: (err) => {
                toast.error("Avatar update failed");
              },
            }
          );
        }
      },
      onError: (err) => {
        toast.dismiss();
        toast.error(`An error occured: ${err.response.data?.message} `);
      },
    });
  };
  const validationSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid phone number")
      .min(2, "Phone Number is too Short!")
      .max(70, "Phone Number is too Long!")
      .required("Phone Number is Required"),
  });
  const fields = [
    {
      name: "phone_number",
      label: "PhoneNumber",
      placeholder: "phone Number",
      type: "text",
      width: "full",
    },
  ];

  return (
    <Layout>
      <Box
        mt={{
          xs: 4,
          md: 4,
        }}
        p={2}
        sx={{
          marginLeft: "15rem",
          width: "500px",
          minHeight: "calc(100vh - 200px)",
          "@media (max-width: 600px)": {
            marginLeft: "0rem",
            width: "100%",
          },
        }}
      >
        <Typography variant="h5" mb={2} style={{ textAlign: "center" }}>
          Sign in
        </Typography>
        <Box sx={{ backgroundColor: "white", padding: "0.8rem" }}>
          <Form
            title="Login with your phone number"
            fields={fields}
            buttonLabel="Submit"
            initialValues={initialValues}
            handleFormSubmit={handleFormSubmit}
            validationSchema={validationSchema}
            buttonColor="red"
            disableElevation={true}
          />

          <div className={style.navigate}>
            You don’t have an account? {""}
            <Link
              to="/events/bibleChallenge/christmas/register"
              className={style.loginLink}
            >
              Register
            </Link>
          </div>
        </Box>
      </Box>
    </Layout>
  );
}
