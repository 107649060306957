import React from "react";

import Testimonial from "./../../../components/shared/Testimonial";

const HomeTestimonial = () => {
  return (
    <div className="">
      <div className="my-10">
        <div className="bg-primaryRed ">
          <p className="flex justify-center text-white heading text-center py-4 md:py-8">
            Our customers love Qwiz, <br /> so why not you
          </p>
        </div>
        <Testimonial bgColor="bg-[#F1F3F1]" />
      </div>
    </div>
  );
};

export default HomeTestimonial;
