import { makeStyles } from "@material-ui/core";
import backgroundImage from "../../assets/svg/christmasBackground.svg";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  export default useStyles;