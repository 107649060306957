import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Robot from "../../../assets/Images2/robot.svg";
import { ChevronRight } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    button: { fontFamily: "'Poppins', sans-serif", fontWeight: 600, color: "#277BA0", textTransform: "capitalize" },
    contentText: {
      [theme.breakpoints.up("md")]: {
        fontSize: "1.3rem !important",
      },
    },
  };
});
function JourneyCard({ title, description, onNext, onSkip, currentStep, onGetStarted }) {
  const classes = useStyles();
  return (
    <Box>
      <Box className="flex justify-between items-center">
        <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600, color: "black" }} variant="body2">
          {title}
        </Typography>
        {currentStep === 6 ? null : (
          <Button className={classes.button} onClick={onSkip}>
            Skip
          </Button>
        )}
      </Box>
      <Grid container>
        <Grid item md={3} xs={3}>
          <img src={Robot} style={{ height: "6.5rem", width: "auto", objectFit: "contain" }} alt="" />
        </Grid>
        <Grid item md={9} xs={9} className="flex items-center">
          <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 400, color: "#333333", textAlign: "left" }} className={classes.contentText} variant="body2">
            {description}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} className="flex justify-end">
          {currentStep === 6 ? (
            <Button className={classes.button} onClick={onGetStarted}>
              Get Started
            </Button>
          ) : (
            <Button className={classes.button} onClick={onNext} endIcon={<ChevronRight />}>
              Next
            </Button>
          )}
        </Grid>
        <Grid item xs={3}>
          {currentStep + 1} of 7
        </Grid>
        <Grid item md={8} xs={9} className="flex justify-start gap-2 items-center">
          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
            <Box key={index} style={{ height: "8px", width: "20px", borderRadius: "10px", backgroundColor: "orange", opacity: index - 1 <= currentStep ? 1 : 0.5 }}></Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default JourneyCard;
