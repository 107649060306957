import React, { useState } from 'react';
import { Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import AuthLayout from '../../components/layout/NewAuthLayout';
import { ChevronRight } from '@mui/icons-material';
import { Icons } from '../../assets/Icons';
import { makeStyles } from '@mui/styles';
import SuccessImg from '../../assets/Images2/success.png';

const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      display: 'flex',
      justifyContent: 'start',
      gap: '0.8rem',
      paddingBottom: '0.5rem',
      borderBottom: '3px solid #277BA0',
    },
    button: {
      margin: '1rem 0rem !important',
      fontFamily: 'Poppins !important',
      textTransform: 'capitalize !important',
      fontSize: '1.5rem !important',
      fontWeight: '600 !important',
    },
    clickable: {
      curson: 'pointer',
    },
  };
});
function Feedback() {
  const classes = useStyles();
  const [starClicked, setStarClicked] = useState(1);
  const [pointClicked, setPointClicked] = useState(1);
  const [success, setSuccess] = useState(false);
  const handleStarClick = (index) => {
    setStarClicked(index);
  };
  const handlePointClick = (index) => {
    setPointClicked(index);
  };
  return (
    <AuthLayout showHeader={false}>
      <Box sx={{ background: '#F5F9F9', position: 'relative', padding: '1rem' }}>
        <Container>
          <Typography variant='body1' sx={{ fontWeight: 700 }}>
            {success ? 'Feedback Submitted' : 'Rate this game/quiz'}
          </Typography>
        </Container>
      </Box>
      <Container>
        <Grid container>
          <Grid item md={12} xs={12} sx={{}} className='flex justify-end'>
            {!success && (
              <Button variant='outlined' sx={{ background: 'white', textTransform: 'none', marginRight: 'auto', margin: '1rem 0rem' }} endIcon={<ChevronRight />}>
                Skip
              </Button>
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <Box sx={{ border: '4px solid #CAE8F8', borderRadius: '1rem', textAlign: 'center', padding: '1.5rem 0rem', marginTop: success ? '4rem' : null }}>
              {success ? (
                <Container>
                  <Grid container className='flex justify-center flex-col'>
                    <Box>
                      <Typography variant='h4'>Thank You</Typography>
                      <Typography variant='body1' sx={{ fontWeight: 700 }}>
                        We appreciate your feedback.
                      </Typography>
                    </Box>
                    <Box>
                      <img src={SuccessImg} style={{ height: '25rem', objectFit: 'contain' }} alt='success avi' />
                    </Box>
                  </Grid>
                </Container>
              ) : (
                <Container>
                  <Typography variant='body1' sx={{ fontWeight: 600, fontSize: '0.87rem' }} className='text-gray-700'>
                    Thank you for playing or taking quiz/game name
                  </Typography>
                  <Typography variant='body1' sx={{ fontSize: '0.85rem' }} className='text-gray-700'>
                    We will like your feedback to help us improve your experience{' '}
                  </Typography>
                  <form className='flex gap-4 flex-col'>
                    <>
                      <label className='text-left text-gray-700 my-4'>1. How will you rate Quiz/game name ?</label>
                      <Grid container className={classes.gridContainer}>
                        <Grid item md={3} className='flex items-end leading-none'>
                          Bad
                        </Grid>
                        <Grid item md={6}>
                          <Box className='flex gap-2 items-center'>
                            {[1, 2, 3, 4, 5].map((index) => (
                              <Box>
                                <div>{index}</div>
                                <img
                                  src={index <= starClicked ? Icons.Star : Icons.Star4}
                                  alt='star-icons'
                                  style={{ height: '1.3rem', width: '1.3rem', objectFit: 'contain' }}
                                  onClick={() => handleStarClick(index)}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item md={3} className='flex items-end leading-none'>
                          Excellent
                        </Grid>
                      </Grid>
                    </>
                    <>
                      <label className='text-left text-gray-700 my-4'>2. How likely are you to recommend quiz/game name to other users?</label>
                      <Grid container className={classes.gridContainer}>
                        <Grid item md={3} className='flex items-end leading-none'>
                          Not at all
                        </Grid>
                        <Grid item md={6}>
                          <Box className='flex gap-2 items-center'>
                            {[1, 2, 3, 4, 5].map((index) => (
                              <Box>
                                <div>{index}</div>
                                <Box
                                  style={{ height: '1.3rem', width: '1.3rem', borderRadius: '50%', border: '3px solid #333333', backgroundColor: index <= pointClicked ? '#333333' : 'transparent' }}
                                  onClick={() => {
                                    handlePointClick(index);
                                  }}
                                ></Box>
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item md={3} className='flex items-end leading-none'>
                          Absolutely
                        </Grid>
                        <Box>
                          <Divider />
                        </Box>
                      </Grid>
                    </>
                    <>
                      <label className='text-left text-gray-700 my-4'>3. What did you like best about the Game / Quiz?</label>
                      <Grid container className='justify-center gap-2'>
                        <Grid item md={12} xs={12}>
                          <TextField variant='standard' placeholder='Your Answer' fullWidth />
                        </Grid>
                      </Grid>
                    </>
                    <>
                      <label className='text-left text-gray-700 my-4'>4. How can we make the platform better for you? </label>
                      <Grid container className='justify-center gap-2'>
                        <Grid item md={12} xs={12}>
                          <TextField variant='standard' placeholder='Your Answer' fullWidth />
                        </Grid>
                      </Grid>
                    </>
                  </form>
                </Container>
              )}
            </Box>
            <Container>
              {success ? (
                <Button variant='contained' fullWidth className={classes.button}>
                  See Leaderboard
                </Button>
              ) : (
                <Button
                  variant='contained'
                  fullWidth
                  className={classes.button}
                  onClick={() => {
                    setSuccess(true);
                  }}
                >
                  Submit
                </Button>
              )}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </AuthLayout>
  );
}

export default Feedback;
