import { useNavigate } from "react-router-dom";
import menuData from "../../../../utils/data/menu.json";
import Logo from "../../assets/images/logoBlack.png";
import LogoBlack from "../../assets/svg/logo-black.svg";
import heroImage from "../../assets/svg/heroImage.svg";
import Navbar from "../../../../components/shared/CustomNavbar";
import footerData from "../../../../utils/data/footer.json";
import Footer from "../../../../components/shared/Footer";
import Hero from "../../../../components/shared/Hero";
import HowItWorks from "../../../../components/shared/HowItWorks";
import Hero2 from "../../../../components/shared/Hero2";
import backgroundImg from "../../../../assets/svgs/background/Group586.svg";
import bgImage2 from "../../assets/images/hero_3.png"
import prize from "../../assets/images/Gifts.png"
import image from "../../assets/images/heroImage2.png";
import {paragraphs } from "../../data"
import {socialLinks} from "../../data"

export default function Home() {
  const navigate = useNavigate();
  const corporateLinks = menuData?.menuData.find(
    (data) => data.bibleChallenge
  )?.bibleChallenge;

  const data = footerData.footerData.map((section) => ({
    header: section.header,
    links: section.links.map((link) => ({
      name: link,
      link: link.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-"),
    })),
  }));

  const videoUrl =
  "https://qwizmobi.s3.amazonaws.com/others/Black%20and%20White%20Retro%20Countdown%20Video%20%281%29.mp4"
  
  const handleNavigate = () => navigate("/events/biblechallenge/christmas/register");
  
  return (
    <div>
      <Navbar
        data={corporateLinks}
        bgColors="bg-white"
        textColor="text-black"
        // outlineText="Help & Support"
        btnText="Log in"
        // outlineTextLink="/help"
        btnTextLink="/events/biblechallenge/christmas/login"
        logoLink="/"
        logoSrc={Logo}
        logoAlt="logo-image"
        bgColor="bg-darkRed"
        navColor="text-darkRed"
        loginTextColor="text-white"
      />
      <Hero
        heroHeader="Play, Learn, and Win Cash Prizes this Christmas!"
        heroPara="Embark on an exciting journey of interactive games with the Online Bible 
        Challenge and stand a chance to win cash prizes! Register NOW for an 
        unforgettable Christmas experience!"
        btnLabel="Register"
        btnFontSize="text-base"
        btnTextColor=" text-white"
        btnBgColor="bg-darkRed"
        btnFontWeight="font-medium"
        btnBorderRadius="rounded-xl"
        imgSrc={heroImage}
        imgWidth="100%"
        imgHeight="20"
        imgAlt="man-image"
        onClick={handleNavigate}
        bgImage="biblechallenge"
       
       

      />
      <Hero2
        text="Spreading the Gospel Through Games"
        paragraph="The annual Christmas Bible Challenge is a captivating online competition 
        aimed at spreading the Gospel through games. This year, the challenge 
        kicks off on Monday, December 18th to Sunday, December 31st, focusing 
        on the intriguing narratives surrounding the birth and life of Jesus and is 
        tailored for participants aged 10 to 21 years."
        backgroundImage={bgImage2}
        image={image}
        imageAlt="image"
      />
      <HowItWorks
        paraData={paragraphs}
        videoUrl={videoUrl}
        btnLabel1="Register"
        btnLabel2="Login"
        btnFontSize="text-base"
        btnBorderRadius="rounded-xl"
        btn1LabelBgColor="bg-darkGreen2"
        btn2LabelBgColor="bg-darkRed"
        btn1TextColor="text-white"
        btn2TextColor="text-white"
        btnFontWeight="font-medium"
        btn1To="/events/biblechallenge/christmas/register"
        btn2To="/events/biblechallenge/christmas/login"
      />
      <div className="flex justify-center items-center p-10 lg:p-0 mt-10 ">
        <div>
          <h1 className="heading text-black text-center max-w-[40rem] m-auto mb-3">
          Register, Play and Win Cash Prizes!
          </h1>
          <div className=" flex justify-center items-center bg-darkGreen2 lg:w-[624px] rounded-lg">
            <img src={prize} alt="price" className="w-[100%]  "/>
          </div>
        </div>
      </div>
      <Footer
        data={data}
        logoSrc={Logo}
        logoAlt="quiz-logo"
        logoLink="/"
        bgColor="bg-red"
        textColor="text-white"
        logoTextColor="text-darkGreen2"
        placeholderColor="placeholder-darkGreen2"
        inputIconColor="text-darkGreen2"
        hoverColor="hover:text-gray-600"
        socialLinks={socialLinks}
        subscribeText="Subscribe to our newsletter."
        disclaimerText="Qwizfun powered by 9ijakids"
      />
    </div>
  );
}
