import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Stack,
  Tabs,
  Tab,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AuthLayout from "../../components/layout/NewAuthLayout";
import ScoreCard from "./components/ScoreCard";
import ScoreRow from "./components/ScoreRow";
import {
  useFetchCompetitions,
  useGetQuiz,
  useGetQuizFunLeaderboard,
} from "../../hooks/api/fetch";
import ordinal from "ordinal-js";
function NewLeaderboard() {
  const [leaderboardType, setLeaderboardType] = useState(0);
  const [activeQuiz, setActiveQuiz] = useState("");
  const [activeCompetition, setActiveCompetition] = useState("");
  const getUserID = sessionStorage.getItem("ccAuth");
  const { id: event_code } = useParams();

  const userID = !!getUserID ? JSON.parse(getUserID)?.id : "";

  const quiz = useGetQuiz({ eventCode: event_code, userID }, "");
  const [timeline, setTimeline] = useState("");
  const leaderboardAPI = useGetQuizFunLeaderboard(activeQuiz, timeline);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const competitionList = useFetchCompetitions(userID);
  // console.log({ competitionList });
  useEffect(() => {
    if (leaderboardType === 0) {
      setLeaderboardData(leaderboardAPI.data?.leaderBoard);
    } else {
      setLeaderboardData(leaderboardAPI.data?.groupLeaderboard);
    }
    return () => setLeaderboardData([]);
  }, [leaderboardAPI.data, leaderboardType]);

  useEffect(() => {
    if (!activeQuiz) {
      setActiveQuiz(quiz.data?.data?.[0]?.id);
    }
  }, [quiz.data, activeQuiz]);

  useEffect(() => {
    if (activeCompetition === "") {
      setActiveCompetition(competitionList.data?.competitions?.[0]?.id);
    }
    // return () => {
    //   if (activeCompetition !== "") {
    //     return setActiveCompetition("");
    //   }
    // };
  }, [competitionList.data, activeCompetition]);
  return (
    <AuthLayout title="Leaderboard">
      {/* Select Competition */}
      <Box p={2}>
        <FormControl fullWidth bgcolor="white" mb={2} p={2}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{ bgcolor: "white", px: 1 }}
          >
            Select a Competition
          </InputLabel>
          <Select
            bgcolor="white"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select a Competition"
            value={activeCompetition}
            onChange={(e) => setActiveCompetition(e.target.value)}
            sx={{
              bgcolor: "white !important",
              border: "1px solid #CAE8F8 !important",
            }}
          >
            {/* <MenuItem value={" "}>All</MenuItem> */}

            {competitionList.data?.data?.competition?.map((item, index) => (
              <MenuItem key={index} value={item?.event_id}>
                {item?.event?.event_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {/* Tab panel for indvidual vs team */}
      <Tabs
        value={leaderboardType}
        onChange={(event, newValue) => {
          setLeaderboardType(newValue);
        }}
        sx={{
          bgcolor: "white",
          borderRadius: "8px",
          m: 2,
          color: "blue",
          border: "1px solid #CAE8F8",
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs leaderboard"
      >
        <Tab
          label="Single Player"
          color="primary"
          sx={{ color: "#277BA0", fontWeight: 600 }}
        />
        <Tab label="Team Play" sx={{ color: "#277BA0", fontWeight: 600 }} />
      </Tabs>

      {/*Filters  */}
      <Stack direction="row" sx={{ display: "flex", gap: "40px", mb: 2, p: 2 }}>
        <FormControl fullWidth bgcolor="white">
          <InputLabel id="demo-simple-select-label">Games</InputLabel>
          <Select
            bgcolor="white"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="All Games"
            value={activeQuiz}
            onChange={(e) => setActiveQuiz(e.target.value)}
            sx={{
              bgcolor: "white !important",
              border: "1px solid #CAE8F8 !important",
            }}
          >
            {/* <MenuItem value={" "}>All</MenuItem> */}

            {competitionList.data?.data?.competition
              ?.find((item) => item.event_id === activeCompetition)
              ?.event?.quizzes?.map((item, index) => (
                <MenuItem key={index} value={item?.id}>
                  {item?.quiz_title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Timeline</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="All Games"
            defaultValue={0}
            sx={{
              bgcolor: "white !important",
              border: "1px solid #CAE8F8 !important",
            }}
            onChange={(e) => setTimeline(e.target.value)}
          >
            <MenuItem value={"week"}>This Week</MenuItem>
            <MenuItem value={"last week"}>Last Week</MenuItem>
            <MenuItem value={" month"}>This Month</MenuItem>
            <MenuItem value={"last month"}>Last Month</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      {/* Leaderboard */}
      <Stack direction="row" justifyContent={"center"} spacing={2}>
        <Box sx={{ maxWidth: "110px" }}>
          <ScoreCard
            name={leaderboardData?.[1]?.child?.display_name}
            avatar={leaderboardData?.[1]?.child?.avatar}
            rank={2}
            ordinal={"nd"}
            score={leaderboardData?.[1]?.point}
            bgcolor="#FF8181"
          />
        </Box>
        <Box sx={{ maxWidth: "110px", transform: "scale(1.2)" }}>
          <ScoreCard
            name={leaderboardData?.[0]?.child?.display_name}
            avatar={leaderboardData?.[0]?.child?.avatar}
            rank={1}
            ordinal={"st"}
            score={leaderboardData?.[0]?.point}
          />
        </Box>
        <Box sx={{ maxWidth: "110px" }}>
          <ScoreCard
            name={leaderboardData?.[2]?.child?.display_name}
            avatar={leaderboardData?.[2]?.child?.avatar}
            rank={3}
            ordinal={"rd"}
            score={leaderboardData?.[2]?.point}
            bgcolor="#DBB46D"
          />
        </Box>
      </Stack>

      {/* score row */}
      <Stack mt={3} spacing={2}>
        {leaderboardData?.slice(3)?.map((item, index) => (
          <ScoreRow
            key={index}
            name={item?.child?.display_name}
            rank={index + 4}
            ordinal={ordinal.ordinalSuffix(index + 4)}
            score={item?.point}
            date={item?.updatedAt?.split(" ")?.[0]}
            time={item?.updatedAt?.split(" ")?.[1]}
            isUser={userID === item?.user_id}
          />
        ))}

        {/* No data */}
        {leaderboardAPI.isLoading && <>Fetching Data.. Please wait</>}
        {leaderboardData?.length < 1 && (
          <Typography variant="h6" align="center">
            No Data Found
          </Typography>
        )}
      </Stack>
    </AuthLayout>
  );
}

export default NewLeaderboard;
