import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import CorporateLeaderboard from "./components/CorporateLeaderboard";
import ImageSection from "./components/ImageSection";

import Logo from "../../assets/images/ngx.png";
import HeroImage from "../../assets/images/orange-man.PNG";
import { socialLinks } from "../corporate/Page";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
const Events = () => {
  const { id } = useParams();
  const [landingPageData, setLandingPageData] = useState({
    navbar: {
      bgColor: "white",
      buttonColor: "#106B2E",
      logo: Logo,
    },
    hero: {
      header: "Play, Learn, and Become Financially Independent with NGX!",
      summary:
        "Dive into the world of financial knowledge where learning meets excitement. Play our interactive games, enhance your financial literacy, and stand a chance to win big rewards.",
      buttonLabel: "",
      backgroundImage: "",
      bgColor: "#75B82B",
      buttonColor: "#106B2E",
    },
    leaderboard: {
      header: "Celebrate Financial Progress on the NGX Leaderboard",
      summary:
        "Monitor real-time progress as you enhance your financial knowledge and compete with others on the path to financial well-being.",
      bgColor: "#75B82B",
    },
    help: {
      texts: [
        {
          id: 1,
          content: "Register with your name and email      .",
        },
        {
          id: 2,
          content:
            " Login with your email used during registration and select a game to start playing.",
        },
        {
          id: 3,
          content: `Play as many times as possible to top the leaderboard to win wonderful prizes!`,
        },
      ],
    },
    prize: {
      header: "Exciting Prizes, Awaits You!",
      summary:
        "Participate, learn, and win prizes on your journey to financial enlightenment with NGX!",
    },
    footer: {
      logo: Logo,
    },
  });

  const landingPageAPI = useFetchLandingPageSettings(id);

  // useEffect(() => {
  //   if (landingPageAPI.data) {
  //     let result = landingPageAPI.data?.landing_page_setting;
  //     let data = {};
  //     data.navbar = {
  //       bgColor: result?.landing_page_header?.background_color,
  //       logo: result?.landing_page_header?.header_logo,
  //       bgColor: "#75B82B",
  //       buttonColor: "#106B2E",
  //     };
  //     data.hero = {
  //       header: result?.landing_page_hero?.hero_title,
  //       summary: result?.landing_page_hero?.hero_text,
  //       buttonLabel: result?.landing_page_hero?.hero_image,
  //       backgroundImage: result?.landing_page_hero?.hero_button_color,
  //     };
  //     data.help = {
  //       texts: [
  //         {
  //           id: 1,
  //           content: "Register with your name and email      .",
  //         },
  //         {
  //           id: 2,
  //           content:
  //             " Login with your email used during registration and select a game to start playing.",
  //         },
  //         {
  //           id: 3,
  //           content: `Play as many times as possible to top the leaderboard to win wonderful prizes!`,
  //         },
  //       ],
  //     };
  //     data.leaderboard = {
  //       header: result?.landing_page_body?.body_title,
  //       summary: result?.landing_page_body?.body_text,
  //     };
  //     data.footer = {
  //       logo: result?.landing_page_footer?.footer_image,
  //     };

  //     setLandingPageData((prev) => ({ ...prev, ...data }));
  //   }
  // }, [landingPageAPI.data]);
  return (
    <div>
      {(true ||
        landingPageAPI.data?.landing_page_setting?.landing_page_header) && (
        <Navbar
          logo={landingPageData.navbar.logo}
          bgColor={landingPageData?.navbar?.bgColor}
          buttonColor={landingPageData.navbar.buttonColor}
        />
      )}
      {(true ||
        landingPageAPI.data?.landing_page_setting?.landing_page_hero) && (
        <Hero
          header={landingPageData.hero.header}
          summary={landingPageData.hero.summary}
          buttonLabel={"Sign Up Now"}
          buttonBG={`bg-[${landingPageData.hero.buttonColor}]`}
          buttonColor={"white"}
          imageUrl={HeroImage}
        />
      )}
      {(true ||
        landingPageAPI.data?.landing_page_setting?.landing_page_body) && (
        <>
          <CorporateLeaderboard
            avatarNameColor="text-[#707070]"
            bgColor={
              landingPageData.leaderboard?.bgColor
                ? `bg-[${landingPageData.leaderboard.bgColor}]`
                : "bg-accessOrange"
            }
            textColor="text-white"
            textHead={landingPageData.leaderboard.header}
            textPara={landingPageData.leaderboard.summary}
          />

          <HowItWorks
            options={landingPageData.help.texts}
            registerButtonColor={"#106B2E"}
          />
          <ImageSection
            header={landingPageData.prize.header}
            summary={landingPageData.prize.summary}
          />
        </>
      )}
      {(true ||
        landingPageAPI.data?.landing_page_setting?.landing_page_footer) && (
        <Footer
          logo={landingPageData.footer.logo}
          socialLinks={socialLinks}
          bgColor="bg-accessBlue"
          textColor="text-white"
          logoTextColor="text-accessBlue"
          placeholderColor="placeholder-accessBlue"
          inputIconColor="text-accessBlue"
          hoverColor=""
        />
      )}
    </div>
  );
};

export default Events;
