import { Box, Container, Fade, Grid, Typography, Popper } from "@mui/material";
import { Icons } from "../assets/Icons";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { ModalWithCloseButton } from "lorem-library";
import Image from "material-ui-image";
import profileBG from "../assets/svgs/event2/profile-bg.png";
import { Link, useParams } from "react-router-dom";
import { useFetchStreak2 } from "../hooks/api/fetch";
const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      padding: "1rem 0rem",
    },
  };
});
export function Header({ open, setOpen, anchorElDOM, currentStep, title }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { id: navId } = useParams();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const navigation = [
    {
      name: "Dashboard",
      link: `/event/${navId}/onboarding`,
      icon: Icons?.Dashboard,
    },
    {
      name: "Profile",
      link: `/event/${navId}/profile`,
      icon: Icons?.Profile,
    },
    {
      name: "Activities",
      link: `/event/${navId}/activities`,
      icon: Icons?.Activities,
    },
    {
      name: "Explore",
      link: `/event/${navId}/explore`,
      icon: Icons?.Explore,
    },
    {
      name: "Leaderboard",
      link: `/event/${navId}/leaderboard`,
      icon: Icons?.Leaderboard,
    },
    {
      name: "Subscription",
      link: "",
      icon: Icons?.Subscription,
    },
    {
      name: "How to Play",
      link: `/event/${navId}/help`,
      icon: Icons?.Info,
    },
    {
      name: "Log Out",
      link: `/event/${navId}/logout`,
      icon: Icons?.Logout,
    },
  ];

  const [openStreak, setOpenStreak] = useState(false);

  return (
    <Box sx={{ background: "#F5F9F9", position: "relative" }}>
      <Container maxWidth="xl">
        <Grid container className={`${classes.gridContainer}`}>
          <Grid item md={6} sm={6} xs={6}>
            <img src={Icons?.Logo} alt="" />
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={6}
            className={`flex gap-5 items-center justify-end`}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  objectFit: "contain",
                }}
                onClick={() => setOpenStreak(true)}
                src={Icons?.Star}
                alt=""
              />
              <Typography
                ml={0.6}
                variant="body2"
                fontWeight={600}
                fontSize={12}
              >
                12
              </Typography>
            </Box>
            <img
              style={{
                height: "1.5rem",
                width: "1.5rem",
                objectFit: "contain",
              }}
              src={Icons?.Hamburger}
              alt=""
              aria-describedby={id}
              type="button"
              onClick={handleClick}
              id="hamburger"
            />
            <Link to={`/event/${navId}/notifications`}>
              <img
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  objectFit: "contain",
                }}
                src={Icons?.Notification}
                alt=""
                id="notification"
              />
            </Link>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl ? anchorEl : anchorElDOM}
              transition
              className="navigation"
              sx={{
                zIndex: 10,
              }}
            >
              {({ TransitionProps }) => (
                <Box className="">
                  {navigation.map((nav) => {
                    return (
                      <Link to={nav.link}>
                        <Fade {...TransitionProps} key={nav.name}>
                          <Box
                            sx={{
                              border: 0,

                              p: 1,
                              bgcolor: "background.paper",
                              color:
                                (nav.name === "Profile" && currentStep === 3) ||
                                (nav.name === "Explore" && currentStep === 4) ||
                                (nav.name === "Leaderboard" &&
                                  currentStep === 5) ||
                                (nav.name === "Activities" && currentStep === 6)
                                  ? "#277BA0"
                                  : "",
                            }}
                            className="flex gap-2"
                            id={`${nav.name}`}
                          >
                            {" "}
                            <img src={nav.icon} alt="" />
                            <span className="font-semibold">{nav.name}</span>
                          </Box>
                        </Fade>
                      </Link>
                    );
                  })}
                </Box>
              )}
            </Popper>
          </Grid>
        </Grid>
        {!!title && (
          <Box
            sx={{
              position: "relative",
              zIndex: 4,
            }}
          >
            <Box
              p={2}
              m={2}
              sx={{ background: `url(${profileBG}) no-repeat center` }}
            >
              <Box bgcolor="white" sx={{ borderRadius: "15px", p: 1, mx: 4 }}>
                <Typography
                  variant="h5"
                  align="center"
                  fontWeight={600}
                  mt={{ xs: 1, lg: 2 }}
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
      <StreakCard
        count={7}
        open={openStreak}
        handleClose={() => setOpenStreak(false)}
      />
    </Box>
  );
}
export default Header;

function StreakCard({ count, open, handleClose }) {
  const user_id = JSON.parse(sessionStorage.getItem("ccAuth"))?.id;
  // const [open, setOpen]= useState(true)
  const streak = useFetchStreak2({ user_id });
  console.log({ streak });
  return (
    <ModalWithCloseButton open={open} handleClose={handleClose}>
      <Box p={2}>
        <Typography
          variant="body1"
          fontSize={12}
          fontWeight={600}
          color="#333333"
          mb={2}
        >
          {count} days streak
        </Typography>
        <Typography variant="body1" fontSize={12} color="#333333" mb={2}>
          Play a game or a quiz today to keep your daily streak
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {new Array(count)
            .fill("")
            .slice(0, 5)
            .map((_, index) => (
              <Box
                sx={{
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 40,
                  width: 40,
                  bgcolor: "#F8FAFC",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="body2" fontSize={8}>
                    Day {index + 1}
                  </Typography>
                  <Box height={16} width={16}>
                    <Image src={Icons?.Star2} alt={index} />
                  </Box>
                </Box>
              </Box>
            ))}
          {count > 5 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>...</Typography>
              <Box
                sx={{
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 48,
                  width: 48,
                  bgcolor: "#f8fafc",
                }}
              >
                <Box>
                  <Typography variant="body2" fontSize={8}>
                    Day {count}
                  </Typography>
                  <Box height={16} width={16}>
                    <Image src={Icons?.Star2} alt={count} />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* </Box> */}
        </Box>
      </Box>
    </ModalWithCloseButton>
  );
}
