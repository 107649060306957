import React from "react";
import { Box, Container, Typography } from "@mui/material";
// import "@fontsource/poppins";
import Image from "material-ui-image";
import qwizLogo from "../../assets/images/accessbank-logo.png";
import styles from "./Layout.module.css";
import { useNavigate } from "react-router-dom";
// import elipse-top-bg-img from "../..//assets/svgs/event2/elipse-bg.png"
import elipseTop from "../../assets/svgs/event2/elipse-bg.png";
import elipseBottom from "../../assets/svgs/event2/elipse-2-bg.png";
function Layout({ children, logo, url }) {
  return (
    <Box
      //   className={styles.section}
      style={{
        backgroundColor: "#F8FAFC",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          background: `url(${elipseTop})`,
          position: "absolute",
          top: "0",
          left: "0",
        }}
        height={238}
        width={238}
      />
      <Box
        sx={{
          background: `url(${elipseBottom})`,
          position: "absolute",
          bottom: "0",
          right: "0",
        }}
        height={238}
        width={238}
      />

      {/* <Header logo={logo} url={url} /> */}
      <Box pt={4} sx={{ display: "flex", justifyContent: "center" }}>
        <Box width={64}>
          <Image src={logo} color="transparent" />
        </Box>
      </Box>
      <Container disableGutters className={styles.container}>
        <Box className={styles.content}>{children}</Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default Layout;
export function Header({ logo, url }) {
  const navigate = useNavigate();
  return (
    <Box pt={1}>
      {/* support header */}
      <Box
        sx={{ display: "flex", justifyContent: "flexStart" }}
        mb={1}
        pl={{ xs: 3, md: 5, lg: 9 }}
      >
        <Box
          width={132}
          onClick={() => navigate(url || "/corporate/accessnation")}
          sx={{ cursor: "pointer" }}
        >
          <Image
            aspectRatio={16 / 9}
            src={logo || qwizLogo}
            color="transparent"
          />
        </Box>
      </Box>
      <Box
        height={5}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          // backgroundColor: "white",
          // backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Box>
    </Box>
  );
}
export function Footer() {
  return (
    <Box py={4} pb={{ xs: 0, md: 4 }}>
      <Typography
        variant="h6"
        align="center"
        fontSize={12}
        sx={{
          fontFamily: "'Poppins', sans-serif",
          color: "#333333",
        }}
      >
        © 2023. Qwizfun - powered by 9ijakids
      </Typography>
    </Box>
  );
}
