import React from "react";
import menuData from "../../../../utils/data/menu.json";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

const Accessnation = () => {
  return (
    <div>
      <Navbar />
      <Hero />

      <HowItWorks />
      <Footer
        bgColor="bg-accessBlue"
        textColor="text-white"
        logoTextColor="text-accessBlue"
        placeholderColor="placeholder-accessBlue"
        inputIconColor="text-accessBlue"
        hoverColor=""
      />
    </div>
  );
};

export default Accessnation;
