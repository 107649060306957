import React from "react";
import Button from "../../../components/shared/Button";
import { Link } from "react-router-dom";
import peopleImg from "../../../assets/svgs/background/people.svg";

const Hero = () => {
  return (
    <div className="px-16 my-10 container mx-auto">
      <div className="text-black text-center">
        <h1 className="heading pb-3">Play, Compete, Learn, Win! </h1>
        <p className="text-sm sm:text-base lg:text-3xl  pb-4">
          Welcome to Qwiz – Where Learning Meets Fun!
        </p>
        {/* <p className="text-3xl pb-4">Lorem ipsum dolor sit amet, conse</p> */}
        <div className="flex justify-center">
          <Link to="corporate">
            <Button
              type="button"
              disabled={false}
              borderRadius="rounded-xl"
              fontSize="text-2xl"
              textColor="text-white"
              bgColor="bg-primaryRed"
              fontWeight="font-semibold"
              hoverBgColor="bg-white border border-primaryRed hover:text-red-500"
            >
              Get started
            </Button>
          </Link>
        </div>
      </div>
      <div className="relative mt-20 ">
        <div className="relative  ">
          <div className="">
            <img src={peopleImg} alt="" />
          </div>
          <div className="absolute top-24 md:top-56  lg:top-96  bg-primaryRed rounded-lg lg:rounded-[93px]  p-4 md:p-6">
            <div className="lg:flex">
              <div className="lg:w-1/3 flex justify-center items-center mb-6 lg:mb-0 w-full  ">
                <h1 className="text-white font-bold  text-base md:text:xl lg:text-4xl ">
                  Engage Your <br className="hidden lg:block" /> your{" "}
                  <span className="text-gold">Users</span>{" "}
                  <br className="hidden lg:block" /> in 3 Simple steps
                </h1>
              </div>
              <div className="lg:w-2/3 text-white  flex flex-wrap md:flex-nowrap">
                <div className="p-2 md:p-4  md:border-r-2 border-white">
                  <h3 className="font-semibold text-xl md:text-2xl mb-4">
                    {" "}
                    Empower
                  </h3>
                  <p className="sub-heading">
                    Transform learning into exciting adventures, making every
                    moment an opportunity to learn and grow.
                  </p>
                </div>
                <div className="p-2 md:p-4 md:border-r-2 border-white">
                  <h3 className="font-semibold text-xl md:text-2xl mb-4">
                    Organize
                  </h3>
                  <p className="sub-heading">
                    Organize quizzes that adapt to your needs, providing a
                    platform that's as versatile as you are.
                  </p>
                </div>
                <div className="p-4 ">
                  <h3 className="font-semibold text-xl md:text-2xl mb-4">
                    Unlock Fun
                  </h3>
                  <p className="sub-heading">
                    Discover a world where fun and development go hand in hand,
                    fostering growth, creativity, and joy. and joy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
