import React from "react";
import InfoIcon from "../../assets/svgs/background/info.svg";
import StarIcon from "../../assets/svgs/background/star.svg";
import CloseIcon from "../../assets/svgs/background/closeIcon.svg";
import KingIcon from "../../assets/svgs/background/king.svg";
import Fade from "react-reveal/Fade";

const LeaderBoard = ({ bgColor, subColor, textHead, textPara }) => {
  return (
    <div>
      <div className=" md:flex justify-between items-center gap-20 px-8 lg:px-0 mt-40 md:mt-0 container mx-auto mb-20">
        <Fade bottom>
          <div className="md:w-1/2 flex justify-center mb-10 md:mb-0">
            <div className="relative ">
              <div className="flex justify-center items-end gap-2 ">
                <div className="flex justify-center cursor-pointer">
                  <img src={InfoIcon} alt="info-icon" className="h-8 w-8" />
                </div>
                <div className={`max-w-xs  ${bgColor} pb-2`}>
                  <div className={`max-w-xs  ${subColor} p-2`}>
                    <span className="text-5xl font-normal text-white">
                      Leaderboard
                    </span>
                  </div>
                </div>

                <div className="flex justify-center cursor-pointer">
                  <img src={CloseIcon} alt="close-icon" className="h-8 w-8" />
                </div>
              </div>
              <div className={`${bgColor} max-w-md pb-2 rounded-b-[19.927px]`}>
                <div className={`${subColor} pb-2 rounded-b-[19.927px]`}>
                  <div className="bg-white rounded-b-[19.927px] p-4">
                    <div className="mt-10 flex justify-between items-center rounded-sm p-2 bg-white text-[#603940] drop-shadow-xl">
                      <div className="flex items-center gap-2">
                        <span>1.</span>
                        <div className="flex justify-center">
                          <img
                            src={KingIcon}
                            alt="king-icon"
                            className="h-8 w-8"
                          />
                        </div>
                        <div>
                          <span>Name</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>
                          <span>0000</span>
                        </div>
                        <div className="flex justify-center">
                          <img
                            src={StarIcon}
                            alt="star-icon"
                            className="h-8 w-8"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`mt-4 flex justify-between items-center rounded-sm p-2 ${subColor} text-white drop-shadow-xl`}
                    >
                      <div className="flex items-center gap-2">
                        <span>2.</span>
                        <div className="flex justify-center">
                          <img
                            src={KingIcon}
                            alt="king-icon"
                            className="h-8 w-8"
                          />
                        </div>
                        <div>
                          <span>USERNAME</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>
                          <span>0000</span>
                        </div>
                        <div className="flex justify-center">
                          <img
                            src={StarIcon}
                            alt="star-icon"
                            className="h-8 w-8"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom delay={500}>
          <div className="md:w-1/2">
            <h1 className="heading text-center">{textHead}</h1>
            <p className="heading-para text-center">{textPara}</p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default LeaderBoard;
