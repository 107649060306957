import CryptoJS from "crypto-js";
const useDecrypt = () => {
    const key = "1e440fksdfbU9jbv"
    const decrypt = (word) => {
        var bytes = CryptoJS.AES.decrypt(word, key);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
    
        return originalText;
    }
    return {decrypt}
}
export default useDecrypt