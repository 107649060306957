import React from "react";
import Fola from "../../assets/images/Fola.png";
import Taiwo from "../../assets/images/Taiwo.png";
import Samuel from "../../assets/images/samuel.png";
import { Carousel } from "react-responsive-carousel";

const cardData = [
  {
    image: Fola,
    name: "FOLA ADEKUNLE",
    text: "I want to  applaud the concept behind the Qwiz platform. The element of            surprise and the desire to keep going when playing the quizzes                and games create a truly engaging experience on the platform",
  },
  {
    image: Taiwo,
    name: " TAIWO ANIFOWOSE",
    text: "I played the  games and quizzes on the Qwiz platform and became top on 2  occasions. The games and quizzes really tested me, but I was  able to prove that with resilience anything is possible. Winning         not only boosts my self-confidence but also exposes me to                 learning opportunities as I've learned tons in the past weeks.",
  },
  {
    image: Samuel,
    name: "SAMUEL EDEMA",
    text: "The Qwiz was really fun and challenging.I got to know more of A.I tools, and it broaden my knowledge on their uses.The feedback that pops up after each quiz is also shows the importance of feedback throughout the learning process.This is something I would recommend to so many persons.",
  },
];

const Testimonial = ({ bgColor }) => {
  return (
    <div>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={3500}
      >
        {cardData.map((card, index) => (
          <div className={`${bgColor} dark:bg-gray-800 w-full mx-auto p-8`}>
            <div className="flex flex-col items-center justify-center md:items-start md:flex-row">
              <div className="flex justify-center rounded-full">
                <img
                  alt="profile"
                  src={card.image}
                  className="mx-auto object-cover rounded-full h-40 w-40"
                />
              </div>
              <div className="w-full md:w-2/3">
                <p className="text-gray-600 dark:text-white w-full md:w-2/3 m-auto text-left text-lg md:text-3xl">
                  <span className="font-bold text-indigo-500">“</span>{" "}
                  {card.text}
                  <span className="font-bold text-indigo-500">”</span>
                </p>
                <div className="flex items-center justify-center mt-8">
                  <span className="mr-2 text-lg font-semibold text-indigo-500">
                    - {card.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonial;
