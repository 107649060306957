import React, { useEffect, useState } from "react";
import Footer from "../../components/shared/Footer";
import Navbar from "../../components/shared/CustomNavbar";
import menuData from "../../utils/data/menu.json";
import Hero from "./components/Hero";
import CorporateWorks from "./components/CorporateWorks";
import CorporateTestimonial from "./components/CorporateTestimonial";
// import Logo from "../../assets/svgs/logos/logo-white.svg";
// import LogoBlack from "../../assets/svgs/logos/logo-black.svg";

import LogoBlack from "../../assets/images/ngx.png";
import Logo from "../../assets/images/ngx.png";
import { BiLogoFacebook, BiLogoTwitter, BiLogoInstagram } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";
import footerData from "../../utils/data/footer.json";
import Guy3 from "../../assets/images/guy3.png";
import LeaderBoard from "../../components/shared/LeaderBoard";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
import { useParams } from "react-router-dom";

export const socialLinks = [
  {
    url: "https://www.instagram.com/ngxgrp",
    icon: <BiLogoInstagram className="cursor-pointer" />,
  },
  {
    url: "https://twitter.com/ngxgrp",
    icon: <BiLogoTwitter className="cursor-pointer" />,
  },
  {
    url: "https://www.facebook.com/ngxgroup",
    icon: <BiLogoFacebook className="cursor-pointer" />,
  },
  { url: "http://www.linkedin.com/company/ngxgroup", icon: <AiFillLinkedin /> },
];

const paragraphs = [
  {
    id: 1,
    content: "Register with your name and email      .",
  },
  {
    id: 2,
    content:
      " Login with your email used during registration and select a game to start playing.",
  },
  {
    id: 3,
    content: `Play as many times as possible to top the leaderboard to win wonderful prizes!`,
  },
];

const Page = () => {
  const { id } = useParams();
  const corporateLinks = menuData?.menuData.find(
    (data) => data.corporate
  )?.corporate;

  const videoUrl =
    "https://mobigames.s3.amazonaws.com/access-compliance-game/Quiz%20Corporate%20-%20Profile%201%20-%20Microsoft%20Edge%202023-11-17%2012-43-23.mp4";

  // Transform links to objects with 'name' and 'link' properties
  const data = footerData.footerData.map((section) => ({
    header: section.header,
    links: section.links.map((link) => ({
      name: link,
      link: link.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-"),
    })),
  }));

  const [landingPageData, setLandingPageData] = useState({
    navbar: {
      bgColor: "",
      logo: Logo,
    },
    hero: {
      header: "Play, Learn, and Become Financially Independent with NGX!",
      summary:
        "Dive into the world of financial knowledge where learning meets excitement. Play our interactive games, enhance your financial literacy, and stand a chance to win big rewards.",
      buttonLabel: "",
      backgroundImage: "",
    },
    leaderboard: {
      header: "Celebrate Financial Progress on the NGX Leaderboard",
      summary:
        "Monitor real-time progress as you enhance your financial knowledge and compete with others on the path to financial well-being.",
    },
    help: {
      texts: paragraphs,
    },
  });

  const landingPageAPI = useFetchLandingPageSettings(id || "093876");
  useEffect(() => {
    if (landingPageAPI.data) {
      let result = landingPageAPI.data?.landing_page_setting;
      result.navbar = {
        bgColor: result?.landing_page_header?.background_color,
        logo: result?.landing_page_header?.header_logo,
      };
      result.hero = {
        header: result?.landing_page_hero?.hero_title,
        summary: result?.landing_page_hero?.hero_text,
        buttonLabel: result?.landing_page_hero?.hero_image,
        backgroundImage: result?.landing_page_hero?.hero_button_color,
      };

      setLandingPageData(result);
    }
  }, [landingPageAPI.data]);
  return (
    <div>
      <Navbar
        data={corporateLinks}
        bgColors="bg-[#75bb2b]"
        textColor="text-white"
        outlineText="Help & Support"
        btnText="Log in"
        outlineTextLink="/help"
        btnTextLink="login"
        logoLink="/"
        logoSrc={landingPageData.navbar.logo}
        logoAlt="logo-image"
        loginTextColor="text-white"
        bgColor=" bg-[#106B2E]"
      />
      <Hero
        // heroHeader="Engage and Boost Your Team Knowledge with Qwizfun"
        heroHeader={landingPageData.hero.header}
        heroPara={landingPageData.hero.summary}
        btnLabel="Book a Demo Now"
        btnFontSize="text-base"
        btnTextColor=" text-white"
        btnBgColor="bg-[#75bb2b]"
        btnFontWeight="font-medium"
        btnBorderRadius="rounded-xl"
        imgSrc={Guy3}
        imgWidth="90%"
        imgHeight="20"
        imgAlt="man-image"
        bgImage="corporate-1 bg-[#106B2E]"
      />

      <LeaderBoard
        bgColor="bg-deepPurple"
        subColor="bg-primaryPurple"
        textHead="Celebrate Achievements with Qwizfun Leaderboard"
        textPara="See and track you and your team's progress in real-time."
      />

      <CorporateTestimonial />
      <CorporateWorks
        paraData={landingPageData.help.texts}
        videoUrl={videoUrl}
        btnLabel1="Sign up for a free trial now"
        btnLabel2="Play now"
        btnFontSize="text-base"
        btnBorderRadius="rounded-xl"
        btn1LabelBgColor="bg-[#75bb2b]"
        btn2LabelBgColor="bg-[#75bb2b]"
        btn1TextColor="text-white"
        btn2TextColor="text-white"
        btnFontWeight="font-medium"
        btn1To="register"
        btn2To="login"
      />
      <Footer
        data={data}
        logoSrc={LogoBlack}
        logoAlt="quiz-logo"
        logoLink=""
        bgColor="bg-[#75bb2b]"
        textColor="text-white"
        logoTextColor="text-[#75bb2b]"
        placeholderColor="placeholder-[#75bb2b]"
        inputIconColor="text-[#75bb2b]"
        hoverColor="hover:text-gray-600"
        socialLinks={socialLinks}
        subscribeText="Stay in touch with us for the freshest products!"
        disclaimerText="Qwizfun powered by 9ijakids"
      />
    </div>
  );
};

export default Page;
