
import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

const HowItWorks = ({
  paraData,
  videoUrl,
  btnLabel1,
  btnLabel2,
  btnFontSize,
  btnBorderRadius,
  btn1LabelBgColor,
  btn2LabelBgColor,
  btn1TextColor,
  btn2TextColor,
  btnFontWeight,
  btn1To,
  btn2To,
}) => {
  return (
    <div className="">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 px-8">
          <div className="flex justify-center items-center mb-20 md:mb-0">
            <div className="">
              <h1 className="heading text-black">How it works</h1>
              <div>
                {paraData?.map((paragraph) => (
                  <p key={paragraph.id} className="heading-para mb-4">
                    {`${paragraph.id}. ${paragraph.content}`}
                  </p>
                ))}
              </div>
              {/* <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start  gap-x-10 mt-14"> */}
              <div className="flex items-center flex-col md:flex-row">
                <Link to={btn1To}>
                  <Button
                    type="button"
                    disabled={false}
                    borderRadius={btnBorderRadius}
                    fontSize={btnFontSize}
                    textColor={btn1TextColor}
                    bgColor={btn1LabelBgColor}
                    fontWeight={btnFontWeight}
                  >
                    {btnLabel1}
                  </Button>
                </Link>
                {/* <Link to={btn2To} className="mt-6 md:mt-0 flex justify-center"> */}
                <Link to={btn2To} className="md:ml-8 md:mt-0 mt-6">
                  <Button
                    type="button"
                    disabled={false}
                    borderRadius={btnBorderRadius}
                    fontSize={btnFontSize}
                    textColor={btn2TextColor}
                    bgColor={btn2LabelBgColor}
                    fontWeight={btnFontWeight}
                  >
                    {btnLabel2}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex items-start justify-center h-fit md:h-56 lg:h-80 w-fit lg:w-full rounded-lg">
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                title="Embedded Video"
                frameBorder="0"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default HowItWorks;
