import React, { useState, useEffect, useContext } from "react";
import LeaderboardByGroup from "./groupLeaderboard/LeaderboardByGroup";
import {
  useGetAllQuiz,
  useGetBCLeaderboard,
} from "../../../../hooks/api/fetch";
// import { Sidebar } from "lorem-library";
import { NavLink } from "react-router-dom";
import { Box, MenuItem, FormControl, Select } from "@mui/material";
import { leaderboardByGrpData } from "../../data";
import { makeStyles } from "@material-ui/core";
import { sidelinks } from "../../data";
import backgroundImage from "../../assets/svg/christmasBackground.svg";
import Profile from "../../components/profile/Profile";
import MobileMenu from "../../components/mobileNav/MobileNav";
import { useTheme } from "@mui/material/styles";
import Sidebar from "../../components/sideBar/SideBar";
import LeaderboardCard from "../../../Leaderboard/LeaderboardCard";
import { SocketContext } from "../../../../context/socket";
import ErrorIcon from "@mui/icons-material/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },
}));

export default function Leaderboard() {
  const info = JSON.parse(sessionStorage.getItem("userInfo"));
  const classes = useStyles();
  const theme = useTheme();
  const [reloader, setReloader] = useState(0);
  const [showGrpLeaderboard, setShowGrpLeaderboard] = useState(false);
  const curentQuizID = sessionStorage.getItem("current Quiz");
  const [quizID, setQuizID] = useState(curentQuizID);
  const { data, isLoading: loadingState } = useGetBCLeaderboard({
    // userID: info?.user_id,
    userID: info?.id,
    age: info?.age_range,
    quizID: quizID,
  });
  const [showPrevious, setShowPrevious] = useState(false);
  const [active, setActive] = useState("individual");
  const socket = useContext(SocketContext);
  const { data: fetchAllBCQuiz, isLoading: Loading } = useGetAllQuiz({
    eventID: info?.event_id,
    userid: info?.id,
  });

  useEffect(() => {
    function reloadLeaderboard() {
      setReloader((e) => e + 1);
      // Add function to reload leaderboard here
    }

    if (socket.connected) {
      // Add Socket logic here
      socket.on(`leaderboard-${quizID}`, reloadLeaderboard);
      return () => socket.off(`leaderboard-${quizID}`, reloadLeaderboard);
    }
  }, [socket.connected]);

  // Get top 3 winners in separate array
  let TopWinners = data?.leaderBoard?.slice(0, 3);
  const TopWinnersPrevious = data?.overallLeaderboard?.slice(0, 3);

  //sort the array in assending order.
  let sortedArray = TopWinners?.slice().sort(function (a, b) {
    return b - a;
  });
  let sortedArrayPrevious = TopWinnersPrevious?.slice().sort(function (a, b) {
    return b - a;
  });

  // Get Rest of the Players
  const players = data?.leaderBoard?.slice(3)?.map((data) => {
    if (!data?.child?.display_name) {
      data.child.display_name = data?.child?.name;
    }
    return data;
  });
  const Previousplayers = data?.overallLeaderboard.slice(3)?.map((data) => {
    if (!data?.child?.display_name) {
      data.child.display_name = data?.child?.name;
    }
    return data;
  });

  // get player index
  const [activePreviousIndex, setActivePreviousIndex] = useState(
    Previousplayers?.findIndex((data) => {
      // console.log({ api: data.user_id });
      return data.user_id === info.id;
    })
  );
  const [activeIndex, setActiveIndex] = useState(
    players?.findIndex((data) => {
      // console.log({ api: data.user_id });
      return data.user_id === info.id;
    })
  );

  // use effect to update state of active index

  useEffect(() => {
    setActiveIndex(
      players?.findIndex((data) => {
        // console.log({ api: data.user_id });
        return data.user_id === info.id;
      })
    );
    setActivePreviousIndex(
      Previousplayers?.findIndex((data) => {
        // console.log({ api: data.user_id });
        return data.user_id === info.id;
      })
    );
  }, [players, Previousplayers]);

  //rearrange Winner array
  const middleIndex = Math.floor(TopWinners?.length / 2);
  const firstElement = TopWinners?.shift();
  // set the Top winner in the middle
  const rearrangedArray = [
    ...(TopWinners ?? []).slice(0, middleIndex),
    firstElement,
    ...(TopWinners ?? []).slice(middleIndex),
  ];
  //rearrange Winner array
  const PreviousMiddleIndex = Math.floor(TopWinnersPrevious?.length / 2);
  const PreviousfirstElement = TopWinnersPrevious?.shift();
  // set the Top winner in the middle
  const rearrangedPreviousArray = [
    ...(TopWinnersPrevious ?? [])?.slice(0, PreviousMiddleIndex),
    PreviousfirstElement,
    ...(TopWinnersPrevious ?? [])?.slice(PreviousMiddleIndex),
  ];

  // HANDLE ACTIVE SCREEN
  const activeScreen = (id) => {
    setActive(id);
  };
  useEffect(() => {
    if (!quizID) {
      setQuizID(fetchAllBCQuiz?.data?.[0]?.id);
    }
  }, [fetchAllBCQuiz?.data]);

  const handleQuizOnchange = (event) => {
    const newQuizID = event.target.value;
    if (newQuizID) {
      setQuizID(newQuizID);
    }
  };

  const currentPlayer = players?.find(
    (player) => player?.child?.display_name === info?.display_name
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "300px auto",
        height: "100vh",
        "@media (max-width: 767px)": {
          gridTemplateColumns: "auto",
        },
      }}
    >
      <Box sx={{ backgroundColor: "#F8F8F8", padding: "15px 15px 0" }}>
        <Box
          sx={{
            borderBottom: "1.5px solid #707070 ",
            padding: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Profile
              bgColor="#469852"
              name={info?.display_name}
              textColor="#707070"
              avatar={info?.avatar}
            />
            <MobileMenu sidelinks={sidelinks} />
          </Box>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.down("768")]: {
              display: "none",
            },
          }}
        >
          <Sidebar
            sidelinks={sidelinks}
            NavLink={NavLink}
            textcolor="#469852"
          />
        </Box>
      </Box>
      <Box className={classes.root}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              marginTop: "10px",
              // width: "50rem",

              //   backgroundColor:"red",
              "@media (max-width: 600px)": {
                // width: "100%",
              },
            }}
          >
            <h1 className="text-black text-center text-[28px] font-[900]">
              Leaderboard
            </h1>
            <section className=" w-full md:w-[40rem] md:p-6 md:ml-[5rem] text-center rounded-t-lg rounded-tl-lg rounded-tr-lg pt-[1.2rem] mt-[1.2rem]  bg-white h-[35rem] overflow-auto">
              <FormControl
                fullWidth
                sx={{
                  border: "2px solid #469852",
                  borderRadius: "8px",
                  "@media (min-width: 900px)": { width: "25rem" },
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={quizID}
                  onChange={handleQuizOnchange}
                >
                  {fetchAllBCQuiz?.data?.map((quiz) => (
                    <MenuItem key={quiz?.id} value={quiz?.id}>
                      {quiz?.quiz_title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="mb-8 mt-8 w-full">
                <button
                  className={`${
                    active === "individual"
                      ? " w-[7rem] h-[2rem] text-md font-[900] rounded-l-[16px] bg-darkGreen2 [0.3rem]  text-[14px] text-white mb-6"
                      : " w-[7rem] h-[2rem] text-[14px] text-darkGreen2 text-md font-[900] rounded-l-[16px] border-2 border-solid border-darkGreen2 [0.3rem]  mb-6"
                  }`}
                  onClick={() => {
                    activeScreen("individual");
                    setShowPrevious(false);
                    setShowGrpLeaderboard(false);
                  }}
                >
                  Individual
                </button>
                <button
                  className={`${
                    active === "overall"
                      ? "border-none w-[7rem] h-[2rem] text-white text-[14px] text-md font-[900]  bg-darkGreen2  mb-6"
                      : " w-[7rem] h-[2rem] text-[14px]  text-darkGreen2  text-md font-[900]  border-2 border-solid border-darkGreen2 [0.3rem]  mb-6"
                  }`}
                  onClick={() => {
                    activeScreen("overall");
                    setShowPrevious(true);
                    setShowGrpLeaderboard(false);
                  }}
                >
                  Overall
                </button>
                <button
                  className={`${
                    active === "country"
                      ? "border-none w-[7rem] h-[2rem] text-white text-[14px] text-md font-[900] rounded-r-[16px] bg-darkGreen2 mb-6"
                      : " w-[7rem] h-[2rem] text-[14px]  text-darkGreen2  text-md font-[900] rounded-r-[16px] border-2 border-solid border-darkGreen2 [0.3rem]  mb-6"
                  }`}
                  onClick={() => {
                    activeScreen("country");
                    setShowGrpLeaderboard(true);
                  }}
                >
                  Country
                </button>
              </div>
              <section>
                {showGrpLeaderboard ? (
                  data?.countryLeaderboard?.length < 1 ? (
                    <div className="leaderboardDataError">
                      <ErrorIcon />
                      No data available for leaderboard by county.
                    </div>
                  ) : (
                    <LeaderboardByGroup
                      CountryWinners={data?.countryLeaderboard}
                    />
                  )
                ) : // <LeaderboardByGroup CountryWinners={leaderboardByGrpData}/>
                showPrevious ? (
                  <>
                    {data?.overallLeaderboard?.length < 1 ? (
                      <div className="leaderboardDataError">
                        <ErrorIcon />
                        No data available for overall leaderboard.
                      </div>
                    ) : (
                      <LeaderboardCard
                        TopWinners={TopWinnersPrevious}
                        players={Previousplayers}
                        activePlayerIndex={activePreviousIndex}
                        rearrangedArray={rearrangedPreviousArray}
                        active={active}
                        loadingState={loadingState}
                        sortedArray={sortedArrayPrevious}
                        backgroundColor="darkGreen2"
                        currentPlayer={currentPlayer}
                        // backgroundColor = {currentPlayer? "red" : "darkGreen2"}
                        winners="darkGreen2"
                        playersColor="red"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {data?.leaderBoard?.length < 1 ? (
                      <div className="leaderboardDataError">
                        <ErrorIcon />
                        No data available for individual leaderboard.
                      </div>
                    ) : (
                      <LeaderboardCard
                        TopWinners={TopWinners}
                        players={players}
                        activePlayerIndex={activeIndex}
                        rearrangedArray={rearrangedArray}
                        active={active}
                        loadingState={loadingState}
                        sortedArray={sortedArray}
                        backgroundColor="darkGreen2"
                        winners="darkGreen2"
                        playersColor="red"
                        currentPlayer={currentPlayer}
                      />
                    )}
                  </>
                )}
              </section>

              {/* {showGrpLeaderboard ? 
               <LeaderboardByGroup/>
               :
             showPrevious ? (
                <>
                  {data?.overallLeaderboard?.length < 1 ? (
                    <div className="leaderboardDataError">
                      <ErrorIcon />
                      No data available for overall leaderboard.
                    </div>
                  ) : (
                    <LeaderboardCard
                      TopWinners={TopWinnersPrevious}
                      players={Previousplayers}
                      rearrangedArray={rearrangedPreviousArray}
                      active={active}
                      loadingState={loadingState}
                      sortedArray={sortedArrayPrevious}
                      backgroundColor="darkGreen2"
                      winners="darkGreen2"
                    />
                  )}
                </>
              ) : (
                <>
                  {data?.leaderBoard?.length < 1 ? (
                    <div className="leaderboardDataError">
                      <ErrorIcon />
                      No data available for individual leaderbaord.
                    </div>
                  ) : (
                    <LeaderboardCard
                      TopWinners={TopWinners}
                      players={players}
                      rearrangedArray={rearrangedArray}
                      active={active}
                      loadingState={loadingState}
                      sortedArray={sortedArray}
                      backgroundColor="darkGreen2"
                      winners="darkGreen2"
                    />
                  )}
                </>
              )}  */}
            </section>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
