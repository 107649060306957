import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Box, Button, Grid, Typography } from "@mui/material";
import Image from "material-ui-image";
import Header from "../quizPlay/components/Header";
import CorrectIcon from "../../assets/Icons2/golden-correct-tick.png";
import WrongIcon from "../../assets/Icons2/wrong-x.png";
import ClapGIF from "../../assets/Gif/clap.GIF";
function QuizCompleted() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  console.log({ searchParams });
  const score = searchParams.get("score");
  const quiz_id = searchParams.get("quiz_id");
  const rank = searchParams.get("rank");
  const attempts = searchParams.get("attempts");
  const correct = searchParams.get("correct");
  const wrong = searchParams.get("wrong");
  return (
    <Box>
      <Header title="Quiz Name" />
      {/* Body */}
      <Box p={2}>
        {/* Button */}
        <Box>
          <Button onClick={() => navigate(`/event/${id}/onboarding`)}>
            <Typography fontWeight={600} fontSize={12}>
              {" "}
              Go To Homepage
            </Typography>
          </Button>
        </Box>

        {/* Frame for quiz completed */}
        <Box className="flex justify-center">
          <Box className="w-full max-w-[348px] p-2 mt-5 border-[#CAE8F8] border-4 rounded-lg">
            <Typography
              variant="h4"
              className="text-center text-[#D8942E] text-bold pt-4"
              fontSize={24}
              fontWeight={700}
            >
              {" "}
              Quiz Completed
            </Typography>

            {/* Image */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box width={168} height={152} className="bg-sky-500 my-5">
                {/* <video src={ClapGIF} loop autoPlay /> */}
                <Image src={ClapGIF} />
              </Box>
            </Box>

            {/* Text */}
            <Typography
              fontWeight={700}
              fontSize={20}
              className="text-[#277BA0] text-center font-bold mb-2"
            >
              {" "}
              Score: {score}
            </Typography>
            <Typography
              fontWeight={700}
              fontSize={14}
              className="text-[#333333] text-center font-bold mb-2"
            >
              {" "}
              Rank: {rank}
            </Typography>
            <Typography
              fontWeight={700}
              fontSize={14}
              className="text-[#333333] text-center font-bold mb-2"
            >
              Attempts: {attempts}
            </Typography>

            {/* Crroect Answers */}
            <Grid container mt={2}>
              <Grid item xs={6} justifyContent="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontWeight={700}
                    className="font-bold text-[#707070] pb-1"
                  >
                    Correct Answers
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box width={23} height={28} mr={1}>
                      <Image
                        src={CorrectIcon}
                        color="transparent"
                        aspectRatio={23 / 28}
                      />
                    </Box>
                    <Typography
                      fontWeight={700}
                      className="font-bold text-[#707070] "
                    >
                      {correct}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} justifyContent="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontWeight={700}
                    className="font-bold text-[#707070] pb-1 "
                  >
                    Wrong Answers
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box width={27} height={24} mr={1}>
                      <Image
                        src={WrongIcon}
                        color="transparent"
                        aspectRatio={27 / 24}
                      />
                    </Box>
                    <Typography
                      fontWeight={700}
                      className="font-bold text-[#707070] "
                    >
                      {wrong}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Play again */}

            <Box
              className="flex justify-right mt-4"
              sx={{ justifyContent: "right" }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(`/event/${id}/quizplay?quiz_id=${quiz_id}`)
                }
              >
                Play Again
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Leaderboard */}
        <Box mt={2}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={() => navigate(`/event/${id}/leaderboard`)}
          >
            {" "}
            See Leaderboard
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default QuizCompleted;
