import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Form, Formik, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useRegistration } from "../../../hooks/api/post";
import * as Yup from "yup";
import {
  useFetchDepartments,
  useFetchCountries,
} from "../../../hooks/api/fetch";

function RegisterForm({ setErrorMessage, setOpenErrorModal }) {
  const navigate = useNavigate();
  const registerApi = useRegistration();
  const departments = useFetchDepartments();
  const countries = useFetchCountries();
  const registerSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Name is too Short!")
      .max(70, "Name is too Long!")
      .required("Name is Required"),
    last_name: Yup.string()
      .min(2, "Name is too Short!")
      .max(70, "Name is too Long!")
      .required("Name is Required"),

    display_name: Yup.string()
      .min(6, "Display Name should be at least 6 characters ")
      .max(30, "Display Name is Too Long!")
      .required("Display Name is Required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      // .matches(
      //   /.+@theaccesscorporation.com$/,
      //   "Email address must end with @theaccesscorporation.com"
      // )
      .matches(
        /theaccesscorporation.com|accessbankplc.com|accesspension.ng/i,
        "Email address must be a valid work email"
      )
      .required("Email is Required")
      .test(
        "first-last-name",
        "Email must include First name and Last Name ",
        (value, ctx) => {
          let fullname = ctx.parent.first_name + "." + ctx.parent.last_name;
          if (!value.toLowerCase().includes(fullname.toLowerCase())) {
            return false;
          }
          return true;
          // console.log({ value, ctx });
        }
      ),
  });
  return (
    <Paper elevation={6}>
      <Box p={{ xs: 2, md: 2 }}>
        <Typography variant="h6" fontSize={12}>
          Sign Up with correct details
        </Typography>
        <Box>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              event_code: "091098",
              display_name: "",
              loginField: "email",
              group_id: "122",
              country: "",
              email: "",
            }}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              registerApi.mutate(values, {
                onSuccess: (response) => {
                  toast.success("Success!");
                  const res = response.data;
                  console.log({ res });
                  const userInfo = JSON.stringify({
                    token: res?.token,
                    id: res?.id,
                    displayName: res?.display_name,
                    name: res?.name,
                    avatar: res?.avatar,
                  });

                  sessionStorage.setItem("ccAuth", userInfo);
                  sessionStorage.setItem(
                    "displayName",
                    res?.subcriber?.display_name
                  );
                  localStorage.setItem("newbie", "yes");
                  navigate("/homepage");
                },
                onError: (err) => {
                  console.log({ err: err.response?.data?.message });
                  setErrorMessage(err?.response?.data?.message);
                  setOpenErrorModal((err) => !err);
                },
                onSettled: () => {
                  setSubmitting(false);
                },
              });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={"15px"}
                >
                  <Box my={2}>
                    <TextField
                      fullWidth
                      data-test-id="firstname"
                      label="First Name"
                      variant="outlined"
                      onChange={(e) =>
                        setFieldValue("first_name", e.target.value.trim())
                      }
                    />{" "}
                    <Box sx={{ color: "error.main", width: "100%" }}>
                      <ErrorMessage name="first_name" />
                    </Box>
                  </Box>
                  <Box my={2}>
                    <TextField
                      fullWidth
                      data-test-id="lastname"
                      label="Last Name"
                      variant="outlined"
                      onChange={(e) =>
                        setFieldValue("last_name", e.target.value.trim())
                      }
                    />{" "}
                    <Box sx={{ color: "error.main", width: "100%" }}>
                      <ErrorMessage name="last_name" />
                    </Box>
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={"15px"}
                >
                  <Box my={2}>
                    <TextField
                      fullWidth
                      data-test-id="display name"
                      label="Display Name"
                      variant="outlined"
                      onChange={(e) =>
                        setFieldValue("display_name", e.target.value.trim())
                      }
                    />{" "}
                    <Box sx={{ color: "error.main", width: "100%" }}>
                      <ErrorMessage name="display_name" />
                    </Box>
                  </Box>

                  <Box my={2}>
                    <TextField
                      fullWidth
                      data-test-id="email"
                      label="Work Email"
                      variant="outlined"
                      onChange={(e) =>
                        setFieldValue("email", e.target.value.trim())
                      }
                    />
                    <Box sx={{ color: "error.main" }}>
                      <ErrorMessage name="email" />
                    </Box>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={"15px"}
                >
                  <Box my={2} sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="">Division</InputLabel>
                      <Select
                        required
                        labelId="division"
                        data-test-id="division"
                        id="division"
                        label="Division"
                        onChange={(e) =>
                          setFieldValue("group_id", e.target.value)
                        }
                      >
                        <MenuItem value="">Select</MenuItem>
                        {departments?.data?.groups?.map((dept) => (
                          <MenuItem value={dept.id} key={dept?.id}>
                            {dept.group_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box my={2} sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="">Country</InputLabel>
                      <Select
                        fullWidth
                        data-test-id="country"
                        required
                        labelId="country"
                        id="country"
                        label="Country"
                        onChange={(e) =>
                          setFieldValue("country", e.target.value)
                        }
                      >
                        <MenuItem value="">Select</MenuItem>
                        {countries?.data?.countries?.map((dept, index) => (
                          <MenuItem value={dept.name} key={index}>
                            {dept.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>

                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    // bgcolor="#277BA0"
                    sx={{ backgroundColor: "rgb(113 51 186 ) !important" }}
                  >
                    {isSubmitting ? "Registering" : "Register"}
                  </Button>
                </Box>
                <Box my={2}>
                  <Typography variant="body2" align="center">
                    Already have an account?
                    <Box component="span" color="green">
                      <Link to="/login"> Login</Link>
                    </Box>
                  </Typography>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
}

export default RegisterForm;
