import React from "react";
import Button from "../../../components/shared/Button";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-router-dom";
import Testimonial from "../../../components/shared/Testimonial";

const CorporateTestimonial = () => {
  return (
    <div className="">
      <div className="my-10">
        <div className="bg-primaryPurple p-4 ">
          <Zoom>
            <div className="container mx-auto">
              <p className="heading  text-center text-white  md:py-4">
                Log in and dive into the world of interactive learning.
              </p>
              <div className="flex justify-center">
                <Link to="/login">
                  <Button
                    type="button"
                    hoverBgColor="primaryRed-dark"
                    disabled={false}
                    borderRadius="rounded-xl"
                    fontSize="text-base"
                    textColor="text-black"
                    bgColor="bg-white"
                    fontWeight="font-medium"
                  >
                    Client log in
                  </Button>
                </Link>
              </div>
            </div>
          </Zoom>
        </div>
        <Testimonial bgColor="bg-white" />
      </div>
    </div>
  );
};

export default CorporateTestimonial;
