import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useGetQuizFunLeaderboard, useGetQuiz } from "../../hooks/api/fetch";
//component
import LeaderboardCard from "../Leaderboard/LeaderboardCard";
import AuthLayout from "../../components/layout/AuthLayout";
import { SocketContext } from "../../context/socket";
//ICON / IMAGE
import ErrorIcon from "@mui/icons-material/Error";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import qwizLogo from "../../assets/images/ngx.png";
export default function Leaderboard() {
  const { id: event_code } = useParams();
  const [reloader, setReloader] = useState(0);
  const getUserID = sessionStorage.getItem("ccAuth");
  const userID = JSON.parse(getUserID);
  const token = userID.token;
  const Uid = userID.id;
  const curentQuizID = sessionStorage.getItem("current Quiz");
  const [quizID, setQuizID] = useState(curentQuizID);
  // const [quizID, setQuizID] = useState(null);
  const [changedQuiz, setChangeQuiz] = useState();
  const quiz = useGetQuiz({ eventCode: event_code, userID: Uid }, quizID);
  const { data, isLoading: loadingState } = useGetQuizFunLeaderboard(quizID);
  const [showPrevious, setShowPrevious] = useState(false);
  const [active, setActive] = useState("individual");
  const socket = useContext(SocketContext);

  // useEffect(() => {
  //   function reloadLeaderboard() {
  //     setReloader((e) => e + 1);
  //     // Add function to reload leaderboard here
  //   }

  //   if (socket?.connected) {
  //     // Add Socket logic here
  //     socket.on(`leaderboard-${quizID}`, reloadLeaderboard);
  //     return () => socket.off(`leaderboard-${quizID}`, reloadLeaderboard);
  //   }
  // }, [socket?.connected]);

  // Get top 3 winners in separate array
  let TopWinners = data?.leaderBoard?.slice(0, 3);
  const TopWinnersPrevious = data?.groupLeaderboard?.slice(0, 3);

  //sort the array in assending order.
  let sortedArray = TopWinners?.slice().sort(function (a, b) {
    return b - a;
  });
  let sortedArrayPrevious = TopWinnersPrevious?.slice().sort(function (a, b) {
    return b - a;
  });

  // Get Rest of the Players
  const players = data?.leaderBoard?.slice(3);
  const Previousplayers = data?.groupLeaderboard.slice(3);

  //rearrange Winner array
  const middleIndex = Math.floor(TopWinners?.length / 2);
  const firstElement = TopWinners?.shift();
  // set the Top winner in the middle
  const rearrangedArray = [
    ...(TopWinners ?? []).slice(0, middleIndex),
    firstElement,
    ...(TopWinners ?? []).slice(middleIndex),
  ];
  //rearrange Winner array
  const PreviousMiddleIndex = Math.floor(TopWinnersPrevious?.length / 2);
  const PreviousfirstElement = TopWinnersPrevious?.shift();
  // set the Top winner in the middle
  const rearrangedPreviousArray = [
    ...(TopWinnersPrevious ?? [])?.slice(0, PreviousMiddleIndex),
    PreviousfirstElement,
    ...(TopWinnersPrevious ?? [])?.slice(PreviousMiddleIndex),
  ];

  // HANDLE ACTIVE SCREEN
  const activeScreen = (id) => {
    setActive(id);
  };
  useEffect(() => {
    if (!quizID) {
      setQuizID(quiz?.data?.data?.[0]?.id);
    }
  }, [quiz?.data?.data]);

  const handleQuizOnchange = (event) => {
    const newQuizID = event.target.value;
    if (newQuizID) {
      setQuizID(newQuizID);
    }
  };

  return (
    <AuthLayout logo={qwizLogo}>
      <main className="leaderboardsection">
        <h1 className="text-black text-center text-[28px] font-[900] mt-2">
          Leaderboard
        </h1>
        <section className=" w-full md:w-[40rem] md:p-6 text-center rounded-t-lg rounded-tl-lg rounded-tr-lg pt-[1.2rem] mt-[1.2rem] p-[20px] bg-white h-full min-h-screen">
          <FormControl
            fullWidth
            sx={{
              border: "2px solid #E88105",
              borderRadius: "8px",
              "@media (min-width: 900px)": { width: "25rem" },
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={quizID}
              onChange={handleQuizOnchange}
            >
              {quiz?.data?.data?.map((quiz) => (
                <MenuItem key={quiz?.id} value={quiz?.id}>
                  {quiz?.quiz_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="mb-8 mt-8">
            <button
              className={`${
                active === "individual"
                  ? " w-[8rem] h-[2rem] text-md font-[900] rounded-l-[16px] bg-accessOrange [0.3rem]  text-[14px] text-white mb-6"
                  : " w-[8rem] h-[2rem] text-[14px] text-accessOrange text-md font-[900] rounded-l-[16px] border-2 border-solid border-accessOrange [0.3rem]  mb-6"
              }`}
              onClick={() => {
                activeScreen("individual");
                setShowPrevious(false);
              }}
            >
              Individual
            </button>
            <button
              className={`${
                active === "coporate"
                  ? "border-none w-[8rem] h-[2rem] text-white text-[14px] text-md font-[900] rounded-r-[16px] bg-accessOrange mr-[0.3rem] mb-6"
                  : " w-[8rem] h-[2rem] text-[14px]  text-accessOrange  text-md font-[900] rounded-r-[16px] border-2 border-solid border-accessOrange [0.3rem]  mb-6"
              }`}
              onClick={() => {
                activeScreen("coporate");
                setShowPrevious(true);
              }}
            >
              Division
            </button>
          </div>
          {showPrevious ? (
            <>
              {data?.groupLeaderboard?.length < 1 ? (
                <div className="leaderboardDataError">
                  <ErrorIcon />
                  No data available for coporate leaderboard.
                </div>
              ) : (
                <LeaderboardCard
                  TopWinners={TopWinnersPrevious}
                  players={Previousplayers}
                  rearrangedArray={rearrangedPreviousArray}
                  active={active}
                  loadingState={loadingState}
                  sortedArray={sortedArrayPrevious}
                />
              )}
            </>
          ) : (
            <>
              {data?.leaderBoard?.length < 1 ? (
                <div className="leaderboardDataError">
                  <ErrorIcon />
                  No data available for individual leaderbaord.
                </div>
              ) : (
                <LeaderboardCard
                  TopWinners={TopWinners}
                  players={players}
                  rearrangedArray={rearrangedArray}
                  active={active}
                  loadingState={loadingState}
                  sortedArray={sortedArray}
                />
              )}
            </>
          )}
        </section>
      </main>
      {/* <Socket setReloader={setReloader} quizID={quizID} token={token} /> */}
    </AuthLayout>
  );
}
