import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useScreenOrientation from "use-screen-orientation";
import { usePlayGame } from "../../hooks/api/fetch";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useWindowSize } from "use-window-size-hook";
import IframeResizer from "iframe-resizer-react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useLogGameplayTime } from "../../hooks/api/post";
function Gameplay({ homeUrl }) {
  const logTimeAPI = useLogGameplayTime();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const quizID = searchParams.get("id");

  sessionStorage.setItem("current Quiz", quizID);

  const userID =
    searchParams.get("user_id") || "ab11ceb3-15e3-48cf-848e-726861514893";
  const playGameQuery = usePlayGame({ player_id: userID, quiz_id: quizID });
  const [orientation, setOrientation] = useState(
    playGameQuery?.data?.quiz?.is_portraits === 1 ? "portrait" : "landscape"
  );

  const url =
    "https://mobigames.s3.eu-west-2.amazonaws.com/access-compliance-game/Access_RiskyAdventures/index.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQ2XSIUXYP3EKN6V2%2F20231115%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20231115T084835Z&X-Amz-Expires=3600&X-Amz-Signature=9b44388bdf499a8af7e16b5bfece039cf47a305297fbcf02623c08a60951d26e&X-Amz-SignedHeaders=host&age_group=null&display_name=Hemarle&user_id=e212fba9-b6c8-4c8b-a507-9bd9c33e1f58";

  // (A) LOCK SCREEN ORIENTATION TO LANDSCAPE IF ON GAME PLAY PAGE
  const [appleMode, setAppleMode] = useState(false);
  const lock = () => {
    // (A1) GO INTO FULL SCREEN FIRST
    let de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    } else if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen();
    } else if (de.webkitRequestFullscreen) {
      de.webkitRequestFullscreen();
    } else if (de.msRequestFullscreen) {
      de.msRequestFullscreen();
    }

    // (A2) THEN LOCK ORIENTATION
    orientation === "landscape" &&
      window.screen.orientation.lock("landscape").then(
        (success) => console.log({ success }),
        (failure) => console.log({ failure })
      );
  };
  const [screenOrientation, screenAngle] = useScreenOrientation();
  const { width } = useWindowSize();
  console.log({ screenOrientation });
  // useEffect(() => {
  //   if (window.orientation === 90) {
  //     setScreenOrientation("landscape");
  //   } else {
  //     setScreenOrientation("portrait");
  //   }
  // }, [screenOrientation]);

  useEffect(() => {
    var isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    var isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    console.log({ orientation, isAndroid });
    if (isIOS) {
      setAppleMode(true);
    } else if (isAndroid) {
      sessionStorage.setItem("orientation", "locked");
      // lock();
    }

    //NOTE: SEE APP.JS TO FIND UNLOCK ORIENTATION FUNCTION

    if (
      orientation === "landscape" &&
      width < 768 &&
      screenOrientation === "portrait"
    ) {
      toast.dismiss();
      toast.warn(
        "Game will be better enjoyed if played in landscape mode. Kindly rotate your screen"
      );
    }
  }, [orientation, width, screenOrientation]);

  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: "left", // This can be left or right
      locale: "en", // Language to be set
      useBrowserLanguage: false, // Set widget language from user's browser
      baseDomain: ".9ijakids.com",
      baseUrl: "https://teamhubpm.9ijakids.com",
      type: "standard", // [standard, expanded_bubble]
      darkMode: "auto", // [light, auto]
    };

    window.addEventListener("chatwoot", function () {
      // Use window.$chatwoot here
      window?.$chatwoot?.toggle("close");
      window?.$chatwoot?.toggleBubbleVisibility("hide");
      // ...
      // console.log({ APPchatwootSDK: window.$chatwoot });
    });
    // window.$chatwoot.toggle("close");
    // window?.$chatwoot?.toggleBubbleVisibility("hide");
    // console.log({ APPchatwootSDK: window.$chatwoot });
  }, []);

  // Log play time
  let oldDate = new Date();
  function logTime(oldDate) {
    // console.log({ oldDate });
    const date = new Date();
    let timediff =
      (date.getUTCHours() - oldDate.getUTCHours()) * 3600 +
      (date.getUTCMinutes() - oldDate.getUTCMinutes()) * 60 +
      (date.getUTCSeconds() - oldDate.getUTCSeconds());
    let diff = {
      minute: date.getUTCMinutes() - oldDate.getUTCMinutes(),
      seconds: date.getUTCSeconds() - oldDate.getUTCSeconds(),
      hour: date.getUTCHours() - oldDate.getUTCHours(),
    };
    return timediff;
  }
  let timeSpent = () => {
    const timeDiff = logTime(oldDate);
    console.log({ timeSpent: timeDiff });
    oldDate = new Date();
    // log time api
    if (timeDiff > 2) {
      logTimeAPI.mutate({
        user_id: userID,
        quiz_id: quizID,
        time_spent: timeDiff,
      });
    }
    // return timeSpent;
  };
  useEffect(() => {
    oldDate = new Date();

    document.addEventListener("visibilitychange", timeSpent);

    return () => {
      document.removeEventListener("visibilitychange", timeSpent);
    };
  }, []);

  const gameList = sessionStorage.getItem("gameList");
  const [games, setGames] = useState([]);
  useEffect(() => {
    if (!!gameList) {
      let newGames = JSON.parse(gameList);
      setGames(newGames);
    }
  }, [gameList]);

  if (playGameQuery?.isError || !quizID || !userID) {
    navigate(!!homeUrl ? homeUrl : "/homepage");
  }
  if (playGameQuery.isLoading) {
    return <>Please Wait ....</>;
  }

  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* {orientation === "landscape" &&
      width < 768 &&
      screenOrientation === "portrait" ? (
        <Box>
          <Typography variant="body1" align="center">
            Games can only be played in Landscape Mode, kindly Rotate your
            screen
          </Typography>
       
        </Box>
      ) : (
        <Box sx={{ minHeight: "100vh" }} className="iframe-container">
          <IframeResizer
            frameBorder={0}
            title="gameplay"
            src={playGameQuery.data?.filePath}
          />
        </Box>
      )} */}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          size="small"
          onClick={() => {
            timeSpent();
            navigate(!!homeUrl ? homeUrl : "/homepage");
            window?.$chatwoot?.toggleBubbleVisibility("show");
          }}
        >
          <CloseIcon color="error" fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ minHeight: "100vh" }} className="iframe-container">
        <IframeResizer
          frameBorder={0}
          title="gameplay"
          src={games[0]?.quizUrl}
          // src={playGameQuery.data?.filePath}
        />
      </Box>
    </Box>
  );
}

export default Gameplay;
