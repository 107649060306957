import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
// import "@fontsource/poppins";
import { Footer } from "./Layout";
// import { sidelinks } from "../../../components/TopNav/TopNav";
import styles from "./AuthLayout.module.css";

import Image from "material-ui-image";
import qwizLogo from "../../assets/images/accessbank-logo.png";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
// import { usePathname } from "next/navigation";

function AuthLayout({ children }) {
  const userProfile =
    typeof window !== "undefined" &&
    JSON.parse(sessionStorage.getItem("ccAuth"));
  const sidelinks = [
    {
      url: "/homepage",
      icon: <HomeIcon />,
      label: "Home",
    },

    {
      url: "/leaderboard",
      icon: <LeaderboardIcon />,
      label: "Leaderboard",
    },

    {
      url: "/logout",
      icon: <LogoutIcon />,
      label: "Logout",
    },
  ];

  return (
    <div style={{ overflow: "hidden" }}>
      <Box disableGutters className={styles.container}>
        <Box className={styles.sidebar} pt={2}>
          {/* Profile */}
          <Box mt={5} px={3}>
            <Profile
              name={userProfile?.displayName}
              avatar={userProfile?.avatar}
            />
          </Box>
          <Box mt={2} px={2}>
            <Divider />
          </Box>
          {/* sidebar */}
          <Sidebar sidelinks={sidelinks} />
        </Box>

        <Box sx={{ flex: "1" }} className={styles.childrenContainer}>
          <Box className={styles.children}>
            <Box
              // position={{ xs: "fixed", md: "relative" }}
              sx={{
                width: "100%",
                top: 0,
                "@media (max-width: 700px)": {
                  backgroundColor: "rgba(255, 255, 255,1)",
                },
              }}
            >
              {/* <TopNav showLogo={true} /> */}
              <Header sidelinks={sidelinks} avatar={userProfile?.avatar} />
            </Box>

            <Box className={styles.childrenContent}>
              <Box className={styles.content}>{children}</Box>
            </Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
}
function Header({ sidelinks, avatar }) {
  return (
    <Box pt={1}>
      {/* header for mobile */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mb={1}
        px={{ xs: 3, md: 5, lg: 9 }}
      >
        <Box width={142}>
          <Image aspectRatio={16 / 9} src={qwizLogo} color="transparent" />
        </Box>

        {avatar && (
          <Box
            width={62}
            sx={{
              "@media (min-width:768px)": {
                display: "none",
              },
            }}
          >
            <Link to="/profile">
              {/* <Image src={avatar} color="transparent" /> */}
              <Avatar
                src={avatar}
                alt="Avatar Image"
                sx={{ bgcolor: "#Fff", height: 48, width: 48 }}
              />
            </Link>
          </Box>
        )}
        <MobileMenu sidelinks={sidelinks} />
      </Box>
      <Box
        height={5}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          // backgroundColor: "white",
          // backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Typography
        variant="h6"
        color="#000"
        align="center"
        fontSize={12}
        sx={{
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        For support or enquiries, please contact:{" "}
        <a
          href="https://wa.me/message/GBEFBYHAWUIRH1"
          target="_blank"
          className={styles.link}
        >
          09157504476
        </a>
      </Typography> */}
      </Box>
    </Box>
  );
}
export function Sidebar({ sidelinks }) {
  return (
    <Box className={styles.sidelinks}>
      {" "}
      <Box>{/* <Typography variant="body1">Hello</Typography> */}</Box>
      <Box mt={4}>
        {sidelinks.map((nav) => (
          <NavLink to={nav.url} style={{ textDecoration: "none" }}>
            {({ isActive, isPending, isTransitioning }) => (
              <Box
                mb={1}
                p={1}
                pl={3}
                className={`${styles.navFlex} ${
                  isActive ? styles.activeNav : styles.idleNavLink
                }`}
              >
                <Box
                  className={` ${
                    isActive ? styles.activeNavLink : styles.idleNavLink
                  }`}
                >
                  {nav.icon}
                </Box>
                <Typography
                  variant="body1"
                  ml={3}
                  fontSize={18}
                  fontWeight={600}
                  className={` ${
                    isActive ? styles.activeNavLink : styles.idleNavLink
                  }`}
                >
                  {nav?.label}
                </Typography>
              </Box>
            )}
          </NavLink>
        ))}
      </Box>
    </Box>
  );
}
export function MobileMenu({ sidelinks }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        "@media (min-width:768px)": {
          display: "none",
        },
      }}
    >
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {!open ? <MenuIcon /> : <CloseIcon />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {sidelinks?.map((link) => (
          <Link key={link.url} to={link.url}>
            <MenuItem onClick={handleClose}>{link.label}</MenuItem>
          </Link>
        ))}
      </Menu>
    </Box>
  );
}
export function Profile({ name, avatar }) {
  return (
    <Link to="/profile">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" fontWeight={900} fontSize={20}>
          {" "}
          {name}
        </Typography>
        <Avatar
          src={avatar}
          alt="Avatar Image"
          sx={{ bgcolor: "#F27405", height: 48, width: 48, padding: 1 }}
        />
      </Box>
    </Link>
  );
}
export default AuthLayout;
