import React from "react";

import Avatar1 from "../../../../../assets/svgs/background/avatar1.svg";
import Avatar2 from "../../../../../assets/svgs/background/avatar2.svg";
import Avatar3 from "../../../../../assets/svgs/background/avatar3.svg";
import CrownIcon from "../../../../../assets/svgs/logos/crown.svg";
import Fade from "react-reveal/Fade";

const CorporateLeaderboard = ({
  bgColor,
  textColor,
  textHead,
  textPara,
  avatarNameColor,
}) => {
  return (
    <div>
      <div className="md:flex justify-between items-center gap-20 px-8 lg:px-0 md:mt-0  mb-20">
        <Fade bottom>
          <div className="md:w-1/2 flex justify-center mb-10 md:mb-0 ">
            <div className="px-8">
              <div
                className={`flex justify-center items-center gap-3 md:gap-7 ${avatarNameColor} `}
              >
                <div className="flex flex-col  items-center gap-y-2">
                  <div>
                    <span className="font-bold">2</span>
                  </div>
                  <div>
                    <img src={Avatar1} alt="avatar-img" />
                  </div>
                  <p className="font-semibold">Adediran</p>
                  <p className="font-bold">800</p>
                </div>
                <div className="flex flex-col items-center gap-y-2">
                  <span className="font-bold">1</span>
                  <div>
                    <img src={CrownIcon} alt="crown-icon" />
                  </div>
                  <div>
                    <img src={Avatar2} alt="avatar-img" />
                  </div>
                  <span className="font-bold">Joseph</span>

                  <p>
                    <span className="font-bold">5000</span>
                  </p>
                </div>
                <div className="flex flex-col items-center gap-y-2">
                  <span className="font-bold">3</span>
                  <div>
                    <img src={Avatar3} alt="avatar-img" />
                  </div>
                  <p className="font-semibold">Adeyemo</p>
                  <p className="font-bold">500</p>
                </div>
              </div>
              <div>
                <div
                  className={`flex ${bgColor}  rounded-xl p-4 gap-x-3 md:gap-x-6 text-base font-bold ${textColor} my-4`}
                >
                  <p>4th</p>
                  <p>Username</p>
                  <p>400</p>
                  <p>09/10/2023</p>
                  <p>03:12:03</p>
                </div>
              </div>
              <div>
                <div
                  className={`flex ${bgColor} rounded-xl p-4 gap-x-3 md:gap-x-6  text-base font-bold ${textColor}`}
                >
                  <p>5th</p>
                  <p>Username</p>
                  <p>350</p>
                  <p>09/10/2023</p>
                  <p>03:12:03</p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom delay={500}>
          <div className="md:w-1/2">
            <h1 className="heading text-center max-w-xl">{textHead}</h1>
            <p className="heading-para text-center ">{textPara}</p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default CorporateLeaderboard;
