import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

import Button from "../../../components/shared/Button";
import CardSection from "./../../competitions/components/CardSection";
import Testimonial from "./../../../components/shared/Testimonial";

import Man1 from "../../..//assets/images/man1.png";
import Card1 from "../../../assets/svgs/background/Group17498.svg";
import Card2 from "../../../assets/images/Group5661.png";
import Card3 from "../../../assets/images/Group5671.png";
import Card5 from "../../../assets/images/Group5701.png";
import Card4 from "../../../assets/svgs/background/Group17499.svg";

const Hero = () => {
  const cardData = [
    {
      image: Card4,
      name: "Lorem ipsum dolor sit amet.",
    },
    {
      image: Card2,
      name: "Card 2",
    },
    {
      image: Card3,
      name: "Card 3",
    },
    {
      image: Card4,
      name: "Card 3",
    },
    {
      image: Card3,
      name: "Card 3",
    },
    {
      image: Card5,
      name: "Card 3",
    },
    {
      image: Card2,
      name: "Card 3",
    },
    {
      image: Card1,
      name: "Card 3",
    },
  ];

  const videoUrl =
    "https://mobigames.s3.amazonaws.com/access-compliance-game/Quiz%20Corporate%20-%20Profile%201%20-%20Microsoft%20Edge%202023-11-17%2012-43-23.mp4";
  return (
    <div className="2xl:container 2xl:mx-auto">
      <div className="md:flex justify-between gap-10  mb-40 px-8 md:px-0 mt-10 lg:mt-0 ">
        <Fade left>
          <div className="md:w-1/2 flex justify-center items-center">
            <div className="px-4 lg:px-6 mb-10 lg:mb-0 ">
              <h1 className="heading   lg:pb-5 max-w-md">
                Boost Event Leads with Our Interactive Quiz Platform for
                Guaranteed Success
              </h1>
              <p className="pb-6 heading-para">
                Elevate Your Event ROI with engaging Interactive Quizzes
              </p>
              <Button
                type="button"
                hoverBgColor="primaryRed-dark"
                disabled={false}
                borderRadius="rounded-xl"
                fontSize="text-base"
                textColor="text-white"
                bgColor="bg-lightGreen"
                fontWeight="font-medium"
              >
                Sign up now
              </Button>
            </div>
          </div>
        </Fade>
        <Fade right delay={1000}>
          <div className="md:w-1/2  flex">
            <div className="relative event-2 w-60 h-fit p-4 lg:p-0 lg:w-full  lg:h-full  flex justify-center">
              <img
                src={Man1}
                alt="laugh-man"
                width=""
                className="w-screen pt-16"
              />
            </div>
          </div>
        </Fade>
      </div>

      <div className="mb-10 px-8">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 ">
          <Fade bottom>
            <div className="flex justify-center items-center mb-20 md:mb-0">
              <div className="">
                <h1 className="heading text-black">How it works</h1>
                <p className="heading-para mb-4">
                  1. Engaging content that aligns with your training objective
                  and keeps your team engaged.
                </p>
                <p className="heading-para mb-4">
                  2. Invite team members to participate in quizzes, boosting
                  team spirit.
                </p>
                <p className="heading-para">
                  3. Monitor and understand your team’s progress effortlessly
                  with our intuitive real-time tracking.
                </p>
                <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start  gap-x-10 mt-14">
                  <Link to="/register">
                    <Button
                      type="button"
                      hoverBgColor="primaryRed-dark"
                      disabled={false}
                      borderRadius="rounded-xl"
                      fontSize="text-base"
                      textColor="text-white"
                      bgColor="bg-lightGreen"
                      fontWeight="font-medium"
                    >
                      Sign up for a free trial now
                    </Button>
                  </Link>
                  <Link
                    to="/homepage"
                    className="mt-6 md:mt-0 flex justify-center"
                  >
                    <Button
                      type="button"
                      hoverBgColor="primaryRed-dark"
                      disabled={false}
                      borderRadius="rounded-xl"
                      fontSize="text-base"
                      textColor="text-white"
                      bgColor="bg-lightGreen"
                      fontWeight="font-medium"
                    >
                      Play now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="flex justify-center items-center">
              <div className="flex items-start justify-center h-fit md:h-56 lg:h-80 w-fit lg:w-full rounded-lg">
                <iframe
                  width="100%"
                  height="100%"
                  src={videoUrl}
                  title="Embedded Video"
                  frameBorder="0"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      {/* section */}
      <div className="mb-10">
        <div>
          <div className="px-8 mt-20 md:mt-32 lg:mt-40">
            <div className="flex justify-center text-center">
              <div className="">
                <h1 className="heading text-black mb-2">Leaderboard</h1>
                <p className="pb-6 heading-para">
                  Our quiz platform includes features such as:
                </p>
              </div>
            </div>
            <CardSection data={cardData} />
          </div>

          <div>
            <div className="bg-primaryGreen flex justify-center items-center gap-x-20 mt-20 px-8 lg:px-0 mb-20 lg:mb-0  ">
              <div className="md:w-1/3 hidden md:block">
                <div className=" relative   event-1  w-full h-[60vh]"></div>
              </div>
              <div className="w-full md:w-2/3 py-10 lg:py-0">
                <div className="md:flex justify-center items-center text-white">
                  <div>
                    <div className="mb-6 lg:mb-0">
                      <h1 className="heading text-white  text-center">
                        Our customers love to use Quizi, <br /> So why not you ?
                      </h1>
                    </div>
                    <div className="heading-para text-center pb-6">
                      <p>
                        We helped Acme Corporation generate over 10,000 leads at
                        their last event. They were so impressed with our
                        platform that they signed up for a year-long
                        subscription
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <Button
                        type="button"
                        hoverBgColor="primaryRed-dark"
                        disabled={false}
                        borderRadius="rounded-xl"
                        fontSize="text-base"
                        textColor="text-white"
                        bgColor="bg-deepGreen"
                        fontWeight="font-medium"
                      >
                        Explore more case studies
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* section */}
      <Testimonial />
      {/* end */}

      {/* section */}
      <div className="flex justify-center my-10">
        <div>
          <h1 className="heading text-black max-w-[40rem] text-center">
            Win lots of amazing prices on Qwizfun
          </h1>
          <div className="h-40 w-full rounded-[1.7rem] bg-primaryGreen"></div>
        </div>
      </div>
      {/* end of  */}
    </div>
  );
};

export default Hero;
