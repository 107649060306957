"use client";
import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { useNavigate, Link } from "react-router-dom";
// import Link from "next/link";
// import { useRouter } from "next/navigation";
import HowToPlay from "./HowToPlay";
import Image from "material-ui-image";
import { Paper, Button, Typography, Box, Stack, Skeleton } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import HomeIcon from "@mui/icons-material/Home";
import { howToPlay } from "../../utils/data/data";
import giftImage from "../../assets/images/gift-box.png";
import giftImage2 from "../../assets/images/gift-2.png";
import boyImage from "../../assets/images/start-game-image.png";
import { useFetchAllQuiz } from "../../hooks/api/fetch";
import NoSubscription from "./NoSubscription";
import Winner from "./Winner";
import { useAcknowledgeReward } from "../../hooks/api/post";
import AuthLayout from "../../components/layout/AuthLayout";
function Homepage() {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("ccAuth"))
  );
  const allQuizApi = useFetchAllQuiz({
    user_id: userInfo?.id,
    event_code: "091098",
  });
  // const rewardsAPI = useFetchRewards(userInfo?.id);
  const acknowledgeRewardAPI = useAcknowledgeReward();
  // const subscriptionState = useCheckSubscribeStatus(userInfo?.userPassport);
  const [activeQuiz, setActiveQuiz] = useState(true);

  // useEffect(() => {
  //   if (subscriptionState.data?.message !== "Your Subscription has expired") {
  //     setActiveQuiz(true);
  //   } else {
  //     setActiveQuiz(false);
  //   }
  //   return () => setActiveQuiz((active) => !active);
  // }, [subscriptionState.data]);

  const [openHowToPlayModal, setOpenHowToPlayModal] = useState(
    localStorage.getItem("hideHelp") === "true" ? false : true
  );

  const [openNoSubModal, setOpenNoSubModal] = useState(false);
  const [quizInfo, setQuizInfo] = useState();
  const [gamePlayModal, setGamePlayModal] = useState(false);
  function handleGameCardClick(id, quiz) {
    if (!activeQuiz) {
      setOpenNoSubModal(true);
    } else {
      // navigate(`/qwiz/gameplay?id=${id}`);
      if (
        typeof window !== "undefined" &&
        window.localStorage.getItem("hideHelp") !== "true"
      ) {
        setOpenHowToPlayModal(true);
      }
      setQuizInfo((quizes) => ({
        title: quiz?.quiz_title,
        id: quiz?.id,
        summary: quiz?.quiz_description,
        time: quiz?.quiz_duration,
        questionCount: quiz?.quiz_question_number,
      }));
      setGamePlayModal(true);
    }
  }

  function handleCloseHowToPlayModal() {
    setOpenHowToPlayModal(false);
  }
  function handleCloseNoSubModal() {
    setOpenNoSubModal(false);
    typeof window !== "undefined" &&
      window.localStorage.setItem("newbie", "no");
  }

  const [openWinningModal, setOpenWinningModal] = useState(false);
  // useEffect(() => {
  //   const winnings = rewardsAPI?.data?.winning;
  //   if (winnings?.length > 0 && winnings?.[0]?.isSeen === 0) {
  //     setOpenWinningModal(true);
  //   }
  // }, [rewardsAPI?.data?.winning]);
  function handleCloseWinningModal() {
    acknowledgeRewardAPI.mutate({ user_id: userInfo.id });
    setOpenWinningModal((modal) => !modal);
  }
  return (
    <AuthLayout>
      <Box
        sx={{
          padding: "15px",
          minHeight: "calc(100vh - 220px)",
          backgroundColor: "#F8F8F8",
          marginTop: "40px",
          borderTopLeftRadius: "40px",
          borderTopRightRadius: "40px",
        }}
      >
        {/* <Box>
        <Typography
          variant="h4"
          fontWeight={900}
          fontSize={16}
          mb={2}
          onClick={() => setActiveQuiz((act) => !act)}
        >
          {" "}
          Previous Quiz
        </Typography>
        {allQuizApi?.isLoading && <SkeletonCard />}
        {allQuizApi?.data?.quizzes?.map((quiz) => (
          <GameCard
            key={quiz.id}
            id={quiz.id}
            title={quiz?.quiz_title}
            summary={quiz?.quiz_description}
            icon={giftImage}
            time={quiz?.quiz_duration}
            onClick={() => handleGameCardClick(quiz?.id, quiz)}
            status={activeQuiz}
            questionCount={quiz?.quiz_question_number}
          />
        ))}
        {(allQuizApi?.data?.data?.length < 1 || allQuizApi.isError) && (
          <Typography variant="body2">No Games Available</Typography>
          // <GameCard
          //   title="Spelling Buzz QUiz"
          //   summary="Test your spelling skills in our Spelling Bee Quiz. Compete to become the ultimate word champion!"
          //   icon={giftImage}
          //   time="3"
          //   onClick={() => navigate("/qwiz/gameplay")}
          // />
        )}

        </Box> */}
        <Typography
          variant="h4"
          fontWeight={900}
          fontSize={16}
          my={2}
          // onClick={() => setActiveQuiz((act) => !act)}
        >
          {" "}
          Current Quiz
        </Typography>
        {allQuizApi?.isLoading && <SkeletonCard />}
        {allQuizApi?.data?.data?.map((quiz) => (
          <Box key={quiz.id} mb={2} data-test-id="game-card">
            <GameCard
              key={quiz.id}
              id={quiz.id}
              title={quiz?.quiz_title}
              summary={quiz?.quiz_description}
              icon={quiz.avatar || giftImage}
              time={quiz?.quiz_duration}
              onClick={() => handleGameCardClick(quiz?.id, quiz)}
              status={activeQuiz}
              questionCount={quiz?.quiz_question_number}
            />
          </Box>
        ))}
        {(allQuizApi?.data?.quizzes?.length < 1 || allQuizApi.isError) && (
          // <Typography variant="body2">No Games Available</Typography>
          <GameCard
            title="Spelling Buzz QUiz"
            summary="Test your spelling skills in our Spelling Bee Quiz. Compete to become the ultimate word champion!"
            icon={giftImage}
            time="3"
            onClick={() => navigate("/qwiz/gameplay")}
          />
        )}
        <NoSubscription
          title="Your Account has been Created"
          open={openNoSubModal}
          handleClose={handleCloseNoSubModal}
        />

        <GamePlayModal
          open={gamePlayModal}
          handleClose={() => setGamePlayModal(false)}
          key={quizInfo?.id}
          id={quizInfo?.id}
          user_id={userInfo?.id}
          title={quizInfo?.title}
          summary={quizInfo?.summary}
          time={quizInfo?.time}
          questionCount={quizInfo?.questionCount}
        />
        <HowToPlay
          options={howToPlay}
          open={openHowToPlayModal}
          handleClose={handleCloseHowToPlayModal}
        />
        <Winner
          open={openWinningModal}
          title="Congratulations 🎉"
          content="You have won ₦5, 000 and payment will made to account ending 5738 within 24 hours"
          image={giftImage2}
          buttonText="Back to Home"
          buttonColor="error"
          buttonVariant="contained"
          buttonIcon={<HomeIcon />}
          buttonFunction={handleCloseWinningModal}
          handleClose={handleCloseWinningModal}
        />
      </Box>
    </AuthLayout>
  );
}

export default Homepage;
export function GameCard({
  key,
  title,
  icon,
  summary,
  time,
  status,
  onClick,
  questionCount,
}) {
  return (
    <Paper
      key={key}
      sx={{
        position: "relative",
        borderTopRightRadius: "15px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          background: status ? "#61A641" : "#F24444",
          borderTopRightRadius: "15px",
        }}
      >
        <Typography
          variant="body2"
          fontSize={14}
          sx={{ color: "white", padding: "11px" }}
        >
          {status ? "Unlocked" : "Locked"}
        </Typography>
      </Box>
      {!status && (
        <Box
          sx={{
            position: "absolute",
            top: "60px",
            right: "20px",
          }}
        >
          <LockIcon color="error" />
        </Box>
      )}
      <Stack direction="row" alignItems={"center"} gap={2} p={1}>
        <Box height={72} width={72}>
          <Image src={icon} color="transparent" />
        </Box>
        <Box sx={{ maxWidth: "202px" }}>
          <Typography variant="h6" fontSize={14} fontWeight={600} mt={1} mb={1}>
            {title}
          </Typography>
          <Typography variant="body1" fontSize={10} fontWeight={300} mb={1}>
            {summary}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AccessTimeIcon />
            <Typography variant="body1" fontSize={10} ml={1}>
              {time} mins
            </Typography>
            <Typography variant="body1" fontSize={10} ml={1}>
              / {questionCount} questions
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
}
function SkeletonCard() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  );
}



function GamePlayModal({
  open,
  handleClose,
  title,
  id,
  user_id,
  summary,
  time,
  questionCount,
}) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box p={2}>
        <Typography
          mt={1}
          variant="h3"
          fontSize={20}
          fontWeight={900}
          align="center"
        >
          {title}
        </Typography>
        <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Box width={100}>
            <Image
              aspectRatio={9 / 16}
              src={boyImage}
              color="transparent"
              alt="boy with opened arm"
            />
          </Box>
        </Box>
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={900}
          align="center"
        >
          {summary}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccessTimeIcon />
          <Typography
            ml={1}
            variant="body2"
            fontSize={12}
            fontWeight={900}
            align="center"
          >
            {time}mins
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            fontSize={12}
            fontWeight={900}
            align="center"
          >
            / {questionCount} questions
          </Typography>
        </Box>
        <Box
          my={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link to={`/gameplay?id=${id}&user_id=${user_id}`}>
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: "rgb(113 51 186 ) " }}
              data-test-id="play"
            >
              Play
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>
  );
}

