import { useEffect, useState } from "react";
import JourneyCard from "./JourneyCard";
import Joyride, { STATUS } from "react-joyride";
import { useUpdateUserProfile } from "../../../hooks/api/post";

export default function Tour({ currentStep, setCurrentStep, setOpen, auth }) {
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(true);

  const handleNextButtonClick = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    }
  };
  const updateProfile = useUpdateUserProfile();
  const handleSkip = async () => {
    setRun(false);
    setStepIndex(0);
    setOpen(false);
    setCurrentStep(0);

    try {
      updateProfile.mutate({
        userId: auth?.id,
        data: {
          first_login: false,
        },
      });
      auth.first_login = false;
      let updatedData = JSON.stringify(auth);
      sessionStorage.setItem("ccAuth", updatedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleJoyRideCallBack = (data) => {
    const { index, action } = data;
    if (index > 1) {
      setOpen(true);
    }

    if (action === STATUS.FINISHED || action === STATUS.SKIPPED) {
      setRun(false);
      setStepIndex(0);
      setCurrentStep(0);
    } else if (action === STATUS.NEXT) {
      handleNextButtonClick();
    }
    setCurrentStep(index);
  };

  const steps = [
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Welcome to qwiz!"
          description="Ready to dive in? Here's a sneak peek, let's get you started with a quick tour."
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Your Notification"
          description="Check for announcements, daily challenges, bonuses, or rewards."
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#notification",
    },
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Your Navigation"
          description="Click on this icon to navigate through the entire qwizfun application!"
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: "#hamburger ",
    },
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Your Profile"
          description="Update your information, invite your friends and earn points."
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "left",
      target: ".navigation",
    },
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Explore Games"
          description="Explore a variety of quizzes and games to challenge yourself."
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "left",
      target: ".navigation",
    },
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Leaderboard"
          description="See where you stand among fellow competitors and strive for the top!"
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "left",
      target: ".navigation",
    },
    {
      content: (
        <JourneyCard
          onGetStarted={handleSkip}
          onSkip={handleSkip}
          title="Activities"
          description="Track streaks, view recent activities, and celebrate achievements and rewards earned on your journey!"
          currentStep={currentStep}
          onNext={handleNextButtonClick}
        />
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "left",
      target: ".navigation",
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    console.log(windowWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  return (
    <>
      <Joyride
        stepIndex={stepIndex}
        callback={handleJoyRideCallBack}
        width="10px"
        styles={{
          floater: {
            width: stepIndex > 2 ? (windowWidth < 500 ? "55vw" : "30vw") : windowWidth < 500 ? "80vw" : "40vw",
          },
          tooltip: {
            width: stepIndex > 2 ? (windowWidth < 500 ? "55vw" : "30vw") : windowWidth < 500 ? "80vw" : "40vw",
          },
        }}
        continuous
        run={auth?.first_login ? run : false}
        steps={steps}
        hideCloseButton
        hideLastButton
        disableScrolling={true}
        disableScrollParentFix={true}
        hideBackButton={true}
      />
    </>
  );
}
