import { Box, Button, Card, Container, Typography } from "@mui/material";
import HeroBg from "../../../assets/Images2/hero_background.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    heroBg: { backgroundImage: `url("${HeroBg}")`, backgroundSize: "cover", backgroundRepeat: "no-repeat", minHeight: "10vh", borderRadius: "1rem" },
    card: { height: "auto", borderRadius: "1rem", border: "2px solid #CAE8F8" },
    body2: { fontFamily: "'Poppins', sans-serif", fontWeight: 400, color: "black" },
    h6: { fontFamily: "'Poppins', sans-serif", fontWeight: 600, color: "white", textTransform: "capitalize" },
  };
});
function HeroSection({ name, isFirstLogin }) {
  const classes = useStyles();
  return (
    <Box>
      <Container maxWidth="xl">
        <Box className={`${classes.heroBg} flex items-center mt-10 px-2`}>
          <Typography variant="h6" className={classes.h6}>
            Welcome, {name}
          </Typography>
        </Box>
        {isFirstLogin && (
          <Box className="my-7">
            <Card elevation={0} className={`${classes.card} p-3`}>
              <Typography sx={{ fontWeight: "600 !important" }} className={classes.body2} variant="body2">
                {" "}
                Start your learning adventure with Qwiz!{" "}
              </Typography>
              <Typography className={classes.body2} variant="body2">
                Explore a world of awesome quizzes, mind-blowing challenges, and endless opportunities to test your knowledge. Buckle up and get ready to level up your brainpower.{" "}
              </Typography>
              <Typography className={classes.body2} sx={{ marginTop: "1rem", fontWeight: "600 !important" }} variant="body2">
                The fun starts now!
              </Typography>
            </Card>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default HeroSection;
