import React from 'react';
import { AiFillLinkedin } from "react-icons/ai";
import { BiLogoFacebook, BiLogoTwitter, BiLogoInstagram } from "react-icons/bi";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizIcon from '@mui/icons-material/Quiz';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DashboardIcon from '@mui/icons-material/Dashboard';



const paragraphs = [
  {
    id: 1,
    content:
      " Register with your name, email, phone, age, and country.",
  },
  {
    id: 2,
    content:
      " Login with your phone number used during registration and select game to start playing.",
  },
  {
    id: 3,
    content:
      "Play as many times as possible to top the leaderboard to win cash prizes ",
  },
];


const socialLinks = [
  {
    url: "https://www.instagram.com/9ijakids/",
    icon: <BiLogoInstagram className="cursor-pointer" />,
  },
  {
    url: "https://twitter.com/9ijakids?t=4YP8vgUSVJzw7KhpS9e_0Q&s=08",
    icon: <BiLogoTwitter className="cursor-pointer" />,
  },
  {
    url: "https://www.facebook.com/9ijakids",
    icon: <BiLogoFacebook className="cursor-pointer" />,
  },
  { url: "https://t.me/+B3jIp1KP4qE0MDk8", icon: <AiFillLinkedin /> },
];
const faqData = [
  {
    question: "How can I register for the Bible Challenge?",
    answer: `To register, click on the link  <a href="https://bit.ly/biblechallenge2023" target="_blank" style="font-weight: bold; color:red "> <u>https://bit.ly/biblechallenge2023 </u></a> This will direct you to the website. Fill in your name, email, phone number, age, and country. After registration, a welcome email will guide you to log in using your phone number.`,
    id: "1"
  },
  {
    question: "When does the Bible Challenge start and end?",
    answer: "The Bible Challenge spans two weeks and runs from Monday, December 18th, 2023, to Sunday, December 31st, 2023",
    id:"2"
  },
  {
    question: "What age group is the Bible Challenge designed for?",
    answer: "The challenge is tailored for participants aged 10 to 21 years, divided into three (3) age groups. 10-12 years, 13-18 years, 18 -21 years.",
    id:"3"
  },
  {
    question: "Can I participate if I'm outside the specified age range?",
    answer: "Unfortunately, the challenge is exclusively for individuals aged 10 to 21.",
    id:"4"
  },
  {
    question: " Is the Bible Challenge only for a specific country?",
    answer: "Yes. The challenge is open to participants in Nigeria, Ghana, Kenya and Zambia.",
    id:"5"
  },
  {
    question: "Is there a registration fee for the Bible Challenge?",
    answer: "No, registration for the Bible Challenge is completely FREE.",
    id:"6"
  },
  {
    question: "Can I still participate if I missed the start date of the challenge??",
    answer: "Yes, participants can join the challenge at any time even if the challenge has started. However, starting the challenge from the beginning gives you a higher chance of becoming a winner.",
    id:"7"
  },
  {
    question: "Can I change my registered information after signing up?",
    answer: "No, you cannot edit your profile details once you have registered",
    id:"8"
  },
  {
    question: "What devices can I use to play the Bible Challenge games?",
    answer: "The games are responsive on laptop, tablet, Android (phone/tablet), iPhone and iPad",
    id:"9"
  },
  {
    question: "Is there a registration fee for the Bible Challenge?",
    answer: "No, registration for the Bible Challenge is completely FREE.",
    id:"10"
  },
    {
      question: "I have not registered before, but it is telling me I already have an account. What should I do?",
      answer: "If you encounter this issue, this may be that you have been registered before now. Please click on login and enter your phone number. If the problem persists, contact support for further assistance by using the live chat within the platform or send a WhatsApp message to +2349157504476.",
      id: "11"
    },
    {
      question: "I am trying to register, but it says, \"invalid credentials.\" What should I do?",
      answer: "Ensure that the information you are providing during registration, such as your email and phone number, is accurate. If the issue persists, double-check for typos, or contact support for assistance by using the live chat within the platform or send a WhatsApp message to +2349157504476.",
      id: "12"
    },
    {
      question: "I am trying to login, but it says, \"invalid credentials.\" What should I do?",
      answer: "Ensure that the phone number you are providing was the same number you used during registration. If the issue persists, contact support for assistance by using the live chat within the platform or send a WhatsApp message to +2349157504476.",
      id: "13"
    },
    {
      question: "I tried reaching out to Contact Support, but no one is answering me. Please help me.",
      answer: "Contact Support operates from Monday to Friday, from 9 am to 7 pm WAT, and typically respond within 5 minutes during these hours. Additionally, support is available on weekends from 10 am to 5 pm WAT, with a typical response time of around 30 minutes. We appreciate your patience and assure you that we are dedicated to addressing your inquiries promptly.",
      id: "14"
    },
    {
      question: "How many games are there in the Bible Challenge?",
      answer: "The challenge features two games. In the first week, the game focuses on the birth of Jesus, while the second week’s game centres around the miracles and life of Jesus.",
      id: "15"
    },
    {
      question: "How long will each game last?",
      answer: "The game opens at the beginning of each week by 9 am on Monday and concludes at the end of the week by 12 midnight on Sunday. Participants can engage at any time during this period.",
      id: "16"
    },
    {
      question: "Is there a limit to the number of times I can attempt the games?",
      answer: "No. There are no limits to the number of times you can attempt the game. Participants can play the game an unlimited number of times throughout the duration of the Bible Challenge.",
      id: "17"
    },
    {
      question: "How do I know my score?",
      answer: "Your score is displayed in the game and on your user dashboard after every attempt made.",
      id: "18"
    },
    {
      question: "Can I see my previous scores and attempts?",
      answer: "Yes, when you login using your phone number, your dashboard displays your highest scores and the number of attempts for each game. There's also a leaderboard for each age group and country.",
      id: "19"
    },
    {
      question: "Why is my score not increasing like others?",
      answer: "Scores vary based on correct answers and completion time. Review the questions and aim for accuracy and speed to improve your score.",
      id: "20"
    },
    {
      question: "How do I invite friends to join the Bible Challenge?",
      answer: "Click on the \"Invite Your Friends\" option, get your unique invite link, and share it with friends.",
      id: "21"
    },
    {
      question: "Are there any educational resources provided during the challenge as a guide?",
      answer: "Yes. A study plan is provided each week of the challenge acting as a guide and focus area and providing an educational and fun experience.",
      id: "22"
    },
    {
      question: "Are there prizes for participants?",
      answer: "Yes, there are exciting cash prizes to be won!",
      id: "23"
    },
    {
      question: "How are winners determined in the Bible Challenge?",
      answer: "Winners are determined based on scores and achievements, with cash prizes awarded. There are weekly winners for each age group and overall country winners at the end of the challenge.",
      id: "24"
    },
    {
      question: "How will I be notified if I win a prize in the Bible Challenge?",
      answer: "Winners will be contacted directly via email or phone number and will be announced on the challenge platform and on social media.",
      id: "25"
    },
];
const rulesData = [
  {
    heading: "Eligibility",
    rule: "Open to participants aged 10 to 21 years. If you're under 13, make sure to have your parent's consent.",
    id: "1"
  },
  {
    heading: "Challenge Period",
    rule: "Join us from Monday, December 18th, until Sunday, December 31st, 2023.",
    id: "2"
  },
  {
    heading: "Weekly Games",
    rule: "Get ready for a new game each week! Stay tuned for exciting challenges.",
    id: "3"
  },
  {
    heading: "Honest Participation",
    rule: "We trust you to complete the games on your own, without help from others.",
    id: "4"
  },
  {
    heading: "Scoring",
    rule: "Earn points for how accurate and quick you are in each game.",
    id: "5"
  },
  {
    heading: "Weekly Winners",
    rule: "Check out who tops the leaderboard! Winners are announced at 6 PM every Monday.",
    id: "6"
  },
  {
    heading: "Leaderboard Ranking",
    rule: "Your overall score on the leaderboard determines if you're a winner.",
    id: "7"
  },
  {
    heading: "Prior Winners",
    rule: "If you've won in previous editions of our Bible Challenge, you're not eligible to win again. Let's give everyone a fair chance!",
    id: "8"
  },
  {
    heading: "Family Restrictions",
    rule: "Relatives of 9ijakids and SIATO Foundation staff can join the fun but aren't eligible for prizes.",
    id: "9"
  },
  {
    heading: "Valid Contact Info",
    rule: "Make sure your contact details are correct and up to date for prize eligibility.",
    id: "10"
  },
  {
    heading: "Prize Payment",
    rule: "Won a cash prize? We'll deposit it ONLY into an Access Bank account matching your registration details.",
    id: "11"
  },
  {
    heading: "Prize Rules",
    rule: "Remember, prizes can't be transferred to someone else or swapped for other items.",
    id: "12"
  },
  {
    heading: "Fair Play",
    rule: "We reserve the right to disqualify anyone who breaks the rules or plays unfairly.",
    id: "13"
  },
  {
    heading: "Stay Informed",
    rule: `Keep an eye on your email and our WhatsApp group 
    <a href=" https://chat.whatsapp.com/JuqVCo8gxDGAJcpdctz0Ub" target="_blank" style="font-weight: bold; color:red"><u>https://chat.whatsapp.com/JuqVCo8gxDGAJ </u></a>
     and Instagram <a href=" https://www.instagram.com/9ijakids/" target="_blank" style="font-weight: bold; color:red"><u>https://www.instagram.com/9ijakids/</u></a> for any updates or changes to the rules.`,
    id: "14"
  },
 
];
// const achievementsData = [
//   {
//     title: "Curent Quest",
//     value: "Spelling Bee",
//     icon: <QuizIcon/>,
    
//   },
//   {
//     title: "Highest Scores",
//     value: 1200,
//     icon:<ScoreboardIcon/>,
//     info:"Players higest score"
//   },
//   {
//     title: "Rank",
//     value: 3,
//     icon:<EmojiEventsIcon/>,
//     info:"Players rank"

//   },
//   // {
//   //   title: "Badges",
//   //   value: 2,
//   //   icon: <MilitaryTechIcon />,
//   // },
//    {
//     title: "Attempts",
//     value: 3,
//     icon: <ReplayCircleFilledIcon />,
//     info:"Players number of attempt "
//   },
//   {
//     title: "Invited Friends",
//     value: 2,
//     icon: < Diversity3Icon />,
//     info:"Player total number of invitec friends"
//   },
 
//   {
//     title: "Time Spent",
//     value: 3,
//     icon: <AccessTimeIcon />,
//     info:'Time spent on game'
//   },
// ];
const sidelinks = [
  // {
  //   url: "/events/biblechallenge/christmas/",
  //   icon: <HomeIcon />,
  //   label: "Home",
  // },
  {
    url: "/events/biblechallenge/christmas/dashboard",
    icon: <DashboardIcon />,
    label: "Dashboard",
  },

  {
    url: "/events/biblechallenge/christmas/leaderboard",
    icon: <LeaderboardIcon />,
    label: "Leaderboard",
  },

  {
    url: "/events/biblechallenge/christmas/login",
    icon: <LogoutIcon />,
    label: "Logout",
  },
];
const leaderboardByGrpData = [
  {
    country: 'Ghana',
    players: [
      { name: 'sam', score: 100, updatedDate: '2023-01-01' },
      { name: 'femi', score: 85, updatedDate: '2023-01-02' },
      { name: 'dave', score: 85, updatedDate: '2023-01-02' },
      // Add more players as needed
    ],
  },
  {
    country: 'Nigeria',
    players: [
      { name: 'Gbolahan', score: 95, updatedDate: '2023-01-03' },
      { name: 'Lilian ', score: 78, updatedDate: '2023-01-04' },
      { name: 'Rayo', score: 95, updatedDate: '2023-01-03' },
      { name: 'Emmanuel', score: 78, updatedDate: '2023-01-04' },
    
    ],
  },
  {
    country: 'Kenya',
    players: [
      { name: 'Muhedeen', score: 88, updatedDate: '2023-01-05' },
      { name: 'uche', score: 92, updatedDate: '2023-01-06' },
      { name: 'Tobi', score: 95, updatedDate: '2023-01-03' },
      { name: 'Dayo', score: 78, updatedDate: '2023-01-04' },
  
    ],
  },
  {
    country: 'Zambia',
    players: [
      { name: 'Titi', score: 87, updatedDate: '2023-01-07' },
      { name: 'Bolu', score: 94, updatedDate: '2023-01-08' },
      { name: 'Lanre', score: 95, updatedDate: '2023-01-03' },
      { name: 'Eniola', score: 78, updatedDate: '2023-01-04' },
   
    ],
  },
  // Add more countries as needed
];

export {socialLinks, paragraphs, faqData, rulesData, sidelinks,leaderboardByGrpData}