import React from "react";
import { Box, Typography } from "@mui/material";
import Image from "material-ui-image";
import QuizLogo from "../../../assets/Images2/qwizfun_logo.svg";
function Header({ title }) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", p: 2, bgcolor: "#F5F9F9" }}
    >
      <Box sx={{ flex: "0.2" }}>
        <Box height={40} width={28}>
          <Image src={QuizLogo} color="transparent" aspectRatio={28 / 40} />
        </Box>
      </Box>
      <Box sx={{ flex: "0.7" }}>
        <Typography
          variant="h4"
          align="center"
          fontSize={16}
          fontWeight={700}
          color={"#333"}
          sx={{ width: "max-contenr" }}
        >
          {" "}
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default Header;
