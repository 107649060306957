import { Box, Card, Container, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ColorfulBg from "../../../assets/Images2/colorful_bg.svg";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import NoDataInfo from "../../../components/NoData";

const useStyles = makeStyles(() => {
  return {
    card: {
      height: "15rem",
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #CAE8F8",
      borderRadius: "1rem",
      position: "relative",
    },
  };
});
function OnGoingGamesAndQuizzes({ data, title, type }) {
  const navigate = useNavigate();
  const goToQuizPage = (id) => {
    navigate(`../quizplay?quiz_id=${id}`, { relative: "path" });
  };
  const goToGamePage = (id) => {
    navigate(`../gameplay?id=${id}`, { relative: "path" });
  };
  const classes = useStyles();
  return (
    <Box className="my-4">
      <Container>
        <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
          {title}
        </Typography>
      </Container>

      <Box className="my-5 pl-5">
        {data.length < 1 ? (
          <NoDataInfo />
        ) : (
          <Splide
            options={{
              type: "loop",
              rewind: true,
              autoplay: false,
              perMove: 1,
              perPage: 2,
              gap: 8,
              arrows: true,
              width: "100%",
              pagination: false,
            }}
          >
            {data?.map((each) => {
              return (
                <SplideSlide key={each?.id}>
                  <Card
                    elevation={0}
                    className={classes.card}
                    onClick={dayjs(each?.quiz_end_date).isAfter(dayjs()) ? (type === "quiz" || each?.quizType === "quiz" ? () => goToQuizPage(each.id) : () => goToGamePage(each.id)) : null}
                  >
                    {!dayjs(each?.quiz_end_date).isAfter(dayjs()) && <Box style={{ position: "absolute", backgroundColor: "rgba(255,255,255,0.4)", height: "100%", width: "100%", zIndex: 2 }}></Box>}
                    <Box
                      style={{
                        backgroundImage: each?.avatar ? `url("${each?.avatar}")` : `url("${ColorfulBg}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "50%",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#CAE8F8",
                          fontSize: "0.8rem",
                          padding: "0.2rem 0.7rem",
                          borderTopLeftRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          width: "fit-content",
                          fontFamily: "poppins",
                          position: "absolute",
                          bottom: 10,
                          right: 0,
                        }}
                      >
                        {dayjs(each?.quiz_end_date).isAfter(dayjs()) ? `Ending: ${dayjs(each?.quiz_end_date).format("DD/MM/YYYY")}` : "CLOSED"}
                      </Box>
                    </Box>
                    <Box className="p-1">
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: 600,
                        }}
                      >
                        {each?.quiz_title}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: 400,
                          color: "black",
                          fontSize: "12px",
                        }}
                        variant="body2"
                      >
                        {each?.quiz_description}
                      </Typography>
                    </Box>
                  </Card>
                </SplideSlide>
              );
            })}
          </Splide>
        )}
      </Box>
      {/* </Grid> */}
    </Box>
  );
}

export default OnGoingGamesAndQuizzes;
