import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ModalTemplate from "../../components/modal/Modal";
import RegisterForm from "./RegisterForm/RegisterForm";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import infoIcon from "../../assets/images/info-circle.png";
function Register() {
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    console.log({ openErrorModal });
  }, [openErrorModal]);
  return (
    <Layout>
      <Box
        mt={{
          xs: 2,
          md: 2,
        }}
        // p={2}
        sx={{
          minHeight: "calc(100vh - 200px)",
        }}
      >
        <Typography variant="h6" my={2} align="center">
          {" "}
          Create An Account
        </Typography>
        <RegisterForm
          setErrorMessage={setErrorMessage}
          setOpenErrorModal={setOpenErrorModal}
        />
        <RegisterError
          openModal={openErrorModal}
          setOpenModal={setOpenErrorModal}
          message={errorMessage}
        />
      </Box>
    </Layout>
  );
}

export default Register;
function RegisterError({ openModal, setOpenModal, message }) {
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <ModalTemplate open={openModal} handleClose={handleCloseModal}>
      <Box mt={1} py={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "48px", height: "48px" }}>
          <Image src={infoIcon} color="transparent" />
        </Box>
      </Box>
      <Typography variant="body1" align="center">
        {message}
      </Typography>
      <Box py={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="success" onClick={handleCloseModal}>
          Close
        </Button>
      </Box>
    </ModalTemplate>
  );
}
