import { Box, Card, Container, Typography } from '@mui/material';
import React from 'react';
import profileBG from '../../events2.0/assets/svgs/event2/profile-bg.png';
import Header from './Header';

function PageTitle({ title }) {
  return (
    <Box>
      <Container className='my-4'>
        <Box style={{ backgroundImage: `url("${profileBG}")`, height: '10vh', width: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '1rem', padding: '0.7rem' }}>
          <Card style={{ height: '100%', borderRadius: '1rem' }} className='flex items-center justify-center'>
            <Typography variant='h5' style={{ fontFamily: 'Poppins', fontWeight: 600, height: 'fit-content' }}>
              {title}
            </Typography>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default PageTitle;
