import React from "react";
import Footer from "../../components/shared/Footer";
import Navbar from "../../components/shared/CustomNavbar";
import menuData from "../../utils/data/menu.json";
import Hero from "./components/Hero";
import CorporateWorks from "./components/CorporateWorks";
import CorporateTestimonial from "./components/CorporateTestimonial";
import Logo from "../../assets/svgs/logos/logo-white.svg";
import LogoBlack from "../../assets/svgs/logos/logo-black.svg";
import { BiLogoFacebook, BiLogoTwitter, BiLogoInstagram } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";
import footerData from "../../utils/data/footer.json";
import Guy3 from "../../assets/images/guy3.png";

const socialLinks = [
  {
    url: "https://www.instagram.com/9ijakids",
    icon: <BiLogoInstagram className="cursor-pointer" />,
  },
  {
    url: "https://twitter.com/9ijakids?t=4YP8vgUSVJzw7KhpS9e_0Q&s=08",
    icon: <BiLogoTwitter className="cursor-pointer" />,
  },
  {
    url: "https://www.facebook.com/9ijakids",
    icon: <BiLogoFacebook className="cursor-pointer" />,
  },
  { url: "https://t.me/+B3jIp1KP4qE0MDk8", icon: <AiFillLinkedin /> },
];

const paragraphs = [
  {
    id: 1,
    content:
      "Engaging content that aligns with your training objective and keeps your team engaged.",
  },
  {
    id: 2,
    content:
      "Invite team members to participate in quizzes, boosting team spirit.",
  },
  {
    id: 3,
    content:
      "Monitor and understand your team’s progress effortlessly with our intuitive real-time tracking.",
  },
];

const page = () => {
  const corporateLinks = menuData?.menuData.find(
    (data) => data.corporate
  )?.corporate;

  const videoUrl =
    "https://mobigames.s3.amazonaws.com/access-compliance-game/Quiz%20Corporate%20-%20Profile%201%20-%20Microsoft%20Edge%202023-11-17%2012-43-23.mp4";

  // Transform links to objects with 'name' and 'link' properties
  const data = footerData.footerData.map((section) => ({
    header: section.header,
    links: section.links.map((link) => ({
      name: link,
      link: link.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-"),
    })),
  }));

  return (
    <div>
      <Navbar
        data={corporateLinks}
        bgColors="bg-primaryPurple"
        textColor="text-white"
        outlineText="Help & Support"
        btnText="Log in"
        outlineTextLink="/help"
        btnTextLink="login"
        logoLink="/"
        logoSrc={Logo}
        logoAlt="logo-image"
      />
      <Hero
        heroHeader="Engage and Boost Your Team Knowledge with Qwizfun"
        heroPara="Discover a new era of corporate engagement where learning meets
                fun. Qwiz is your key to fostering a knowledgeable and connected
                workforce."
        btnLabel="Book a Demo Now"
        btnFontSize="text-base"
        btnTextColor=" text-white"
        btnBgColor="bg-primaryPurple"
        btnFontWeight="font-medium"
        btnBorderRadius="rounded-xl"
        imgSrc={Guy3}
        imgWidth="90%"
        imgHeight="20"
        imgAlt="man-image"
        bgImage="corporate-1"
      />
      <CorporateTestimonial />
      <CorporateWorks
        paraData={paragraphs}
        videoUrl={videoUrl}
        btnLabel1="Sign up for a free trial now"
        btnLabel2="Play now"
        btnFontSize="text-base"
        btnBorderRadius="rounded-xl"
        btn1LabelBgColor="bg-primaryPurple"
        btn2LabelBgColor="bg-primaryPurple"
        btn1TextColor="text-white"
        btn2TextColor="text-white"
        btnFontWeight="font-medium"
        btn1To="register"
        btn2To="login"
      />
      <Footer
        data={data}
        logoSrc={LogoBlack}
        logoAlt="quiz-logo"
        logoLink=""
        bgColor="bg-primaryPurple"
        textColor="text-white"
        logoTextColor="text-primaryPurple"
        placeholderColor="placeholder-primaryPurple"
        inputIconColor="text-primaryPurple"
        hoverColor="hover:text-gray-600"
        socialLinks={socialLinks}
        subscribeText="Stay in touch with us for the freshest products!"
        disclaimerText="Qwizfun powered by 9ijakids"
      />
    </div>
  );
};

export default page;
