import React from "react";
import { Box } from "@mui/material";
function Card({ children, small, smallPadding, bgcolor, ...rest }) {
  return (
    <Box
      p={smallPadding ? 1 : 2}
      borderRadius={small ? "8px" : "16px"}
      bgcolor={bgcolor || "white"}
      sx={{ width: "max-content", border: "1px solid #cae8f8" }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default Card;
