import React from "react";
import Button from "../../../components/shared/Button";
import LaptopMan1 from "../../../assets/images/laptop1.png";
import LaptopMan2 from "../../../assets/images/laptop2.png";
import Lady1 from "../../../assets/images/lady1.png";
import Lady2 from "../../../assets/images/lady4.png";
import Guy1 from "../../../assets/images/guy1.png";
import Guy2 from "../../../assets/images/guy2.png";
import { Link } from "react-router-dom";

const QuizkWork = () => {
  return (
    <div className="px-8 container mx-auto py-10">
      <h1 className="text-black heading mb-[72px] flex justify-center py-6">
        How does Qwiz work?
      </h1>
      <div className="grid grid-cols-1 place-content-center md:grid-cols-2 md:grid-rows-3 gap-x-13 gap-y-20">
        <div className="">
          <div className="relative   bg-primaryGreen rounded-2xl  h-72  flex justify-center">
            <img
              src={LaptopMan1}
              alt="laptop-man"
              width={400}
              height={300}
              className="absolute bottom-0"
            />
          </div>
        </div>
        <div className="">
          <div className="">
            <h1 className="heading-2">Boost Team Knowledge and Connection</h1>
            <p className="py-6 heading-para">
              Elevate your corporate environment by engaging your workforce in a
              fun learning experience and continuous development through
              gamification
            </p>
            <Link to="/corporate">
              <Button
                type="button"
                hoverBgColor="primaryRed-dark"
                disabled={false}
                borderRadius="rounded-xl"
                fontSize="text-base"
                textColor="text-white"
                bgColor="bg-primaryGreen"
                fontWeight="font-medium"
              >
                Click here for more information
              </Button>
            </Link>
          </div>
        </div>
        <div className="">
          <div className="">
            <h1 className="heading-2">
              Turn Tough Crowds into Engaged Audiences
            </h1>
            <p className="py-6 heading-para">
              Transform events into engaging experiences adding an interactive
              layer to make your events memorable and leaving participants
              excited for more.
            </p>
            <Link to="/events">
              <Button
                type="button"
                hoverBgColor="primaryRed-dark"
                disabled={false}
                borderRadius="rounded-xl"
                fontSize="text-base"
                textColor="text-white"
                bgColor=" bg-lightBlue"
                fontWeight="font-medium"
              >
                Click here for more information
              </Button>
            </Link>
          </div>
        </div>
        <div className="">
          <div className="relative   bg-darkBlue rounded-2xl h-72  flex justify-center px-4">
            <div className="rounded-full bg-deepBlue w-full h-72 flex justify-center">
              <img
                src={Lady2}
                alt="lady-img"
                width={300}
                height={50}
                className="absolute bottom-0"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="relative   bg-lightRed rounded-2xl h-72  flex justify-center p-4 ">
            <div className="w-[90vh] h-[76vh] flex justify-center">
              <img
                src={Guy1}
                alt="laptop-man"
                width={300}
                height={200}
                className="absolute bottom-0"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="">
            <h1 className="heading-2">
              Energize Your Audience with Friendly Competition
            </h1>
            <p className="py-6 heading-para">
              Fuel the excitement of competition. Brings quizzes and challenges
              to life, creating an electrifying atmosphere for participants.
            </p>
            <Link to="/competitions">
              <Button
                type="button"
                hoverBgColor="primaryRed-dark"
                disabled={false}
                borderRadius="rounded-xl"
                fontSize="text-base"
                textColor="text-white"
                bgColor="bg-lightRed"
                fontWeight="font-medium"
              >
                Click here for more information
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizkWork;
