import React from "react";
import Button from "../../../components/shared/Button";

import Lady2 from "../../..//assets/images/lady2.png";
import Lady3 from "../../../assets/images/lady3.png";
import Card1 from "../../../assets/images/Group5632.png";
import Card2 from "../../../assets/images/Group5661.png";
import Card3 from "../../../assets/images/Group5671.png";
import Card4 from "../../../assets/images/Group5691.png";
import Card5 from "../../../assets/images/Group5701.png";

import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import LeaderBoard from "../../../components/shared/LeaderBoard";
import CardSection from "./CardSection";

const cardData = [
  {
    image: Card1,
    name: "Lorem ipsum dolor sit amet.",
  },
  {
    image: Card2,
    name: "Card 2",
  },
  {
    image: Card3,
    name: "Card 3",
  },
  {
    image: Card4,
    name: "Card 3",
  },
  {
    image: Card3,
    name: "Card 3",
  },
  {
    image: Card5,
    name: "Card 3",
  },
  {
    image: Card2,
    name: "Card 3",
  },
  {
    image: Card1,
    name: "Card 3",
  },
  // Add more cards as needed
];

const Hero = () => {
  const videoUrl =
    "https://mobigames.s3.amazonaws.com/access-compliance-game/Quiz%20Corporate%20-%20Profile%201%20-%20Microsoft%20Edge%202023-11-17%2012-43-23.mp4";
  return (
    <div className="2xl:container 2xl:mx-auto">
      <div className="md:flex justify-between  mb-40 px-8 md:px-0 mt-10 lg:mt-0 ">
        <Fade left>
          <div className="md:w-1/2 flex justify-center items-center">
            <div className="px-4 lg:px-8 mb-10 lg:mb-0 ">
              <h1 className="heading   lg:pb-10">
                Play, Learn <br className="hidden md:block" /> and Win.
              </h1>
              <p className="pb-6 heading-para">
                Discover a new era of corporate engagement where learning meets
                fun. Qwiz is your key to fostering a knowledgeable and connected
                workforce.
              </p>
              <Button
                type="button"
                hoverBgColor="primaryRed-dark"
                disabled={false}
                borderRadius="rounded-xl"
                fontSize="text-base"
                textColor="text-white"
                bgColor="bg-deepBlue"
                fontWeight="font-medium"
              >
                Book a Demo Now
              </Button>
            </div>
          </div>
        </Fade>
        <Fade right delay={1000}>
          <div className="md:w-1/2  flex  lg:justify-end">
            <div className="relative  competition-1 w-fit h-fit p-4 lg:p-0 lg:w-full  lg:h-full  flex justify-center">
              <img
                src={Lady2}
                alt="laugh-man"
                width="90%"
                height={20}
                className=""
              />
            </div>
          </div>
        </Fade>
      </div>

      <div className="mb-10 px-8">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 ">
          <Fade bottom>
            <div className="flex justify-center items-center mb-20 md:mb-0">
              <div className="">
                <h1 className="heading text-black">How it works</h1>
                <p className="heading-para mb-4">
                  1. Engaging content that aligns with your training objective
                  and keeps your team engaged.
                </p>
                <p className="heading-para mb-4">
                  2. Invite team members to participate in quizzes, boosting
                  team spirit.
                </p>
                <p className="heading-para">
                  3. Monitor and understand your team’s progress effortlessly
                  with our intuitive real-time tracking.
                </p>
                <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start  gap-x-10 mt-14">
                  <Link to="/register">
                    <Button
                      type="button"
                      hoverBgColor="primaryRed-dark"
                      disabled={false}
                      borderRadius="rounded-xl"
                      fontSize="text-base"
                      textColor="text-white"
                      bgColor="bg-deepBlue"
                      fontWeight="font-medium"
                    >
                      Sign up for a free trial now
                    </Button>
                  </Link>
                  <Link
                    to="/homepage"
                    className="mt-6 md:mt-0 flex justify-center"
                  >
                    <Button
                      type="button"
                      hoverBgColor="primaryRed-dark"
                      disabled={false}
                      borderRadius="rounded-xl"
                      fontSize="text-base"
                      textColor="text-white"
                      bgColor="bg-deepBlue"
                      fontWeight="font-medium"
                    >
                      Play now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="flex justify-center items-center">
              <div className="flex items-start justify-center h-fit md:h-56 lg:h-80 w-fit lg:w-full rounded-lg">
                <iframe
                  width="100%"
                  height="100%"
                  src={videoUrl}
                  title="Embedded Video"
                  frameBorder="0"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      {/* section */}
      <div className="mb-10">
        <CardSection data={cardData} />
      </div>
      {/* end */}

      {/* section */}
      <div className="mb-10">
        <LeaderBoard
          bgColor="bg-deepBlue"
          subColor="bg-lightBlue"
          textHead="Lorem ipsum."
          textPara="Lorem ipsum dolor sit amet, conse"
        />
      </div>
      {/* end */}

      {/* section */}
      <div>
        <div className="bg-deepBlue flex justify-center items-center gap-x-40 mt-20 py-10 px-8 lg:px-0 mb-20 lg:mb-0  ">
          <div className=" hidden md:block">
            <div className="">
              <div className=" relative    rounded-2xl h-full  flex justify-center ">
                <div className="h-40 bg-white rounded-xl w-64"></div>
                {/* <div className="relative  corporate-2 rounded-2xl w-screen h-[60vh]  flex justify-center">
                  <img
                    src={Guy3}
                    alt="man-img"
                    width={220}
                    height={100}
                    className="absolute bottom-0"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="  py-10 lg:py-0">
            <div className="md:flex justify-center items-center text-white">
              <div>
                <div className="mb-6 lg:mb-0">
                  <h1 className="heading text-white  text-center">
                    Prizes to be won
                  </h1>
                </div>
                <div className="heading-para">
                  <p>
                    Engage, Learn and stand a chance to win exciting prizes!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of  */}
      <Fade bottom>
        <div className="px-8  mt-10 flex flex-wrap justify-center items-center space-x-10">
          <div className="md:w-1/2 max-w-md mb-20  lg:mb-0">
            <div className="">
              <h1 className="heading text-center whitespace-normal md:text-left">
                Ready to Transform Learning at Your Workplace?
              </h1>
              <p className="pb-4 heading-para">
                Get in touch with us to discover how Qwizfun can revolutionize
                corporate training and elevate your team's knowledge and
                connection. Take the first step towards a more engaged
                workforce.
              </p>
              <div className="flex justify-center md:justify-start">
                <Link to="/contact">
                  <Button
                    type="button"
                    hoverBgColor="primaryRed-dark"
                    disabled={false}
                    borderRadius="rounded-xl"
                    fontSize="text-base"
                    textColor="text-white"
                    bgColor="bg-deepBlue"
                    fontWeight="font-medium"
                  >
                    Get in Touch
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 ">
            <div className="relative  competition-2 rounded-2xl w-full min-h-full  flex justify-center">
              <img
                src={Lady3}
                alt="lady-img"
                width={300}
                height={100}
                className=""
              />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Hero;
