import Logo from '../assets/Icons2/quiz_fun_logo.jpg';
import Hamburger from '../assets/Icons2/hambuger.svg';
import Notification from '../assets/Icons2/notification.svg';
import Star from '../assets/Icons2/star.svg';
import Star2 from '../assets/Icons2/star2.png';
import Dashboard from '../assets/Icons2/dashboard.svg';
import Leaderboard from '../assets/Icons2/leaderboard.svg';
import Activities from '../assets/Icons2/activities.svg';
import Info from '../assets/Icons2/info.svg';
import Subscription from '../assets/Icons2/subscription.svg';
import Explore from '../assets/Icons2/explore.svg';
import Logout from '../assets/Icons2/logout.svg';
import Profile from '../assets/Icons2/profile.svg';
import Target from '../assets/Icons2/target.svg';
import Dice from '../assets/Icons2/dice.svg';
import Game from '../assets/Icons2/game_achievement.svg';
import Gold from '../assets/Icons2/gold_trophy.svg';
import Silver from '../assets/Icons2/silver_trophy.svg';
import Bronze from '../assets/Icons2/bronze_trophy.svg';
import Play from '../assets/Icons2/play.svg';
import Crown from '../assets/Icons2/crown.svg';
import Lock from '../assets/Icons2/lock.svg';
import Search from '../assets/Icons2/search.svg';
import Star3 from '../assets/Icons2/star3.svg';
import Star4 from '../assets/Icons2/star4.svg';
import Confetti from '../assets/Icons2/confetti.svg';
import Announcement from '../assets/Icons2/announcement.svg';

export const Icons = {
  Logo,
  Hamburger,
  Notification,
  Star,
  Star2,
  Dashboard,
  Leaderboard,
  Activities,
  Info,
  Subscription,
  Explore,
  Logout,
  Profile,
  Target,
  Dice,
  Gold,
  Silver,
  Bronze,
  Game,
  Play,
  Crown,
  Lock,
  Search,
  Star3,
  Star4,
  Confetti,
  Announcement,
};
