import React from "react";
import Button from "../../../components/shared/Button";
import LaptopMan1 from "../../../assets/images/laptop1.png";
import LaptopMan2 from "../../../assets/images/laptop2.png";
import Lady1 from "../../../assets/images/lady1.png";
import Lady2 from "../../..//assets/images/lady4.png";
import Guy1 from "../../../assets/images/guy1.png";
import Guy3 from "../../../assets/images/guy3.png";
import CorporateLeaderboard from "./CorporateLeaderboard";
import Fade from "react-reveal/Fade";
import LeaderBoard from "../../../components/shared/LeaderBoard";

const Hero = ({
  heroHeader,
  heroPara,
  btnLabel,
  btnFontSize,
  btnTextColor,
  btnBgColor,
  btnFontWeight,
  btnBorderRadius,
  moreWord,
  imgHeight,
  imgWidth,
  imgAlt,
  imgClassName,
  imgSrc,
  bgImage,
}) => {
  return (
    <div className="2xl:container 2xl:mx-auto">
      <div className="md:flex justify-between  mb-40 px-8 md:px-0 mt-10 lg:mt-0 ">
        <Fade left>
          <div className="md:w-1/2 flex justify-center items-center">
            <div className="px-4 lg:px-8 mb-10 lg:mb-0 ">
              <h1 className={`heading lg:pb-10 ${moreWord ? "max-w-md" : ""}`}>
                {heroHeader}
              </h1>
              <p className="pb-6 heading-para">{heroPara}</p>
              <Button
                type="button"
                disabled={false}
                borderRadius={btnBorderRadius}
                fontSize={btnFontSize}
                textColor={btnTextColor}
                bgColor={btnBgColor}
                fontWeight={btnFontWeight}
              >
                {btnLabel}
              </Button>
            </div>
          </div>
        </Fade>
        <Fade right delay={1000}>
          <div className="md:w-1/2  flex  lg:justify-end">
            <div
              className={`relative  ${bgImage}  w-fit h-fit p-4 lg:p-0 lg:w-full  lg:h-full  flex justify-center`}
            >
              <img
                src={imgSrc}
                alt={imgAlt}
                width={imgWidth}
                height={imgHeight}
                className={imgClassName}
              />
            </div>
          </div>
        </Fade>
      </div>

      <div className="">
        <LeaderBoard
          bgColor="bg-deepPurple"
          subColor="bg-primaryPurple"
          textHead="Celebrate Achievements with Qwizfun Leaderboard"
          textPara="See and track you and your team's progress in real-time."
        />
      </div>
    </div>
  );
};

export default Hero;
