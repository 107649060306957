import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/api/post";
function RegisterForm() {
  const loginApi = useLogin();
  const navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    unique_id: Yup.string()
      .email("Please enter a valid email address")
      // .matches(
      //   /.+@theaccesscorporation.com$/,
      //   "Email address must end with @theaccesscorporation.com"
      // )
      .matches(/access|9ijakids/i, "Email address must be a valid work email")
      .required("Email is Required"),
  });
  return (
    <Paper elevation={6}>
      <Box p={{ xs: 2, md: 4 }}>
        <Typography variant="h6" fontSize={12}>
          Log In With your Email
        </Typography>
        <Box>
          <Formik
            validationSchema={loginSchema}
            initialValues={{ unique_id: "", event_code: "091098" }}
            onSubmit={(values, { setSubmitting }) => {
              loginApi.mutate(values, {
                onSuccess: (response) => {
                  toast.success("Success!");
                  const res = response.data;
                  console.log({ res });

                  const userInfo = JSON.stringify({
                    token: res?.token,
                    userPassport: values.user_passport,
                    id: res?.user?.id,
                    displayName: res?.user?.display_name,
                    name: res?.user?.name,
                    ageRange: res?.user?.age_range,
                    avatar: res?.user?.avatar,
                  });

                  if (res.user.display_name === "null") {
                    sessionStorage.setItem("closeCreate", false);
                  }
                  // sessionStorage.setItem(
                  //   "quizID",
                  //   "24df12d9-1116-4b57-a458-c2e39d6fd36a"
                  // );
                  sessionStorage.setItem("ccAuth", userInfo);
                  sessionStorage.setItem(
                    "displayName",
                    res?.user?.display_name
                  );
                  if (res?.user?.first_login === 0) {
                    sessionStorage.setItem("newbie", "no");
                  } else {
                    sessionStorage.setItem("newbie", "yes");
                    localStorage.setItem("hideHelp", "false");
                  }
                  navigate("/homepage");
                },
                onError: (err) => {
                  console.log({ err: err.response?.data?.message });
                  toast.error(`${err.response?.data?.message}`);
                  // toast.error(`${err.response?.data?.message}`);
                },
                onSettled: () => {
                  setSubmitting(false);
                },
              });
              console.log({ values });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Box my={2}>
                  <TextField
                    fullWidth
                    data-test-id="email"
                    label="Email"
                    variant="outlined"
                    onChange={(e) => setFieldValue("unique_id", e.target.value)}
                  />
                  <Box sx={{ color: "error.main", width: "100%" }}>
                    <ErrorMessage name="unique_id" />
                  </Box>
                </Box>
                {/* <Box>
                  <TextField
                    fullWidth
                    data-test-id="password"
                    label="Password"
                    variant="outlined"
                    onChange={(e) => setFieldValue("password", e.target.value)}
                  />
                </Box> */}
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    data-test-id="login"
                    // bgcolor="#277BA0"
                    sx={{ backgroundColor: "rgb(113 51 186 )  !important" }}
                  >
                    {isSubmitting ? "Logging In" : "Login"}
                  </Button>
                </Box>
                <Box my={2}>
                  <Typography variant="body2" align="center">
                    You don't have an account?
                    <Box component="span" color="green">
                      <Link to="/register"> Register</Link>
                    </Box>
                  </Typography>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
}

export default RegisterForm;
