import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import menuData from "../../../../utils/data/menu.json";
// import Hero from "./components/Hero";
import Hero from "../../components/shared/Hero";

// import HowItWorks from "./components/HowItWorks";
import HowItWorks from "../../components/shared/HowItWorks";
import Footer from "./components/Footer";
// import Navbar from "./components/Navbar";
import Navbar from "../../components/shared/CustomNavbar";
import CorporateLeaderboard from "./components/CorporateLeaderboard";
import ImageSection from "./components/ImageSection";
import { BiLogoFacebook, BiLogoTwitter, BiLogoInstagram } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";

import Logo from "../../assets/images/ngx.png";
import HeroImage from "../../assets/images/orange-man.PNG";
// import { socialLinks } from "../corporate/Page";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
import Hero2 from "../../../../components/shared/Hero2";
const Events = () => {
  const { id } = useParams();
  const corporateLinks = menuData?.menuData.find(
    (data) => data.newEvents
  )?.newEvents;
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("register");
  };
  const [landingPageData, setLandingPageData] = useState({
    navbar: {
      bgColor: "#75B82B",
      buttonColor: "#106B2E",
      logo: "http://localhost:3000/static/media/people.da8b1eaec31568b1a8481d7722e90c9a.svg",
    },
    hero: {
      header: "Play, Learn, and Become Financially Independent with NGX!",
      summary:
        "Dive into the world of financial knowledge where learning meets excitement. Play our interactive games, enhance your financial literacy, and stand a chance to win big rewards.",
      buttonLabel: "",
      backgroundImage: "",
      bgColor: "#75B82B",
      buttonColor: "#106B2E",
    },
    leaderboard: {
      header: "Celebrate Financial Progress on the NGX Leaderboard",
      summary:
        "Monitor real-time progress as you enhance your financial knowledge and compete with others on the path to financial well-being.",
      bgColor: "#75B82B",
    },
    help: {
      texts: [
        {
          id: 1,
          content: "Register with your name and email      .",
        },
        {
          id: 2,
          content:
            " Login with your email used during registration and select a game to start playing.",
        },
        {
          id: 3,
          content: `Play as many times as possible to top the leaderboard to win wonderful prizes!`,
        },
      ],
    },
    prize: {
      header: "Exciting Prizes, Awaits You!",
      summary:
        "Participate, learn, and win prizes on your journey to financial enlightenment with NGX!",
    },
    footer: {
      logo: Logo,
    },
  });

  const landingPageAPI = useFetchLandingPageSettings(id);
  const [socialLinks, setSocialLinks] = useState([]);
  useEffect(() => {
    if (landingPageAPI.data) {
      let result = landingPageAPI.data?.landing_page_setting;
      let footer = result?.landing_page_footer;
      console.log({ footer });
      setSocialLinks([
        {
          url: footer?.instagram_link || "",
          icon: <BiLogoInstagram className="cursor-pointer" />,
        },
        {
          url: footer?.x_link || "",
          icon: <BiLogoTwitter className="cursor-pointer" />,
        },
        {
          url: footer?.facebook_link || "",
          icon: <BiLogoFacebook className="cursor-pointer" />,
        },
        {
          url: footer?.linkedin_link || "",
          icon: <AiFillLinkedin />,
        },
      ]);
      let data = {};
      data.navbar = {
        bgColor: result?.landing_page_header?.background_color,
        logo: result?.landing_page_header?.header_logo,
        bgColor: "#75B82B",
        buttonColor: "#106B2E",
      };
      data.hero = {
        header: result?.landing_page_hero?.hero_title,
        summary: result?.landing_page_hero?.hero_text,
        buttonLabel: result?.landing_page_hero?.hero_image,
        image: result?.landing_page_hero?.hero_image,

        backgroundImage: result?.landing_page_hero?.hero_button_color,
      };

      let moneyWeek = [
        {
          id: 1,
          content:
            "Register with your name, email, phone number and select your country and type of school.",
        },
        {
          id: 2,
          content:
            "Login with your email used during registration and select a game to start playing.",
        },
        {
          id: 3,
          content: `Play as many times as possible to top the leaderboard to win wonderful prizes!`,
        },
      ];
      data.help = {
        // todo: make this dynamic
        texts:
          id === "725094"
            ? moneyWeek
            : [
                {
                  id: 1,
                  content: "Register with your name and email      .",
                },
                {
                  id: 2,
                  content:
                    " Login with your email used during registration and select a game to start playing.",
                },
                {
                  id: 3,
                  content: `Play as many times as possible to top the leaderboard to win wonderful prizes!`,
                },
              ],
      };
      data.leaderboard = {
        header: result?.landing_page_body?.body_title,
        summary: result?.landing_page_body?.body_text,
        image: result?.landing_page_body?.body_image,
      };
      data.footer = {
        logo: result?.landing_page_footer?.footer_image,
      };

      setLandingPageData((prev) => ({ ...prev, ...data }));
    }
  }, [landingPageAPI.data]);
  return (
    <div>
      {landingPageAPI.data?.landing_page_setting?.landing_page_header && (
        <Navbar
          data={corporateLinks}
          logoSrc={landingPageData.navbar.logo}
          bgColor={`bg-[${landingPageData?.navbar?.bgColor}]`}
          buttonColor={landingPageData.navbar.buttonColor}
          bgColors="bg-white"
          textColor="text-black"
          // outlineText="Help & Support"
          btnText="Log in"
          // outlineTextLink="/help"
          btnTextLink="login"
          logoLink={`/${
            window.location.href.includes("corporate") ? "corporate" : "event"
          }/${id}`}
          // logoSrc={Logo}
          logoAlt="logo-image"
          // bgColor="bg-darkRed"
          navColor="text-darkRed"
          loginTextColor="text-white"
        />
      )}
      {landingPageAPI.data?.landing_page_setting?.landing_page_hero && (
        <Hero
          header={landingPageData.hero.header}
          summary={landingPageData.hero.summary}
          buttonLabel={"Sign Up Now"}
          buttonBG={`bg-[${landingPageData.hero.buttonColor}]`}
          buttonColor={"white"}
          imageUrl={landingPageData.hero.image}
          heroHeader={landingPageData.hero.header}
          heroPara={landingPageData.hero.summary}
          btnLabel="Register"
          btnFontSize="text-base"
          btnTextColor=" text-white"
          btnBgColor="bg-darkRed"
          btnFontWeight="font-medium"
          btnBorderRadius="rounded-xl"
          // imgSrc={landingPageData.hero.hero_image}
          imgSrc={landingPageData.hero.image}
          imgWidth="100%"
          imgHeight="20"
          imgAlt="man-image"
          onClick={handleNavigate}
          // bgImage={"biblechallenge"}
        />
      )}
      {landingPageAPI.data?.landing_page_setting?.landing_page_body && (
        // <>
        //   <CorporateLeaderboard
        //     avatarNameColor="text-[#707070]"
        //     bgColor={
        //       landingPageData.leaderboard?.bgColor
        //         ? `bg-[${landingPageData.leaderboard.bgColor}]`
        //         : "bg-accessOrange"
        //     }
        //     textColor="text-white"
        //     textHead={landingPageData.leaderboard.header}
        //     textPara={landingPageData.leaderboard.summary}
        //   />

        //   <HowItWorks
        //     options={landingPageData.help.texts}
        //     registerButtonColor={"#106B2E"}
        //   />
        //   <ImageSection
        //     header={landingPageData.prize.header}
        //     summary={landingPageData.prize.summary}
        //   />
        // </>

        <>
          <Hero2
            text={landingPageData.leaderboard.header}
            paragraph={landingPageData.leaderboard.summary}
            // backgroundImage={landingPageData.leaderboard.image}
            image={landingPageData.leaderboard.image}
            imageAlt="image"
          />
          <HowItWorks
            paraData={landingPageData.help.texts}
            // videoUrl={videoUrl}
            btnLabel1="Register"
            btnLabel2="Login"
            btnFontSize="text-base"
            btnBorderRadius="rounded-xl"
            btn1LabelBgColor="bg-darkGreen2"
            btn2LabelBgColor="bg-darkRed"
            btn1TextColor="text-white"
            btn2TextColor="text-white"
            btnFontWeight="font-medium"
            btn1To="register"
            btn2To="login"
          />
          <div className="flex justify-center items-center p-10 lg:p-0 mt-10 ">
            <div>
              <h1 className="heading text-black text-center max-w-[40rem] m-auto mb-3">
                Register, Play and Win Cash Prizes!
              </h1>
              <div className=" flex justify-center items-center bg-darkGreen2 lg:w-[624px] rounded-lg">
                {/* <img src={prize} alt="price" className="w-[100%]  "/> */}
              </div>
            </div>
          </div>
        </>
      )}

      {landingPageAPI.data?.landing_page_setting?.landing_page_footer && (
        <Footer
          logo={landingPageData.footer.logo}
          socialLinks={socialLinks}
          bgColor="bg-accessBlue"
          textColor="text-white"
          logoTextColor="text-accessBlue"
          placeholderColor="placeholder-accessBlue"
          inputIconColor="text-accessBlue"
          hoverColor=""
        />
      )}
    </div>
  );
};

export default Events;
