import React from "react";
import AuthLayout from "../../components/layout/NewAuthLayout";
import PageTitle from "../../components/PageTitle";
import NotificationCard from "./components/NotificationCard";
import { useGetNotifications } from "../../hooks/api/fetch";

function Notifications() {
  let auth = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data } = useGetNotifications(auth?.id);
  console.log(data);
  return (
    <AuthLayout>
      <PageTitle title="Notifications" />
      <NotificationCard data={data?.notifications} />
    </AuthLayout>
  );
}

export default Notifications;
